import {Injectable} from '@angular/core';
import {EvaluacionModel} from './evaluacion-model';
import {EvaluacionResource} from './evaluacion.resource';

@Injectable()
export class EvaluacionService {
  constructor(private improvementsRepository: EvaluacionResource) {
  }

  crearEvaluacion(improvement: EvaluacionModel) {
    return this.improvementsRepository.crearEvaluacion(improvement);
  }

  obtenerListaEvaluaciones(filter: any): Promise<EvaluacionModel[]> {
    return this.improvementsRepository.obtenerListaEvaluaciones(filter);
  }

  obtenerEvaluacion(id: any): Promise<EvaluacionModel> {
    return this.improvementsRepository.obtenerEvaluacion({id: id});
  }
}
