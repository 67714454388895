/**
 * Created by Raul on 03/05/2018.
 */

 import {Component, OnInit, ViewChild} from '@angular/core';
 import {UsuarioService} from '../service/usuario.service';
 import {UsuarioModel} from '../service/usuario.model';
 import {ToastService} from 'ng-uikit-pro-standard';
 import {DataShareService} from '../../common.module/util/data.share';
 import {UsersDetailComponent} from '../usuario-detalle/usuario-detalle.component';
 import * as pdfMake from 'pdfmake/build/pdfmake.js';
 import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
 import {ExcelService} from '../../common.module/util/exportxls.service';
 import {ExcelUserModel} from '../service/excel.user.model';
 import {ConfigItem} from '../../common.module/model/config-item.model';
 import { ConfiguracionGeneralService } from 'src/app/admin.configuracion-general.module/service/configuracion-general.service';
 import { ConfiguracionGeneralModel } from 'src/app/admin.configuracion-general.module/service/configuracion-general.model';
 import {OrganizacionesService} from '../service/organizaciones.service';
 
 @Component({
 
   selector: 'app-user-list',
   templateUrl: 'usuario-lista.component.html'
 })
 export class UsuarioListaComponent implements OnInit {
   public count: number;
   public listItems: any[] = [];
   public listToExport: any[] = [];
   public isCheck = false;
   public configItems: ConfigItem[] = [];
   public filterModel: any = {
     elemPorPagina: 5,
     pagina: 1,
     forma: 'DESC',
     ordenarPor: '_id'
   };
   public filterModelOrganizacion: any = {
     elemPorPagina: 9000,
     pagina: 1-10,
     forma: 'DESC',
     ordenarPor: '_id'
   };
 
   @ViewChild(UsersDetailComponent) modalD;
   public users: UsuarioModel[];
   public excelusers: ExcelUserModel[];
   public user: UsuarioModel = <UsuarioModel>{
     roles: []
   };
   public optionsSelect: Array<any>;
   dataPdf: any;
   public billSelect: Array<any>;
   public search_name: string;
   public search_rfc: string;
   public search_status: string;
   public search_id: string;
   public idm: number;
   public check:string;
   public configuracion: ConfiguracionGeneralModel = {};
   public userSesion: UsuarioModel = {};
   public rolAdm = "";
 
 
 
   constructor(private userService: UsuarioService,
               private toastMsg: ToastService,
               private excel: ExcelService,
               private dataShareService: DataShareService, 
               private configuracionGeneralService:ConfiguracionGeneralService,
               private organizacionesService: OrganizacionesService) {
     this.dataShareService.objectConfirmed$.subscribe(obj => {
       console.log(obj);
       this.loadUsers();
     });
   }
 
   ngOnInit() {
     this.optionsSelect = [
       {value: '', label: 'TODO'},
       {value: 'A', label: 'ACTIVO'},
       {value: 'I', label: 'INACTIVO'}
     ];
     this.billSelect = [
       {value: '', label: 'TODO'},
       {value: 'true', label: 'Si'},
       {value: 'false', label: 'No'}
     ];
     this.configItems = [
       /* {
         title: 'ID',
         nameAttribute: '_id',
         nameFilter: '_id'
       }, */
       {
         title: 'Nombre',
         nameAttribute: 'nombre',
         nameFilter: 'nombre'
       },
       {
         title: 'Apellido paterno',
         nameAttribute: 'apellido_p',
         nameFilter: 'apellido_p'
       },
       {
         title: 'Apellido materno',
         nameAttribute: 'apellido_m',
         nameFilter: 'apellido_m'
       },
       {
         title: 'Email',
         nameAttribute: 'correo',
         nameFilter: 'correo'
       },
       {
         title: 'Género',
         nameAttribute: 'sexo',
         nameFilter: 'sexo',
         typeSelect: true,
         options: [
           {label: 'Ver Todo', value: ''},
           {label: 'Masculino', value: 'M'},
           {label: 'Femenino', value: 'F'}
         ]
       },
       {
         title: 'RFC',
         nameAttribute: 'rfc',
         nameFilter: 'rfc'
       },
       {
         title: 'Estatus',
         nameAttribute: 'estatus',
         nameFilter: 'estatus',
         typeSelect: true,
         options: [
           {label: 'Ver Todo', value: ''},
           {label: 'Activo',   value: 'A'},
           {label: 'Inactivo', value: 'I'}
         ]
       },
       {
         title: 'Autentificación',
         nameAttribute: 'twoFactorAuth',
         nameFilter: 'twoFactorAuth',
         typeSelect: true,
         options: [
           {label: 'Ver Todo', value: ''},
           {label: 'Activo',   value: true},
           {label: 'Inactivo', value: false}
         ]
       },
       {
         title: 'Ver',
         filteringNotAllowed: true,
         enableButtonAction: true,
         iconButtonAction: 'fa-binoculars'
       }
     ];
     this.loadUsers();
 
     this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion = configuracion;
      this.isCheck = this.configuracion.twoFactorAuth;
     });
 
     this.userService.obtenerUsuarioSesion().then(res => {
     res.roles.forEach(element => {
       if(element._id == "5db89adb54f3470105730c8e"){
         this.rolAdm = element._id;
       }
     });
     });
   }
 
   loadUsers() {
     this.userService.obtenerUsuariosPaginados(this.filterModel).then(data => {
       this.listItems = data;
 
       this.listItems.forEach(usuario => {
         switch (usuario.sexo) {
           case 'M':
             usuario.sexo = 'Masculino';
             break;
           case 'F':
             usuario.sexo = 'Femenino';
             break;
         }
       });
 
       const result = this.listItems;
       result.forEach(itm => {
         itm.estatus = itm.estatus == null ? 'Inactivo' : itm.estatus == 'I' ? 'Inactivo' : 'Activo';
         itm.twoFactorAuth = itm.twoFactorAuth == null ? 'Inactivo' : itm.twoFactorAuth == false ? 'Inactivo' : 'Activo';
       });
     }, err => {
       this.toastMsg.error('No se ha podido realizar la carga de usuarios, estamos trabajando en ello.');
       console.error(err);
     });
   }
 
   createModal(create) {
     this.modalD.addControls();
     this.user.cumpleanios = '';
     create.rolSelected = null;
     create.modal.show();
     create.formUser.reset();
     this.modalD.disEdit = false;
   }

   base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len); 
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer; 
  }
  
  getMimeType(base64: string): string {
    const mimeType = base64.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    return mimeType ? mimeType[1] : ''; 
  }
  
  arrayBufferToImageSrc(arrayBuffer: ArrayBuffer, mimeType: string) {
    const blob = new Blob([arrayBuffer], { type: mimeType }); 
    return URL.createObjectURL(blob); 
  }
  
  toImage(base64: string): string {
    const base64Data = base64.split(',')[1];
    const arrayBuffer = this.base64ToArrayBuffer(base64Data); 
    const mimeType = this.getMimeType(base64); 
    const imageUrl = this.arrayBufferToImageSrc(arrayBuffer, mimeType);
    return imageUrl; 
  }
 
   detail(create, id) {
     this.idm = id;
     console.log('Detalle->' + id);
     this.modalD.disEdit = true;
     this.userService.obtenerUsuario(id).then(usr => {
       if(usr && usr.firma) {
        const im = this.toImage(usr.firma)
        create.imageSrc = usr.firma
        create.base64Image = usr.firma
       }
       create.formUser.reset();
       this.user = usr;
       if (usr.cumpleanios == '00/00/0000') {
         this.user.cumpleanios = '';
       }
       if (this.user && this.user.cumpleanios) {
         const regex = '[0-9]{4}[-/][0-9]{2}[-/][0-9]{2}';
         console.log(this.user.cumpleanios.match(regex));
         if (this.user.cumpleanios.match(regex)) {
           if (this.user.cumpleanios.match(regex).input == this.user.cumpleanios) {
             this.user.cumpleanios = this.user.cumpleanios.substring(8, 10) + '/' + this.user.cumpleanios.substring(5, 7)
               + '/' + this.user.cumpleanios.substring(0, 4);
           }
         }
       }
       
      //  this.user.id = this.user._id;
       this.modalD.formUser.id_organizacion = usr.id_organizacion;
       //console.log(this.modalD.formUser.id_organizacion);
       //console.log(usr);
       //console.log(usr.id_organizacion);
 
       //verificamos si es nuevo usuario o existente
       if(this.user._id != null){
         this.organizacionesService.obtenerOrganizacionesPaginado(this.filterModelOrganizacion).then(organizacion => {
           organizacion.forEach(organizacion => {
           //  console.log("ID DE ORGANIZACION:"+organizacion._id);
           })
           
            organizacion.map(tipoOrganizacion => {
             //console.log("id_organizacion del usuario:"+this.modalD.formUser.id_organizacion);
             //console.log("id_organizacion en la lista:"+tipoOrganizacion._id);
 
             if(this.modalD.formUser.id_organizacion == tipoOrganizacion._id){
               console.log("encontramos coincidencia.Asignando");
               this.user.organizacionNombre = tipoOrganizacion.nombre;
            //   console.log(this.modalD.formUser.organizacionNombre);
             }
 
           });
         });
       }
 
       return this.user;
     }, err => {
       if (err.status === 404)
         this.toastMsg.info('El usuario que busca no ha sido encontrado.');
       else
         this.toastMsg.error('El detalle de usuario no se ha podido cargar.');
     });
     create.modal.show();
   }
 
   cleanModal() {
     this.user = <UsuarioModel>{
       roles: []
     };
     this.user.cumpleanios = '';
     this.modalD.rolSelected = null;
   }
 
   pdf() {
     //noinspection TypeScriptUnresolvedVariable
     pdfMake.vfs = pdfFonts.pdfMake.vfs;
     const tmpData: any[] = [
       [ // {text: 'ID', style: 'header'},
         {text: 'NOMBRE', style: 'header'},
         {text: 'APELLIDO PATERNO', style: 'header'},
         {text: 'APELLIDO MATERNO', style: 'header'},
         {text: 'EMAIL', style: 'header'},
         {text: 'GENERO', style: 'header'},
         // {text: 'Identificador SIM', style: 'header'},
         {text: 'RFC', style: 'header'},
         {text: 'ESTATUS', style: 'header'},
         // {text: 'Puede facturar', style: 'header'}
 
       ]
     ];
     const result = this.listItems;
     // result.forEach(obj => {
     result.forEach(itm => {
       // itm.estatus = itm.state == 'I' ? 'Inactivo' : 'Activo';
       let obj = [
         // {text: itm._id, style: 'anotherStyle'},
         {text: itm.nombre, style: 'anotherStyle'},
         {text: itm.apellido_p, style: 'anotherStyle'},
         {text: itm.apellido_m, style: 'anotherStyle'},
         {text: itm.correo, style: 'anotherStyle'},
         {text: itm.sexo, style: 'anotherStyle'},
         {text: itm.rfc, style: 'anotherStyle'},
         {text: itm.estatus, style: 'anotherStyle'} //, estatus
         // {text: itm.can_bill == true ? 'Si' : 'No', style: 'anotherStyle'}
 
       ]
       tmpData.push(obj);
     });
 
     this.dataPdf = {
       border: [false, true, false, false],
       fontSize: 8,
       pageOrientation: 'landscape',
       content: [
         {
           table: {
             body: tmpData
           },
           layout: {
             fillColor(rowIndex, node, colIndex) {
               return rowIndex % 2 === 0 ? '#e9e9e9' : '#fff';
             }
           }
         }
       ],
       styles: {
         header: {
           fontSize: 12,
           bold: true,
           alignment: 'center',
           fillColor: '#3F729B',
           color: '#ffffff'
         },
         anotherStyle: {
           fontSize: 10,
           italic: true,
           alignment: 'left'
         }
       }
     };
     if (this.dataPdf.content[0].table.body.length > 1) {
       //noinspection TypeScriptUnresolvedFunction
       pdfMake.createPdf(this.dataPdf).download('Usuarios.pdf');
     } else {
       this.toastMsg.error('No se puede generar el PDF, no hay datos');
     }
   }
 
   xls() {
     this.excelusers = this.listItems;
     const result = this.excelusers.map(obj => {
       return {
         // ID: obj._id,
         CORREO: obj.correo,
         NOMBRE: obj.nombre,
         APELLIDO_PATERNO: obj.apellido_p,
         APELLIDO_MATERNO: obj.apellido_m,
         TELEFONO: obj.telefono == null ? '' : obj.telefono,
         RFC: obj.rfc,
         // can_bill: obj.can_bill == true ? 'Si' : 'No',
         // ESTATUS: obj.estatus == 'I' ? 'Inactivo' : 'Activo',
         ESTATUS: obj.estatus,
         GENERO: obj.sexo
       };
     });
     if (result.length > 0) {
       this.excel.exportAsExcelFile(result, 'Usuarios');
     } else {
       this.toastMsg.error('No se puede generar el XLS, no hay datos');
     }
   }
 
   actionEmmiterCallback(item: any, createUserModal: any) {
     this.detail(createUserModal, item._id);
   }
   actualizarDA(){
     if(this.isCheck == true){
       this.check = "1"
     }else{
       this.check = "0"
     }
     this.userService.actualizarUsuariosDA(this.check).then(data => {
       console.log("" + data);
     });
   }
 }
 