/**
 * Created by Raul.
 *
 * Agregar las rutas que se desean cargar, usando la carga asincrona, se deben aregar por componente.
 *
 *  @routesApp Arreglo de rutas de la aplicación.
 */
import {Routes} from '@angular/router';
import {AuthGuardAdmin} from './common.module/auth/auth-guard.admin.service';
import {IndexAdminComponentConsumos} from './main-admin-consumos.component/index-admin.component';
import {AuthGuardChildren} from './common.module/auth/auth-guard-children';


const routesAppConsumos: Routes = [
 
  {
    path: '',
    component: IndexAdminComponentConsumos,
  },
  {
    path: 'catalogo_usuarios_consumos',
    loadChildren: './admin.admin-consumos.module/admin-admin-consumos.module#AdminAdminConsumosModule',
    data: {
      pageTitle: 'Administración de Usuarios Consumos'
    }
  },
  {
    path: 'catalogo_establecimientos_consumos',
    loadChildren: './admin.admin-consumos-establecimiento.module/admin-admin-establecimientos.module#AdminAdminEstablecimientosModule',
    data: {
      pageTitle: 'Administración de Establecimientos Consumos'
    }
  },

  {
    path: 'catalogo_reportes_consumos',
    loadChildren: './admin.admin-consumos-reportes.module/admin-admin-reporte.module#AdminAdminReportesModule',
    data: {
      pageTitle: 'Administración de Productos Consumos'
    }
  },
  {
    path: 'bandeja_entrada_consumos',
    loadChildren: './admin.index.module/admin-index.module#AdminIndexModule',
    data: {
      pageTitle: 'Bandeja de entrada'
    },
    canActivate: [AuthGuardAdmin]
  },
  {
    path: 'consumos',
    loadChildren: './admin.historial-consumos.module/historial-consumos.module#HistorialConsumosModule',
    data: {
      pageTitle: 'Historial Consumos'
    }
  },
];

export const AdminRoutesModulesConsumos = [...routesAppConsumos];
