<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-4 pt-3 pb-3 mt-0 animated fadeIn fast">
      <div class="w-100 mx-auto">
        <img [src]="'data:image/jpg;base64,'+configuracion.logoRecuperarContrasena" alt="" class="img-fluid">
      </div>
      <!-- Login form -->
      <form [formGroup]="formRecover"
            (submit)="formRecover.valid && finalRegister()">
        <p class="h5 text-center mb-4">Registre su nueva contraseña, por favor.</p>

        <div class="md-form">
          <input type="password" formControlName="defaultFormPassword" id="password" class="form-control"
                 data-error="Datos invalidos" data-success="Correcto"
                 [(ngModel)]="userModel.contrasenia"
                 mdbInput>
          <label for="password">Nueva contraseña</label>
          <div *ngIf="formRecover.controls['defaultFormPassword'].dirty">
            <div *ngIf="formRecover.controls['defaultFormPassword'].errors?.required"
                 class="text-danger animated fadeInDown fast-4 small">
              La contraseña es obligatorio.
            </div>
            <div *ngIf="userModel.contrasenia != userModel.contraseniaConfirma"
                 class="text-danger animated fadeInDown fast-4 small">
              Las contraseñas no coinciden, favor de validar.
            </div>
          </div>
        </div>
        <div class="md-form">
          <input type="password" formControlName="defaultFormPasswordConfirm" id="passwordConfirm" class="form-control"
                 [(ngModel)]="userModel.contraseniaConfirma"
                 mdbInput>
          <label for="passwordConfirm">Confirmación de nueva contraseña</label>
          <div *ngIf="formRecover.controls['defaultFormPasswordConfirm'].dirty">
            <div *ngIf="formRecover.controls['defaultFormPasswordConfirm'].errors?.required"
                 class="text-danger animated fadeInDown fast-4 small">
              La confirmación de contraseña es obligatorio.
            </div>
            <div *ngIf="userModel.password != userModel.passwordConfirm"
                 class="text-danger animated fadeInDown fast-4 small ">
              Las contraseñas no coinciden.
            </div>
            <div *ngIf="userModel.contrasenia != userModel.contraseniaConfirma"
                 class="text-danger animated fadeInDown fast-4 small">
              Las contraseñas no coinciden, favor de validar.
            </div>
          </div>
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-default waves-light" mdbWavesEffect>Cambiar contraseña</button>
        </div>
      </form>
      <!-- Login form -->
    </div>
  </div>
</div>

