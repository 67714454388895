/**
 * Created by Rsierra.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {ToastService} from 'ng-uikit-pro-standard';
import {AuthServerProvider} from './auth-jwt.service';


@Injectable()
export class AuthGuardAdmin implements CanActivate {

  constructor(private auth: AuthService,
              private router: Router,
              private toastMsg: ToastService,
              private authServ: AuthServerProvider) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this
      .authServ
      .validacionRuta(this.stripQueryParams(state.url)).do(res => {
        if (res == false && state.url == '/app')
          this.router.navigate(['portal']);
        if (res == false)
          this.toastMsg.warning('Usted no cuenta con los permisos para entrar a esta funcionalidad.');
      });
  }

  stripQueryParams(url: string): string {
    let newUrl = '';
    let urlSplit = url.split('?');
    if (urlSplit.length == 2) {
      newUrl = urlSplit[0];
    } else {
      newUrl = url;
    }

    // Se hace validacion para corregidora
    if (!isNaN(+newUrl.split('/').slice(-1))) {
      newUrl = newUrl.split('/').slice(0, -1).join('/') + '/*'
    }

    return newUrl;
  }
}
