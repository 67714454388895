import {Injectable} from '@angular/core';
import {RolResource} from './resource/rol.resource';
import {RolModel} from './model/role.model';


@Injectable({
  providedIn: 'root'
})
export class RolService {
  constructor(private rolResource: RolResource) {
  }

  obtenerRoles(): Promise<RolModel[]> {
    return this.rolResource.obtenerRoles();
  }

  obtenerRol(id: string): Promise<RolModel> {
    return this.rolResource.obtenerRol({id: id});
  }
}
