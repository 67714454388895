/**
 * Created by Daniel on 17/07/2018.
 */

import { Injectable } from '@angular/core';
import { IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod } from '@ngx-resource/core';
import { FormalitiesModel } from './formalities.model';
import { ResourceAuth } from '../../../auth/authenticate.resource';
import { AuthServerProvider } from '../../../auth/auth-jwt.service';
import { SimPaymentModel } from '../../../model/sim-payment.model';
import { PaseCaja } from '../../../../portal-legacy/admin.albercas.module/service/pase-caja.model/pase-caja.model';

// @Injectable()
// @ResourceParams({
//   url: 'http://localhost:8072'
// })
// export class SimResource extends Resource {
//   constructor(resHandler: ResourceHandler) {
//     super(resHandler);
//   }

@Injectable()
@ResourceParams({
  pathPrefix: '/sim'
})
export class SimResource extends ResourceAuth {
  constructor(resHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resHandler, authProv);
  }

  @ResourceAction({
    path: '/set-formalities',
    method: ResourceRequestMethod.Post
  })
  registerFormality: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/na/set-taxpayer',
    method: ResourceRequestMethod.Put
  })
  setTaxpayerFact: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/update-folio',
    method: ResourceRequestMethod.Patch
  })
  updateFolio: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/exist-key/{!cadastralKey}',
    method: ResourceRequestMethod.Get
  })
  getPredialFolio: IResourceMethod<{ cadastralKey: string }, any>;

  @ResourceAction({
    path: '/status-history/{!id}',
    method: ResourceRequestMethod.Get
  })
  getStatus: IResourceMethod<{ id: number }, any>;

  @ResourceAction({
    path: '/status',
    method: ResourceRequestMethod.Post
  })
  updateStatusHistory: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/status-formality',
    method: ResourceRequestMethod.Post
  })
  updateStatusHistoryWithFormalityId: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/case-file?id_req={!id_req}&&id_form={!id_form}',
    method: ResourceRequestMethod.Get
  })
  getCaseFile: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/paid-payments/{!id}',
    method: ResourceRequestMethod.Get
  })
  getTicket: IResourceMethod<{ id: any }, SimPaymentModel[]>;

  @ResourceAction({
    path: '/update/inspection/{!inspection}',
    method: ResourceRequestMethod.Patch
  })
  updateInspection: IResourceMethod<{ inspection: any }, any>;

  @ResourceAction({
    path: '/na/detail/cashPass/{!liq}'
  })
  getDetailCashPass: IResourceMethod<{ liq: string }, PaseCaja>;


}
