<div mdbModal [config]="{backdrop: 'static'}" #CrearObservacion="mdb-modal" class="modal fade overflow-auto" tabindex="-1" role="dialog"
     aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: false}">
  <div class="modal-dialog mw-75" role="document">
    <div class="modal-content">
      <div mti-color-theme class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="CrearObservacion.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Motivo del rechazo</h4>
      </div>
      <div class="modal-body">
        <form [formGroup]="formObservacion">
          <div class="row  justify-content-center">
            <div class="col-12 col-lg-12">
              <div class="md-form">
                <input mdbInput type="text" maxlength="50" formControlName="formVigenciaReq" id="observacion"
                       class="form-control" [(ngModel)]="observacion">
                <label for="observacion" class="col-lg-12">Motivo de rechazo: </label>
                
              </div>
            </div>
          </div> 
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="CrearObservacion.hide();" class="btn btn-danger waves-light btn-md"
                aria-label="Close"
                mdbWavesEffect>
          Cancelar
        </button>
        <button class="btn btn-info btn-theme-primary darken-3 waves-ligth btn-md" mdbWavesEffect
                (click)="crearObserv();" mti-color-theme>
          <i class="fa fa-save" aria-hidden="true"></i> Guardar
        </button>
      </div>
    </div>
  </div>
</div>
