import {Injectable} from "@angular/core";
import {
  IResourceMethod,
  Resource,
  ResourceAction,
  ResourceHandler,
  ResourceParams, ResourceRequestMethod,
} from "@ngx-resource/core";
import {ExpedientesElectModel} from "./expedientes-elect.model";
import {LocationsModel} from "./locations.model";
import {RequisitosModel} from "./requisitos.model";
import {ExpedienteArchivosModel} from "./expediente-archivos.model";
import {ResourceAuth} from "../../common.module/auth/authenticate.resource";
import {AuthServerProvider} from "../../common.module/auth/auth-jwt.service";

@Injectable()
@ResourceParams({
  pathPrefix: '/core'
  // url: 'http://localhost:8012'
})
export class RequisitosResource extends ResourceAuth {
// export class RequisitosResource extends Resource {
  constructor(restHandler: ResourceHandler, provService: AuthServerProvider) {
    super(restHandler, provService);
  }
  // constructor(restHandler: ResourceHandler) {
  //   super(restHandler);
  // }

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/cargar-lista-requisitos-exp/{!estatus}'
  })
  obtenerListaRequisitos: IResourceMethod<{estatus: string}, RequisitosModel[]>;

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/cargar-requisitos-exp/{!id}'
  })
  obtenerRequisito: IResourceMethod<{id: string}, RequisitosModel>;

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/cargar-lista-archivos-exp-id/{!idSolExp}'
  })
  obtenerListaRequisitosIdSol: IResourceMethod<{idSolExp: string}, ExpedienteArchivosModel []>;

  @ResourceAction({
    method: ResourceRequestMethod.Patch,
    path: '/actualizar-archivos-exp'
  })
  actualizarRequisitosRes: IResourceMethod<{archivosExpElect: ExpedienteArchivosModel}, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Patch,
    path: '/actualizar-archivo-id'
  })
  actualizarArchivoId: IResourceMethod<{archivosExpElect: ExpedienteArchivosModel}, any>;


  /*
  *
   */
  @ResourceAction({
    isArray: false,
    method: ResourceRequestMethod.Post,
    path: '/crear-archivos-exp'
  })
  GuardarArchivosExpediente: IResourceMethod<{cargaArchivosReq: any}, any>;


  @ResourceAction({
    isArray: false,
    method: ResourceRequestMethod.Patch,
    path: '/actualizar-archivo-id/{!id}'
  })
  actualizarArchivoExp: IResourceMethod<{cargaArchivosReq: any}, any>;




  @ResourceAction({
    isArray: false,
    method: ResourceRequestMethod.Delete,
    path: '/elimina-archivos-exp?id={!id}'
  })
  eliminarArchivoExpediente: IResourceMethod<{id: string}, any>;

  @ResourceAction({
    isArray: false,
    method: ResourceRequestMethod.Get,
    path: '/obtener-archivo/{!id}'
  })
  obtenerArchivoExpediente: IResourceMethod<{id: string}, any>;


}
