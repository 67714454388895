import {NgModule} from '@angular/core';
import {AppCommonLibsModule} from '../common.module/common.module';
import {ConsultaPagoRouting} from '../portal.consulta-pago.module/consulta-pago.routing';
import {DirectivasModule} from '../directivas.module/directivas.module';
import {ConsultaPagoResource} from '../portal.consulta-pago.module/service/consulta-pago.resource';
import {ConsultaPagoService} from '../portal.consulta-pago.module/service/consulta-pago.service';
import {PdfXmlResource} from '../portal.consulta-pago.module/service/pdfXml.resource';
import {ConsultaPagoComponent} from './consulta-pago/consulta-pago.component';

@NgModule({
  declarations: [
    ConsultaPagoComponent
  ],
  imports: [
    AppCommonLibsModule,
    ConsultaPagoRouting,
    DirectivasModule
  ],
  providers: [
    ConsultaPagoResource,
    ConsultaPagoService,
    PdfXmlResource
  ]
})
export class ConsultaPagoModule {
}
