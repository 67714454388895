import {Injectable} from '@angular/core';
import {Resource, ResourceHandler, ResourceParams} from '@ngx-resource/core';

@Injectable()
@ResourceParams({
  // pathPrefix: '/pagos'
  url: 'http://localhost:8006'
})
export class PaseCajaResource extends Resource {
// export class PaseCajaResource extends ResourceAuth {
  constructor(handler: ResourceHandler) {
    super(handler);
  }

  // constructor(handler: ResourceHandler, authProv: AuthServerProvider) {
  //   super(handler, authProv);
  // }
}
