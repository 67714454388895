/**
 * Created by Raul on 03/05/2018.
 */
import {
  IResourceMethod,
  ResourceAction,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {Injectable} from '@angular/core';
import {ResourceAuth} from '../../../../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../../../../common.module/auth/auth-jwt.service';
import {RolModel} from '../model/role.model';

@Injectable()
@ResourceParams({
  // url: 'http://localhost:8001'
  pathPrefix: '/administracion'
})
export class RolResource extends ResourceAuth {

//  export class RolResource extends Resource {
  /*
     constructor(handler: ResourceHandler) {
      super(handler);
     }*/

  constructor(handler: ResourceHandler, auth: AuthServerProvider) {
    super(handler, auth);
  }

  @ResourceAction({
    path: '/rol'
  })
  obtenerRoles: IResourceMethod<any, RolModel[]>;

  @ResourceAction({
    path: '/rol/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtenerRol: IResourceMethod<{ id: any }, RolModel>;

}
