/**
 * Created by Raul on 03/05/2018.
 */

import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UsuarioService} from '../service/usuario.service';
import {UsuarioModel} from '../service/usuario.model';
import {IOption, ToastService} from 'ng-uikit-pro-standard';
import {ActivatedRoute} from '@angular/router';
import {RolModel} from '../../admin.roles.module/service/rol.model';
import {isNullOrUndefined, isUndefined} from 'util';
import {DataShareService} from '../../common.module/util/data.share';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePickerConstant} from '../../common.module/util/datepicker.constant';
import {GeneralPatternsValidators} from '../../common.module/util/general.patterns.validator';
import {ModalDirective} from 'angular-bootstrap-md';
import {PermisosModel} from '../service/permisos.model';
import {OrganizacionesService} from '../service/organizaciones.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: 'usuario-detalle.component.html'
})
export class UsersDetailComponent implements OnInit {

  imageSrc: string | ArrayBuffer | null = null;
  base64Image: string | null = null;
  @ViewChild('fileInput') fileInput!: ElementRef;
  
  @Input()
  public user: UsuarioModel = <UsuarioModel> {
    roles: []
  };
  @ViewChild('CreateUser')
  public modal: ModalDirective;
  @Output()
  public load = new EventEmitter();
  public rolSelected: RolModel;
  public optionsSelect: Array<any>;
  public disEdit = true;
  private areasSelect: Array<any>;
  public optionsSex: Array<any> = [
    {
      value: 'M',
      label: 'Masculino'
    },
    {
      value: 'F',
      label: 'Femenino'
    }
  ];
  public myDateOptionPicker = DatePickerConstant.datePickerOptions;

  public formUser: FormGroup;

  public authorityDetail: RolModel = <RolModel>{
    tramites: []
  };

  public idOrganizacion: string;
  public nombreOrganizacion: string;
  public listaOrganizacion: IOption[] = [];
  constructor(private userService: UsuarioService,
              private toastMsg: ToastService,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private dataShareService: DataShareService,
              private organizacionesService: OrganizacionesService) {
  }

  public filterModel: any = {
    pagina: 1-50,
    elemPorPagina: 9000,
    ordenarPor: '_id',
    forma: 'DESC'
  };

  ngOnInit() {
    const stackPromise: Promise<any>[] = [];
    stackPromise.push(this.userService.obtenerAreas());
    Promise.all(stackPromise).then(res => {
      this.areasSelect = res[0].map(itm => {
        return {value: itm._id, label: itm.descripcion};
      });
    }, err => {
      this.toastMsg.error('Falla al hacer la carga de algunos recursos');
    });
    this.formUser = this.fb.group({
      twoFactorAuth:[],
      defaultFormName: ['', Validators.required],
      defaultFormFirstName: ['', Validators.required],
      defaultPassword2: ['', Validators.required],
      password1: ['', Validators.required],
      defaultFormLastName: ['', Validators.required],
      defaultFormTelephone: ['', Validators.compose([Validators.maxLength(10)])],
      defaultFormEmail: ['', Validators.compose([
                                                Validators.required,
                                                Validators.pattern(GeneralPatternsValidators.EMAIL_CON_DOMINIO_PATTERN)])],
      defaultFormRFC: ['', Validators.pattern(GeneralPatternsValidators.RFC_PATTERN)],
      idOrganizacion:[],
      nombreOrganizacion:[]
      
    });
    this.userService.obtenerRoles().then(res => {
      this.optionsSelect = res.map(obj => {
        return {
          value: obj,
          label: obj.nombre
        };
      });
    }, err => {
      if (err.status == 400 && err.body)
        this.toastMsg.error(err.body.message);
      else
        this.toastMsg.error('Se ha producido un error inesperado.');
    });

    this.organizacionesService.obtenerOrganizacionesPaginado(this.filterModel).then(organizacion => {
      organizacion.forEach(organizacion => {
        console.log("ID DE ORGANIZACION:"+organizacion._id);
      })
      
      this.listaOrganizacion = organizacion.map(tipoOrganizacion => {
        return <IOption>{
          value: tipoOrganizacion._id,
          label: (tipoOrganizacion.nombre)
        };
      });

      console.log(this.listaOrganizacion);
    });
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
  
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();
  
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          console.log(reader.result);
          this.imageSrc = reader.result;
          this.base64Image = reader.result; // o reader.result.split(',')[1], si quieres quitar el prefijo
        }
  
        // Limpiar el campo de archivo después de leer el archivo
        this.limpiarInput(input);
      };
  
      reader.readAsDataURL(file);
    }
  }
  
  limpiarInput(input: HTMLInputElement): void {
    input.value = ''; // Limpiar el campo de entrada
  }

  showAuthorityDetail(id: string) {
    if (isUndefined(id)) {
      this.toastMsg.info('Seleccione un Rol.');
      return;
    }
    this.userService.obtenerRol(id).then(res => {
      this.authorityDetail = res;
    }, err => {
      this.toastMsg.error('No se pudo cargar el detalle del rol.');
    });
  }

  addAuthority() {
    console.log(this.rolSelected);
    if (!this.user.roles)
      this.user.roles = [];
    const index = this.user.roles.findIndex(obj => {
      return obj._id === this.rolSelected._id;
    });
    if (index !== -1) {
      this.toastMsg.warning('El rol ya se encuentra en la lista de roles del usuario.');
      return;
    }
    if (this.user.roles.length > 0) {
      let realizar = true;
      this.user.roles.forEach(obj => {
        this.userService.obtenerRol(obj._id).then(rol => {
          if (realizar) {
            if (rol.acceso_administrativo) {// usuario administrador
              console.log('funcionario');
              if (!this.rolSelected.acceso_administrativo) {// rol no de administrador
                this.toastMsg.warning('No es posible agregar el rol ya que actualmente tiene asignado un rol de tipo: "Administrativo". ');
                realizar = false;
                return;
              } else {// es administrador
                this.addRolToList();
              }
            } else {// no es administrador
              console.log('ciudadano');
              if (this.rolSelected.acceso_administrativo) {// es administrador
                this.toastMsg.warning('No es posible agregar el rol ya que actualmente tiene asignado un rol de tipo: "Solo portales". ');
                realizar = false;
                return;
              } else {// rol no de administrador
                this.addRolToList();
              }
            }
          }
        });
      });
    } else {
      this.user.roles.push({
        _id: this.rolSelected._id,
        nombre: this.rolSelected.nombre,
        acceso_administrativo: this.rolSelected.acceso_administrativo
      });
    }
  }
  private addRolToList() {
    const i = this.user.roles.findIndex(obj => obj._id === this.rolSelected._id);
    if (i != -1)
      return;
    this.user.roles.push({
      _id: this.rolSelected._id,
      nombre: this.rolSelected.nombre,
      acceso_administrativo: this.rolSelected.acceso_administrativo
    });
  }

  removeAuthority(authorityModel: RolModel) {
    console.log(authorityModel);
    if (!authorityModel)
      return;
    const index = this.user.roles.findIndex(obj => obj._id === authorityModel._id);
    if (index !== -1)
      this.user.roles.splice(index, 1);
  }

  saveUser() {
    this.disEdit = true;
    if (!isNullOrUndefined(this.user.rfc))
      this.user.rfc = this.user.rfc.toUpperCase();
    //console.log(this.user);
    if (!isNullOrUndefined(this.user.contrasenia)) {
      if (this.user.contrasenia.trim() === '') {
        this.toastMsg.error('La constraseña es invalida.');
        this.disEdit = false;
        return;
      }
      if (this.user.contrasenia !== this.user.contraseniaConfirma) {
        this.toastMsg.error('Las constraseñas no coinciden, valide la información.');
        this.disEdit = false;
        return;
      }
    }
    if (isNullOrUndefined(this.user.roles) || this.user.roles.length === 0) {
      this.toastMsg.error('El usuario debe tener asignado almenos un rol.');
      this.disEdit = false;
      return;
    }
    if (this.user.estatus != 'A' || this.user.estatus == null) {
      this.user.estatus = 'I';
    }
    this.user.firma = this.base64Image || ''
    console.log(this.user, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
    if (isUndefined(this.user._id)) {
      this.userService.crearUsuario(this.user).then(res => {
        this.disEdit = true;
        this.toastMsg.success('La operación ha sido exitosa.');
        this.imageSrc = null
        this.base64Image = null
        this.fileInput.nativeElement.value = '';
        this.modal.hide();
        this.formUser.reset();
        this.dataShareService.confirmObject(this.user);
        this.user = <UsuarioModel>{
          roles: []
        };
      }, err => {
        if (err.body) {
          this.disEdit = false;
          this.toastMsg.error(err.body.message);
        } else {
          this.toastMsg.error('Se ha producido un error inesperado.');
          this.disEdit = false;
        }
      });
    } else
      this.userService.actualizarUsuario(this.user).then(res => {
        this.toastMsg.success('La operación ha sido exitosa.');
        this.dataShareService.confirmObject(this.user);
        this.imageSrc = null
        this.base64Image = null
        this.fileInput.nativeElement.value = '';
        this.modal.hide();
        this.formUser.reset();
      }, err => {   
        if (err.status == 400 && err.body) {
          this.toastMsg.error(err.body.message);
          this.disEdit = false;
        }
        else {
          this.toastMsg.error('Se ha producido un error inesperado.');
          this.disEdit = false;
        }
      });
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
    this.disEdit = false;
    return true;
  }

  enEdit() {
    if (this.disEdit) {
      this.disEdit = false;
      this.cleanPassword();
      this.toastMsg.info('Modo editar activado');
    } else {
      this.disEdit = true;
      this.addControls();
      this.toastMsg.info('Modo editar desactivado');
    }
  }

  cleanPassword() {
    this.formUser.controls['defaultPassword2'].clearValidators();
    this.formUser.controls['defaultPassword2'].reset();
    this.formUser.controls['password1'].clearValidators();
    this.formUser.controls['password1'].reset();
  }

  addControls() {
    this.formUser.controls['defaultPassword2'].setValidators(Validators.required);
    this.formUser.controls['defaultPassword2'].reset();
    this.formUser.controls['password1'].setValidators(Validators.required);
    this.formUser.controls['password1'].reset();
  }

  esUsuarioCiudadano(usuario: UsuarioModel) {
      let esCiudadano = false;
      if(usuario.roles != undefined){
        const permisosmodel = <PermisosModel[]>usuario.roles;
        for (const permiso of permisosmodel) {
            // if (permiso != undefined) {console.log('---------', permiso);}
            // @ts-ignore
          if (permiso != undefined && permiso.acceso_administrativo == false) {
                // si no tiene acceso administrativo, es porque es ciudadano y se sale del for
                esCiudadano = true;
                break;
            }
        }
      }      
    return esCiudadano;
  }

  /*
  private addRolToList() {
    const i = this.user.roles.findIndex(obj => obj._id === this.rolSelected._id);
    if (i != -1)
      return;
    this.user.roles.push({
      _id: this.rolSelected._id,
      nombre: this.rolSelected.nombre,
      acceso_administrativo: this.rolSelected.acceso_administrativo
    });
  }*/

  agregarOrganizacion() {
   // console.log(this.idOrganizacion);
    //console.log(this.user.id_organizacion);

    if (this.idOrganizacion == undefined) {
      this.toastMsg.warning('Seleccione una organizacion.');
      return;
    }else{

  
      if (this.user.id_organizacion != "" && this.user.id_organizacion != undefined) {
        this.toastMsg.warning('El usuario ya tiene organización asignada. Elimine la actual.');
        return;
      } else {

        if (this.user.id_organizacion == this.idOrganizacion) {
          this.toastMsg.warning('La organización ya se encuentra asignada al usuario.');
          return;
        }else{
          this.user.id_organizacion = this.idOrganizacion;
          this.user.organizacionNombre = this.nombreOrganizacion;
          //console.log("se ha agregado la organizacion:"+this.user.id_organizacion);
        }
        
      }
   }
  }

  getValue(event) {
    console.log(event.label);
    this.nombreOrganizacion = event.label;
    this.idOrganizacion = event.value;
  }

  removerOrganizacion() {
  //  console.log("organizacion a remover:"+this.user.id_organizacion);
    this.user.id_organizacion = "";
    this.user.organizacionNombre = "";
    //console.log("organizacion removida:"+this.user.id_organizacion);
    
  }

  hideModal(){
    this.modal.hide(); 
    this.limpiaVariables();
  }

  public limpiaVariables(){
   // console.log("estamos en funcion limpiar variables");
    this.idOrganizacion = "";
    this.nombreOrganizacion = "";
    this.base64Image = null
    this.imageSrc = null
    this.fileInput.nativeElement.value = '';
  }
}
