<div class="row">
  <div class="col-12">
    <div class="jumbotron">
      <p class="lead">{{message}}</p>
    </div>
  </div>
  <div class="col-12" *ngIf="isPredial">
    <div class="row">
      <div class="col-lg-12 col-12">
        <h5><strong>Gracias por utilizar el sistema de Predial Digital {{anioActual}}! ayúdanos a seguir avanzando, responde las siguientes preguntas:</strong></h5>
      </div>
    </div>
<!--    Pregunta 1-->
    <div class="row">
      <div class="col-12 mt-5">
        <h6>{{pregunta1}}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-frown-o fa-3x red-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q1_very_bad" name="q1" value="muy_insatisfecho" [(ngModel)]="questionSuccess.answer_question_1">
            <label class="form-check-label face-check" for="q1_very_bad">Muy insatisfecho</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-frown-o fa-3x orange-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q1_bad" name="q1" value="insatisfecho" [(ngModel)]="questionSuccess.answer_question_1">
            <label class="form-check-label face-check" for="q1_bad">insatisfecho</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-meh-o fa-3x amber-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q1_neutro" name="q1" value="neutro" [(ngModel)]="questionSuccess.answer_question_1">
            <label class="form-check-label face-check" for="q1_neutro">Neutral</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-smile-o fa-3x text-success"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q1_good" name="q1" value="satisfecho" [(ngModel)]="questionSuccess.answer_question_1">
            <label class="form-check-label face-check" for="q1_good">Satisfecho</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-smile-o fa-3x green-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q1_very_good" name="q1" value="muy_satisfecho" [(ngModel)]="questionSuccess.answer_question_1">
            <label class="form-check-label face-check" for="q1_very_good">Muy satisfecho</label>
          </div>
        </div>
      </div>
    </div>
<!--    Pregunta 2-->
    <div class="row">
      <div class="col-12 mt-5">
        <h6>{{pregunta2}}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-frown-o fa-3x red-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q2_very_bad" name="q2" value="muy_insatisfecho" [(ngModel)]="questionSuccess.answer_question_2">
            <label class="form-check-label face-check" for="q2_very_bad">Muy insatisfecho</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-frown-o fa-3x orange-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q2_bad" name="q2" value="insatisfecho" [(ngModel)]="questionSuccess.answer_question_2">
            <label class="form-check-label face-check" for="q2_bad">insatisfecho</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-meh-o fa-3x amber-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q2_neutro" name="q2" value="neutro" [(ngModel)]="questionSuccess.answer_question_2">
            <label class="form-check-label face-check" for="q2_neutro">Neutral</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-smile-o fa-3x text-success"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q2_good" name="q2" value="satisfecho" [(ngModel)]="questionSuccess.answer_question_2">
            <label class="form-check-label face-check" for="q2_good">Satisfecho</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-smile-o fa-3x green-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q2_very_good" name="q2" value="muy_satisfecho" [(ngModel)]="questionSuccess.answer_question_2">
            <label class="form-check-label face-check" for="q2_very_good">Muy satisfecho</label>
          </div>
        </div>
      </div>
    </div>
<!--    Pregunta 3-->
    <div class="row">
      <div class="col-12 mt-5">
        <h6>{{pregunta3}}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-frown-o fa-3x red-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q3_very_bad" name="q3" value="muy_insatisfecho" [(ngModel)]="questionSuccess.answer_question_3">
            <label class="form-check-label face-check" for="q3_very_bad">Muy insatisfecho</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-frown-o fa-3x orange-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q3_bad" name="q3" value="insatisfecho" [(ngModel)]="questionSuccess.answer_question_3">
            <label class="form-check-label face-check" for="q3_bad">insatisfecho</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-meh-o fa-3x amber-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q3_neutro" name="q3" value="neutro" [(ngModel)]="questionSuccess.answer_question_3">
            <label class="form-check-label face-check" for="q3_neutro">Neutral</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-smile-o fa-3x text-success"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q3_good" name="q3" value="satisfecho" [(ngModel)]="questionSuccess.answer_question_3">
            <label class="form-check-label face-check" for="q3_good">Satisfecho</label>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-2 col-lg-2 text-center">
        <div class="row">
          <div class="col-12 margin-mobile">
            <i class="fa fa-smile-o fa-3x green-text"></i>
          </div>
          <div class="col-12">
            <input class="form-check-input" type="radio" id="q3_very_good" name="q3" value="muy_satisfecho" [(ngModel)]="questionSuccess.answer_question_3">
            <label class="form-check-label face-check" for="q3_very_good">Muy satisfecho</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-5 d-flex justify-content-center justify-content-md-start">
  <div class="col-12 col-md-3 text-center text-md-left">
    <button class="btn btn-primary btn-large" (click)="save()" mdbWavesEffect>
      Finalizar pago
    </button>
  </div>
</div>
