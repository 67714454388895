import {Injectable} from '@angular/core';
import {ConsultaPagoResource} from './consulta-pago.resource';
import {ConsultaPagoModel} from './consulta-pago.model';
import {PdfXmlResource} from './pdfXml.resource';
import {EndPointModel} from './end-point.model';

@Injectable({
  providedIn: 'root'
})
export class ConsultaPagoService {

  constructor(private consultaPagoResource: ConsultaPagoResource,
              private pdfXmlResource: PdfXmlResource) {
  }


  getInfoRecibo(parametros: any): Promise<ConsultaPagoModel> {
    return this.consultaPagoResource.obtenerInfoRecibo(parametros);
  }

  getInfoReciboConfigurable(parametros: any): Promise<ConsultaPagoModel> {
    return this.consultaPagoResource.obtenerInfoReciboConfigurable(parametros);
  }

  public getFactura(folioPaseCaja: any) {
    return this.pdfXmlResource.getFactura({ folioPaseCaja: folioPaseCaja });
  }

  public descargarPdfFacturaConfigurable(serie: string, folio: string, importe: string) {
    return this.pdfXmlResource.descargarPdfFacturaConfigurable({ serie: serie, folio: folio, importe: importe });
  }

  public getFacturaXml(folio: any, serie: any) {
    return this.pdfXmlResource.getFacturaXml({folio: folio, serie: serie});
  }

  public descargarXmlFacturaConfigurable(serie: string, folio: string, importe: string) {
    return this.pdfXmlResource.descargarXmlFacturaConfigurable({ serie: serie, folio: folio, importe: importe });
  }

  public getComprobante(folioPaseCaja: any) {
    return this.pdfXmlResource.getComprobante({ folioPaseCaja: folioPaseCaja });
  }

  public descargarComprobanteConfigurable(serie: string, folio: string) {
    return this.pdfXmlResource.descargarComprobanteConfigurable({ serie: serie, folio: folio });
  }
}
