/**
 * Created by anunez on 02/05/2018.
 */
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {PaymentService} from '../service/payment.service';
import {QuestionsSuccessfulPayment} from '../service/questionssuccessfulpayment.model';
import { ToastService } from 'ng-uikit-pro-standard';

@Component({
  moduleId: module.id,
  selector: 'app-payment-success-create',
  templateUrl: 'payment-success.component.html'
})
export class PaymentSuccessCreateComponent implements OnInit {
  public satisfaction_q1 = '';
  public satisfaction_q2 = '';
  public satisfaction_q3 = '';
  public isPredial = false;
  public bank = '';
  public message = '';
  public cash_pass = '';
  public pregunta1 = "¿Comó calificas el servicio de pago y la atención ciudadana?";
  public pregunta2 = "En general ¿Comó calificas los Servicios Públicos(recolección de basura, alumbrado entre otros) del Municipio de Corregidora?";
  public pregunta3 = "¿Comó calificas la Seguridad Pública?";
  public questionSuccess: QuestionsSuccessfulPayment = <QuestionsSuccessfulPayment>{};
  public anioActual = new Date().getFullYear();

  constructor(private fb: FormBuilder,
              private toastMsg: ToastService,
              private paymentService: PaymentService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.route
      .queryParams
      .subscribe(params => {
        this.questionSuccess.bank = params['bank'];
        this.questionSuccess.referencia = params['cashPass'];
        this.questionSuccess.cash_pass = params['cashPass'];
        this.message = params['messageSuccess'];
        this.isPredial = (params['isPredial'] != null && params['isPredial'] == 'true') ? true : false;
      });
  }
  save() {
    console.log('Guardando datos');
    if (this.isPredial) {
      console.log('Guardando los datos en la tabla ');
      if (this.questionSuccess.answer_question_1 != '' && this.questionSuccess.answer_question_2 != '' && this.questionSuccess.answer_question_3 != ''
      && this.questionSuccess.answer_question_1 != null && this.questionSuccess.answer_question_2 != null && this.questionSuccess.answer_question_3 != null) {
        console.log('');
        //this.questionSuccess.answer_question_1 = this.satisfaction_q1;
        //this.questionSuccess.answer_question_2 = this.satisfaction_q2;
        //this.questionSuccess.answer_question_3 = this.satisfaction_q3;
        //this.questionSuccess.bank = this.bank;
        //this.questionSuccess.cash_pass = this.cash_pass;
        this.questionSuccess.encuesta = this.pregunta1 + "=" + this.questionSuccess.answer_question_1 + "|" + this.pregunta2 + "=" + this.questionSuccess.answer_question_2 + "|"
        + this.pregunta3 + "=" + this.questionSuccess.answer_question_3
        this.paymentService.save(this.questionSuccess).then(res => {
          console.log('Encuentra guardada');
          this.redirect();
        }, err => {
          if (err.body) {

            if (err.body.message) {
              console.log(err.body.message);
              this.redirect();
            } else {
              console.log('Su encuenta no se guardo correctamente');
              this.redirect();
            }
          } else {
            console.log('No se pudo registrar la encuesta');
            this.redirect();
          }
        });
      } else {
        this.toastMsg.error('Favor de llenar la encuesta');
      }

    } else {
      console.log('Redireccionando al portal');
      this.redirect();
    }
  }

  redirect() {
    console.log('Redireccionando al portal');
    this.router.navigate(['/portal/pagos']);
  }
}
