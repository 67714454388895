/**
 * Created by Raul on 17/06/2018.
 */
import {ModalDirective} from 'angular-bootstrap-md';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {RolModel} from '../../../admin.roles.module/service/rol.model';

@Component({
  selector: 'app-authority-detail',
  templateUrl: 'rol-detalle.component.html'
})
export class RolDetalleComponent implements OnInit {
  @ViewChild('authorityModal')
  public modal: ModalDirective;

  @Input()
  authority: RolModel = <RolModel>{
    permisos: []
  };

  ngOnInit() {
  }
}
