<div class="row no-gutters">
  <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
    <div class="page-title">
      <mdb-icon fas icon="arrow-left" routerLink="/portal"></mdb-icon>
      <div mti-color-theme class="bg-icon"></div>
      <div mti-color-theme class="title">
        <div class="w-100 bg-title">Consulta de resolutivo</div>
      </div>
    </div>
  </div>
  <div class="card mt-3 col-lg-12">
    <div mti-color-theme class="card-header unique-color lighten-1 white-text d-flex">
      <div class="mr-auto align-self-center">
        <strong >
          R E S O L U T I V O
        </strong>
      </div>
    </div>
  </div>
  <div class="card mt-3 col-lg-12">
    <mdb-card class="card">
      <div class="card">
        <mdb-card-body class="card-body">

            <div class="row" *ngIf="idResolutivoEncontrado; else elseBlock">

               <div class="md-form col-12 col-lg-4">
                <label for="serie" class="col-lg-12" style="font-weight: bold;">Trámite: </label>
                <br/>
                <label  class="col-lg-12"> <br/>{{nombreTramite}}</label>
               </div>
               <div class="md-form col-12 col-lg-4">
                <label for="serie" class="col-lg-12" style="font-weight: bold;">Folio: </label>
                <br/>
                <label  class="col-lg-12"> <br/>{{folioTramite}}</label>
               </div>
               <div class="md-form col-12 col-lg-4">
                <label for="serie" class="col-lg-12" style="font-weight: bold;">Fecha de aprobacion: </label>
                <br/>
                <label  class="col-lg-12"> <br/>{{fechaHoraTramite}}</label>
               </div>
               <div class="md-form col-12 col-lg-4" *ngIf="mostrarNombreSolicitante">
                <label for="serie" class="col-lg-12" style="font-weight: bold;">Solicitante: </label>
                <br/>
                <label  class="col-lg-12"> <br/>{{nombreCiudadano}}</label>
               </div>
               <div class="md-form col-12 col-lg-4" *ngIf="nombre_completo_funcionario_firma != null">
                <label for="serie" class="col-lg-12" style="font-weight: bold;">Aprobó: </label>
                <br/>
                <label  class="col-lg-12"> <br/>{{nombre_completo_funcionario_firma}}</label>
               </div>
               <div class="md-form col-12 col-lg-4" *ngIf="mostrarMonto">
                <label for="serie" class="col-lg-12" style="font-weight: bold;">Monto: </label>
                <br/>
                <label  class="col-lg-12"> <br/>{{monto}}</label>
               </div>
               <div class="md-form col-12 col-lg-4">
                <label for="vigencia" class="col-lg-12" style="font-weight: bold;">Vigencia: </label>
                <br/>
                <label class="col-lg-12"> <br/>31/12/2024</label>
               </div>
               
            </div>
            <ng-template #elseBlock style="font-weight: bold;">El trámite buscado no existe.</ng-template>
          <br/>
        </mdb-card-body>
      </div>
    </mdb-card>
  </div>
</div>
