import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ConfigItem} from '../../common.module/model/config-item.model';
import {ConsultaExpElecService} from '../service/consulta-exp-elec.service';
import {ExpedientesElectModel} from '../service/expedientes-elect.model';
import {ToastService} from 'ng-uikit-pro-standard';
import {ConsExpElecDetalleComponent} from '../cons-exp-elec-detalle/cons-exp-elec-detalle.component';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import {LocationsModel} from '../service/locations.model';
import {isNullOrUndefined} from 'util';
import {TramitesModel} from '../../admin.tramites.module/service/tramites.model';
import {ExcelService} from '../../common.module/util/exportxls.service';
import {ConsExpElecDuplicadoComponent} from '../cons-exp-elec-duplicados/cons-exp-elec-duplicado.component';

@Component({
  selector: 'app-consulta-exp-elec',
  templateUrl: 'consulta-exp-elec.component.html'
})
export class ConsultaExpElecComponent implements OnInit {
  @ViewChild(ConsExpElecDetalleComponent)
  public modalDetalle;
  public configItems: ConfigItem[] = [];
  @ViewChild(ConsExpElecDuplicadoComponent)
  public modalDuplicado;
  @Input()
  public listaElementos: ExpedientesElectModel[] = [];
  public excellevel: ExpedientesElectModel[] = [];
  public listaElementosDuplicado: ExpedientesElectModel[] = [];
  @Input()
  public expedienteElect: ExpedientesElectModel = <ExpedientesElectModel>{};
  public count: number;
  public listaAExportar: any[] = [];
  public filterModel: any = {
    elemPorPagina: 5,
    pagina: 1,
    forma: 'DESC',
    ordenarPor: '_id',
    adm_tramite_id: '',
    nombre: '',
    apellido_p: '',
    apellido_m: '',
    curp: '',
    rfc: '',
    tipo_persona: '',
    estatus: ''
  };
  public filterModelAll: any = {
    elemPorPagina: 999999,
    pagina: 1,
    forma: 'DESC',
    ordenarPor: '_id',
    adm_tramite_id: '',
    nombre: '',
    apellido_p: '',
    apellido_m: '',
    curp: '',
    rfc: '',
    tipo_persona: '',
    estatus: ''
  };
  dataPdf: any;

  constructor(private toastMsg: ToastService,
              private consultaExpElecService: ConsultaExpElecService,
              private excel: ExcelService) { }

  ngOnInit() {
    this.cargaListaExpElecUsuario();
  }

  pdf() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const tmpData: any[] = [
      [
        {text: 'CURP', style: 'header'},
        {text: 'RFC', style: 'header'},
        {text: 'NOMBRE', style: 'header'},
        {text: 'APELLIDO PATERNO', style: 'header'},
        {text: 'APELLIDO MATERNO', style: 'header'},
        {text: 'RAZON SOCIAL', style: 'header'},
        {text: 'PROPIETARIO', style: 'header'},
        {text: 'TIPO PERSONA', style: 'header'}
      ]
    ];
    const result = this.listaElementos;
    result.forEach(itm => {
      console.log(itm);
      itm.curp = isNullOrUndefined(itm.curp) ? '' : itm.curp;
      itm.rfc = isNullOrUndefined(itm.rfc) ? '' : itm.rfc;
      itm.nombre = isNullOrUndefined(itm.nombre) ? '' : itm.nombre;
      itm.apellido_p = isNullOrUndefined(itm.apellido_p) ? '' : itm.apellido_p;
      itm.apellido_m = isNullOrUndefined(itm.apellido_m) ? '' : itm.apellido_m;
      itm.razon_social = isNullOrUndefined(itm.razon_social) ? '' : itm.razon_social;
      itm.propietario = isNullOrUndefined(itm.propietario) ? '' : itm.propietario;
      itm._tipo_persona = isNullOrUndefined(itm._tipo_persona) ? '' : itm._tipo_persona;
      tmpData.push([
        {text: itm.curp, style: 'anotherStyle'},
        {text: itm.rfc, style: 'anotherStyle'},
        {text: itm.nombre, style: 'anotherStyle'},
        {text: itm.apellido_p, style: 'anotherStyle'},
        {text: itm.apellido_m, style: 'anotherStyle'},
        {text: itm.razon_social, style: 'anotherStyle'},
        {text: itm.propietario, style: 'anotherStyle'},
        {text: itm._tipo_persona, style: 'anotherStyle'}
      ]);
    });
    console.log(tmpData);
    this.dataPdf = {
      border: [false, true, false, false],
      fontSize: 8,
      pageOrientation: 'landscape',
      content: [
        {
          table: {
            body: tmpData
          },
          layout: {
            fillColor(rowIndex, node, colIndex) {
              return rowIndex % 2 === 0 ? '#e9e9e9' : '#fff';
            }
          }
        }
      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
          fillColor: '#3F729B',
          color: '#ffffff'
        },
        anotherStyle: {
          fontSize: 10,
          italic: true,
          alignment: 'left'
        }
      }
    };
    if (this.dataPdf.content[0].table.body.length > 1) {
      pdfMake.createPdf(this.dataPdf).download('Catalogo-Expediente-Electronico.pdf');
    } else {
      this.toastMsg.error('No se puede generar el PDF, no hay datos');
    }
  }

  xls() {
    this.excellevel = this.listaElementos;
    console.log(this.excellevel);
    const result = this.excellevel.map(obj => {
      return <TramitesModel>{
        CURP: obj.curp,
        RFC: obj.rfc,
        NOMBRE: obj.nombre,
        APELLIDO_PATERNO: obj.apellido_p,
        APELLIDO_MATERNO: obj.apellido_m,
        RAZON_SOCIAL: obj.razon_social,
        PROPIETARIO: obj.propietario,
        TIPO_PERSONA: obj._tipo_persona
      };
    });
    console.log(result);
    if (result.length > 0) {
      console.log('Convertiremos a XLS');
      this.excel.exportAsExcelFile(result, 'Catalogo-Expediente-Electronico');
    } else {
      this.toastMsg.error('No se puede generar el XLS, no hay datos');
    }
  }

  nuevoTramite() {
    this.modalDetalle.modal.show();
    this.modalDetalle.detExpedienteForm.reset();
    this.modalDetalle.desPerFisica = true;
    this.modalDetalle.desPerMoral = false;
    this.modalDetalle.detExpedienteForm.enable();
    this.modalDetalle.hideBtn = true;
    this.modalDetalle.showReq = false;
    this.modalDetalle.disDel = true;
    this.modalDetalle.blockLoading = false;
    this.modalDetalle.manageLoading.loadCount = 0;
    this.modalDetalle.staticTabs.setActiveTab(1);

    setTimeout(() => {
      this.modalDetalle.obtenerListaRequisitos();
    }, 500);

    this.modalDetalle.location = <LocationsModel>{};
    this.modalDetalle.expedienteElect = <ExpedientesElectModel>{};
    this.modalDetalle.requirements = [];
    this.modalDetalle.optMunicipio = [];
    this.modalDetalle.optColonia = [];
  }

  buscarRfcDuplicado() {
    if (this.expedienteElect.rfc.length === 13) {
      this.filterModelAll.rfc = this.expedienteElect.rfc.substring(0, 10);
    } else if (this.expedienteElect.rfc.length === 12) {
      this.filterModelAll.rfc = this.expedienteElect.rfc.substring(0, 9);
    } else if (this.expedienteElect.rfc.length === 10 || this.expedienteElect.rfc.length === 9) {
      this.filterModelAll.rfc = this.expedienteElect.rfc;
    }

    this.filterModelAll.id = this.expedienteElect._id;
    console.log(this.filterModel);
    this.consultaExpElecService.obtenerListaExpedientes(this.filterModelAll).then(resp => {
      this.listaElementosDuplicado = resp;

      if (this.listaElementosDuplicado.length > 0) {
        this.toastMsg.warning('Duplicidad en RFC.');
        this.modalDuplicado.modale.show();
      }
    }, err => {
      console.log(err);
      if (err.body.message) {
        this.toastMsg.error(err.body.message);
      } else {
        this.toastMsg.error('Ocurrio un error al consultar la lista de RFC duplicados.');
      }
    });
  }

  cargaListaExpElecUsuario() {
    this.configItems = [
      {
        title: 'CURP',
        nameAttribute: 'curp',
        nameFilter: 'curp'
      },
      {
        title: 'RFC',
        nameAttribute: 'rfc',
        nameFilter: 'rfc'
      },
      {
        title: 'Nombre',
        nameAttribute: 'nombre',
        nameFilter: 'nombre'
      },
      {
        title: 'Apellido paterno',
        nameAttribute: 'apellido_p',
        nameFilter: 'apellido_p'
      },
      {
        title: 'Apellido materno',
        nameAttribute: 'apellido_m',
        nameFilter: 'apellido_m'
      },
      {
        title: 'Razón Social',
        nameAttribute: 'razon_social',
        nameFilter: 'razon_social'
      },
      {
        title: 'Propietario',
        nameAttribute: 'propietario',
        nameFilter: 'propietario'
      },
      {
        title: 'Tipo persona',
        nameAttribute: '_tipo_persona',
        nameFilter: 'tipo_persona',
        typeSelect: true,
        options: [
          {label: 'Todos', value: ''},
          {label: 'Física', value: 'Fisica'},
          {label: 'Moral', value: 'Moral'}
        ]
      },
      {
        title: 'Estatus',
        nameAttribute: '_estatus',
        nameFilter: 'estatus',
        typeSelect: true,
        options: [
          {label: 'Todos', value: ''},
          {label: 'Aprobado', value: 'A'},
          {label: 'Pendiente', value: 'P'},
        ]
      },
      {
        title: 'Ver',
        filteringNotAllowed: true,
        enableButtonAction: true,
        iconButtonAction: 'fa-binoculars'
      },
      {
        title: 'Editar',
        filteringNotAllowed: true,
        enableButtonAction: true,
        iconButtonAction: 'fa-edit'
      }
    ];

    console.log(this.filterModel);
    this.consultaExpElecService.obtenerListaExpedientes(this.filterModel).then(resp => {
      this.listaElementos = resp;
      this.listaElementos.forEach(r => {
        switch (r.tipo_persona) {
          case 'Fisica':
            r._tipo_persona = 'Física';
            break;
          case 'Moral':
            r._tipo_persona = 'Moral';
        }

        switch (r.estatus) {
          case 'A':
            r._estatus = 'Aprobado';
            break;
          case 'R':
            r._estatus = 'Rechazado';
            break;
          case 'P':
            r._estatus = 'Pendiente';
        }
      });

      this.count = null;
    }, err => {
      if (err.body.message) {
        this.toastMsg.error(err.body.message);
      } else {
        this.toastMsg.error('Ocurrio un error al consultar la lista de expedientes.');
      }
    });
  }

  cargarDetalle(event: any) {
    this.modalDetalle.main_action = 0;
    this.modalDetalle.requirements = [];
    this.modalDetalle.detExpedienteForm.reset();

    this.consultaExpElecService.obtenerExpediente(event._id).then(res => {
      this.expedienteElect = res;
      this.modalDetalle.modal.show();

      setTimeout(() => {
        this.modalDetalle.cambioPersona();
        this.modalDetalle.obtenerListaRequisitos();
      }, 500);

      this.modalDetalle.disDel = false;
      this.modalDetalle.blockLoading = true;
      this.modalDetalle.showReq = true;
      this.modalDetalle.staticTabs.setActiveTab(1);
      this.modalDetalle.hideBtn = false;
      this.modalDetalle.blockLoading = true;
      this.modalDetalle.location = res.location;
      this.modalDetalle.buscarListaMunicipios(res.location.municipality_id);
      this.modalDetalle.buscarListaColonias(res.location.township);

      setTimeout(() => {
        this.modalDetalle.buscarListaColonias(res.location.township);
        this.modalDetalle.requisitosDetalle();
      }, 1000);

      this.modalDetalle.detExpedienteForm.disable();
      this.modalDetalle.main_action = 0;
    }, err => {
      if (err.body.message) {
        this.toastMsg.error(err.body.message);
      } else {
        this.toastMsg.error('Ocurrio un error al consultar el detalle del expediente.');
      }
    });
  }

  editarDetalle(event: any) {
    this.modalDetalle.main_action = 1;
    this.modalDetalle.requirements = [];
    this.modalDetalle.detExpedienteForm.reset();
    this.consultaExpElecService.obtenerExpediente(event._id).then(res => {
      this.expedienteElect = res;
      //this.modalDetalle.modal.show();

      setTimeout(() => {
        this.modalDetalle.cambioPersona();
        this.modalDetalle.obtenerListaRequisitos();

        if (this.expedienteElect.tipo_persona == 'Fisica') {
          this.modalDetalle.buscarINE();
        }

          this.modalDetalle.modal.show();
          this.buscarRfcDuplicado();
      }, 500);

      this.modalDetalle.disDel = true;
      this.modalDetalle.blockLoading = true;
      this.modalDetalle.showReq = false;
      this.modalDetalle.staticTabs.setActiveTab(1);
      this.modalDetalle.hideBtn = true;
      this.modalDetalle.blockLoading = true;
      this.modalDetalle.location = res.location;
      this.modalDetalle.buscarListaMunicipios(res.location.municipality_id);
      this.modalDetalle.buscarListaColonias(res.location.township);

      setTimeout(() => {
        this.modalDetalle.buscarListaColonias(res.location.township);
        this.modalDetalle.requisitosDetalle();
      }, 1000);

      this.modalDetalle.detExpedienteForm.enable();
    }, err => {
      if (err.body.message) {
        this.toastMsg.error(err.body.message);
      } else {
        this.toastMsg.error('Ocurrio un error al consultar el detalle del expediente.');
      }
    });
  }
}
