<!--Double navigation-->
<header>

  <!-- Sidebar navigation -->
  <mdb-sidenav #sidenav class="sn-bg-1" [fixed]="false">
    <logo>
      <li>
        <div class="d-flex justify-content-center">
          <!--<img src="assets/img/heraldica.png" class="img-fluid mt-2 white w-85">-->
          <img class="img-fluid mt-2 w-85 img-sidebar" style="width:100px;height:100px;"     [src]="'data:image/jpg;base64,'+logoSidebar">
        </div>
      </li>
      <!--/. Logo -->
    </logo>

    <hr style="border-color: white;">

    <links>
      <!-- Side navigation links -->
      <li>
        <perfect-scrollbar style="max-width: 600px; max-height: 350px;">
          <ul class="collapsible collapsible-accordion mt-0">
            <mdb-squeezebox [multiple]="false" aria-multiselectable="false">
              <mdb-item class="no-collase" >
                <mdb-item-head mdbWavesEffect *ngIf="user.is_admin"  routerLink="catalogo_usuarios_consumos" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Catálogo de Usuarios
                </mdb-item-head>
              </mdb-item>
              <mdb-item class="no-collase" >
                <mdb-item-head mdbWavesEffect *ngIf="user.is_admin" routerLink="catalogo_establecimientos_consumos" routerLinkActive="active">
                  <i class="far fa-hand-pointer"></i> Catálogo de Establecimientos
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase" >
                <mdb-item-head mdbWavesEffect *ngIf="!user.is_admin" routerLink="consumos" routerLinkActive="active">
                  <i class="far fa-list-alt"></i> Consumos
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              <mdb-item class="no-collase" >
                <mdb-item-head mdbWavesEffect routerLink="catalogo_reportes_consumos" routerLinkActive="active">
                  <i class="far fa-list-alt"></i> Reportes
                </mdb-item-head>
                <mdb-item-body></mdb-item-body>
              </mdb-item>
              
            </mdb-squeezebox>
          </ul>
        </perfect-scrollbar>
      </li>
      <!--/. Side navigation links -->
    </links>
    <div class="sidenav-bg mask-strong"></div>
  </mdb-sidenav>
  <!--/. Sidebar navigation -->

  <!-- Navbar -->
  <mdb-navbar SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav"
              [containerInside]="false">

    <navlinks class="navbar-container">
      <!-- SideNav slide-out button -->
      <div class="float-left">
        <a (click)="sidenav.show()" class="button-collapse hidden-nav-button-collapse">
          <i class="fa fa-bars"></i>
        </a>
      </div>
      <!--/. SideNav slide-out button -->
    </navlinks>

    <navlinks>
      <ul class="nav navbar-nav nav-flex-icons ml-auto ie-double-nav ie-hidden-double-nav">
        <li class="nav-item item-movil-admin">
          <a class="nav-link waves-light" mdbWavesEffect (click)="accountComponent.modal.show()">
            <i class="fa fa-user"></i>
            <span class="clearfix d-none d-sm-inline-block"> &nbsp;Hola, {{ username }}</span>
          </a>
        </li>
        <li class="nav-item item-movil-admin">
          <a class="nav-link waves-light" mdbWavesEffect (click)="signOut()">
            <i class="fa fa-sign-out-alt mr-1"></i>
            <span class="clearfix d-none d-sm-inline-block">Cerrar sesión</span>
          </a>
        </li>
      </ul>
    </navlinks>
  </mdb-navbar>
  <!--/. Navbar -->

</header>
<!--/.Double navigation-->
<app-data-user #accountComponent></app-data-user>
<app-notifications-modal #notificationsModal
                         [borrarNotificacionDeListaYDisminuirContador]="borrarNotificacionDeListaYDisminuirContadorFunc">
</app-notifications-modal>
