import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams} from '@ngx-resource/core';
import {ResourceAuth} from '../../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../../common.module/auth/auth-jwt.service';
import {UsuarioModel} from './usuario.model';

@Injectable()
@ResourceParams({
  // url: 'http://localhost:8001'
   pathPrefix: '/administracion'
})
 // export class UsuarioEntidadResource extends Resource {
 export class UsuarioEntidadResource extends ResourceAuth {
   /*constructor(handler: ResourceHandler) {
    super(handler);
   }*/

  constructor(handler: ResourceHandler, auth: AuthServerProvider) {
    super(handler, auth);
  }
  @ResourceAction({
    path: '/ss/usuario'
  })
  usuarioSesion: IResourceMethod<any, UsuarioModel>;
}
