export enum COMPONENTES {
  NAVBAR = '<mdb-navbar',
  BUTTON = '<button',
  ICONOS = '<mdb-icon',
  ACCORDIONS = '<mdb-accordion-item',
  TABSET = '<mdb-tabset',
  DIV = '<div',
  A = '<a',
  FOOTER = '<footer',
  LOGO = '<logo',
  SPAN = '<span',
  CAROUSEL = '<mdb-carousel',
  BADGE = '<mdb-badge'
}
