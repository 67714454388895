import {Injectable} from '@angular/core';
import {
  IResourceMethod,
  Resource,
  ResourceAction,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
//import {ConsultaPagoModel} from "./consulta-pago.model";

@Injectable()
@ResourceParams({
  pathPrefix: '/ven-unica'
  //url: 'http://localhost:8006'
})

export class ConsultaResolutivoResource extends ResourceAuth {
  constructor(restHandler: ResourceHandler, provService: AuthServerProvider) { 
    super(restHandler, provService); 
  }

/*export class ConsultaResolutivoResource extends Resource {
  constructor(requestHandler: ResourceHandler) {
  super(requestHandler);
}*/

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/na/consulta-info-resolutivo/{!id}'
  })
  obtenerInfoResolutivo: IResourceMethod<{ id: string}, any>;

  /**configuracion para liga ?=
   *   @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/na/consulta-info-recibo'
  })
  obtenerInfoResolutivo: IResourceMethod<{ id: string}, any>;
  */

  @ResourceAction({
    path: '/na/consulta-resolutivo-externo',
    method: ResourceRequestMethod.Post
  })
  getComprobanteExterno: IResourceMethod<any, any>;
}
