/**
 * Created by MTI on 25/06/2018.
 */

import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {QuestionsSuccessfulPayment} from './questionssuccessfulpayment.model';
import {Injectable} from '@angular/core';
import { ResourceAuth } from 'src/app/common.module/auth/authenticate.resource';
import { AuthServerProvider } from 'src/app/common.module/auth/auth-jwt.service';
@Injectable()
@ResourceParams({
  pathPrefix: '/payments/'
  // url: 'http://localhost:8087'
})
export class PaymentResource extends ResourceAuth {
  constructor(restHandler: ResourceHandler, private  auth: AuthServerProvider) {
    super(restHandler, auth);
  }
// export class PaymentResource extends Resource {
//   constructor(restHandler: ResourceHandler) {
//     super(restHandler);
//   }
  @ResourceAction({
    path: '/na/question_successful',
    method: ResourceRequestMethod.Post
  })
  save: IResourceMethod<QuestionsSuccessfulPayment, any>;
}
