<footer [ngClass]="{'loginPrincipal': ocultarNavegacion}" mti-color-theme class="page-footer font-small pt-0 pb-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center text-md-left">
        <div class="row">

          <div class="col-12 col-lg-3 pl-lg-3 py-3 text-center-responsive">
            <div class="row">
              <div class="col-12">
                <h6 class="mb-1 font-weight-bold">Horarios de atención</h6>
                <!--div class="line-break">{{configuracion.horarioAtencion}}</div-->
                <div class="line-break">{{ configuracion.horarioAtencion }}</div>
                <div class="mt-4">
                  <mdb-icon fas icon="phone" size="2x" class="pie-color-icono mr-1"></mdb-icon>
                  <span class="h6">{{ configuracion.telefonoContacto }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-6 border-left-2px text-center-responsive pl-lg-3 py-3 content-info-footer">
            <div class="row">
              <div class="col-lg-6 mb-auto">
                <div class="pb-3">
                  <h6 class="mb-1 font-weight-bold">Dirección</h6>
                  <div class="line-break">{{ configuracion.direccion }}</div>
                </div>
              </div>
              <div class="col-lg-6 pt-2 pb-2">
                <agm-map [latitude]="configuracion.ubicacionLatitud" [longitude]="configuracion.ubicacionLongitud"
                         [zoom]="zoom"
                         style="height: 170px;" (mapClick)="placeMarker()">
                  <agm-marker [latitude]="configuracion.ubicacionLatitud"
                              [longitude]="configuracion.ubicacionLongitud"></agm-marker>
                </agm-map>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-3 border-left-2px pl-lg-3 py-3 content-info-footer">
            <div class="row">
              <div class="col-12 text-center">
                <div>
                  <h6 class="mb-1 font-weight-bold">{{ configuracion.mensajeRedesSociales }}</h6>
                </div>
                <div class="py-2">
                  <a href="javascript: void(0)">
                    <img src="assets/img/facebook_icono.png" height="28px" style="margin-top: -11px;"
                    (click)="redirect(configuracion.urlFacebook)">
                   
                  </a>
                  <a href="javascript: void(0)">
                    <img src="assets/img/twiter_icono.png" height="28px" style="margin-top: -11px;" class="ml-2"
                    (click)="redirect(configuracion.urlTwitter)">
                  </a>
                </div>
              </div>
              <div class="col-12 pl-4 pr-2 py-4 d-flex justify-content-center">
                <div class="row">
                  <div class="col-8">
                    <img height="60px" alt="" [src]="'data:image/jpg;base64,'+configuracion.logoPieDePagina">
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div mti-color-theme class="container-fluid pie-fondo-color">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-12 p-1">
          <div class="row">
            <p class="footer-letters m-auto">
              <i class="fa fa-copyright"></i>
              {{ configuracion.mensajePiePagina }}
            </p>
          </div>
        </div>
        <div class="col-lg-9 col-md-9 col-12 p-1">
          <div class="row justify-content-center">
            <p class="footer-letters">
              <span class="ml-2 mr-2" *ngIf="configuracion.urlTransparencia"></span>
              <a href="javascript: void(0)" (click)="redirect(configuracion.urlTransparencia)"
                 *ngIf="configuracion.urlTransparencia">Transparencia</a>
              <span class="ml-2 mr-2" *ngIf="configuracion.urlAvisoPrivacidad"></span>
              <a href="javascript: void(0)" (click)="redirect(configuracion.urlAvisoPrivacidad)"
                 *ngIf="configuracion.urlAvisoPrivacidad">Aviso de Privacidad</a>
              <span class="ml-2 mr-2" *ngIf="configuracion.urlDenunciasYQuejas"></span>
              <a href="javascript: void(0)" (click)="redirect(configuracion.urlDenunciasYQuejas)"
                 *ngIf="configuracion.urlDenunciasYQuejas">Denuncias y quejas</a>
              <span class="ml-2 mr-2" *ngIf="configuracion.urlProteccionDatos"></span>
              <a href="javascript: void(0)" (click)="redirect(configuracion.urlProteccionDatos)"
                 *ngIf="configuracion.urlProteccionDatos">Protección de datos</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

</footer>
