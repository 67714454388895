/**
 * Created by orojo on 24/07/2019.
 */

import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {environment} from '../../../../../environments/environment';
import {SimPaymentModel} from '../../../model/sim-payment.model';

/**
 @Injectable()
 @ResourceParams({
  url: 'http://localhost:8072'
})
 export class SimResource extends Resource {
  constructor(resHandler: ResourceHandler) {
    super(resHandler);
  }
 **/
@Injectable()
@ResourceParams({
  // pathPrefix: '/sim'
  url: environment.apiBaseUrl + '/api/sim'
  // url: 'http://localhost:8025'
})
export class SimNaResource extends Resource {
  constructor(resHandler: ResourceHandler) {
    super(resHandler);
  }

  @ResourceAction({
    path: '/na/case-file?id_req={!id_req}&&id_form={!id_form}',
    method: ResourceRequestMethod.Get
  })
  getCaseFile: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/na/status-history/{!id}',
    method: ResourceRequestMethod.Get
  })
  getStatus: IResourceMethod<{ id: number }, any>;

  @ResourceAction({
    path: '/na/paid-payments/{!id}',
    method: ResourceRequestMethod.Get
  })
  getTicket: IResourceMethod<{ id: any }, SimPaymentModel[]>;

}
