import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HttpModule} from '@angular/http';
import {FilterByPipe} from './util/filter.pipe';
import {RouterModule} from '@angular/router';
import {DataShareService} from './util/data.share';
import {PageNotFoundComponent} from './components/generic/not-found/not-found.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {AuthService} from './auth/auth.service';
import {AuthGuardLoged} from './auth/auth-loged.service';
import {AuthServerProvider} from './auth/auth-jwt.service';
import {ModalConfirmComponent} from './components/generic/modal-confirm/modal-confirm.component';
import {Ng2Webstorage} from 'ngx-webstorage';
import {ResourceModule} from '@ngx-resource/handler-ngx-http';

import {LayoutListComponent} from './components/generic/layout-list/layout-list.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ResourceAuth} from './auth/authenticate.resource';
import {RequirementsResource} from './resource/requirements.resource';
import {UploadRequirementsComponent} from './components/upload-requirements.component/upload-requirements.component';
import {ExcelService} from './util/exportxls.service';
import {AuthGuardAdmin} from './auth/auth-guard.admin.service';
import {AuthGuardPortal} from './auth/auth-guard.portal.service';
import {NgProgressInterceptor} from 'ngx-progressbar';
import {RfcService} from './service/rfc.service';
import {RfcResource} from './resource/rfc.resource';
import {PaidPaymentResource} from './resource/paid-payment.resource';
import {HistoryCommentsComponent} from './components/history-comments/history-comments.component';
import {FormalitiesNotesService} from './components/history-comments/service/formality-notes.service';
import {FormalityNotesResource} from './components/history-comments/service/formality-notes.resource';
import {EventBusService} from './util/event-bus.service';
import {HistoryCommentsAdminComponent} from './components/history-comments/history-comments-admin.component';
import {CommentsModalComponent} from './components/history-comments/comments-modal.component';
import {ListItemsComponent} from './components/list-items/list-items.component';
import {ListItemsReportesComponent} from './components/list-items/list-items-reportes.component';
import {AuthGuardPortalEmployee} from './auth/auth-guard.portal.employee.service';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {BillingComponent} from './components/billing-component/billing-information.component';
import {ReceiptListComponent} from './components/receipt-list-component/receipt-list.component';
import {FormalityValidateResource} from './resource/formalities-validate.resource';
import {AuthGuardContractors} from './auth/auth-guard.contractors.service';
import {AuthGuardProviders} from './auth/auth-guard.providers.service';
import {ResolutiveComponent} from './components/resolutive-component/resolutive-button.component';
import {ResolutiveResource} from './resource/resolutive.resource';
import {ResolutiveService} from './service/resolutive.service';
import {UnlockFrmService} from './service/unlock-formality.service';
import {UnlockFrmResource} from './resource/unlock-formality.resource';
import {MDBBootstrapModulePro} from 'ng-uikit-pro-standard';
import {MainListComponent} from './components/generic/form-base/main-list.component';
import {ItemsListComponent} from './components/generic/form-base/items-list/items-list.component';
import {CreationModalComponent} from './components/generic/form-base/creation-modal/creation-modal.component';
import {UpdateModalComponent} from './components/generic/form-base/update-modal/update-modal.component';
import {CreationFormComponent} from './components/generic/form-base/creation-form/creation-form.component';
import {NotificacionesComponent} from '../portal.reporte-ciudadano.module/notificacions/notifications.component';
import {AgmCoreModule} from '@agm/core';
import {PlantillaOficioComponent} from './components/plantilla-oficio/plantilla-oficio.component';
import {PlantillaResolutivoComponent} from './components/plantilla-resolutivo/plantilla-resolutivo.component';
import {VentanillaUnicaService} from './service/ventanilla-unica.service';
import {VentanillaUnicaResource} from './resource/ventanilla-unica.resource';
import {LoginCitizenComponent} from '../main-portal.component/login.component/login.component';
import {LocationsRepCiudService} from './service/locations-rep-ciud.service';
import {EvidenciaComponent} from './components/evidence/evidence.component';
import {DatosAdicionalesComponent} from './components/datos.adicionales.component/datos-adicionales.component';
import {LocationsRepCiudResource} from '../portal.reporte-ciudadano.module/service/locations-rep-ciud.resource';
import {UbicacionComponent} from './components/ubication/ubication.component';
import {DatosCiudadanoComponent} from './components/datos-solicitante/datos-ciudadano.component';
import {DatosCiudadanoSinsegComponent} from '../portal.reporte-ciudadano.module/ms-reporte-ciudadano/datos-ciudadano-sinseg.component';
import {EvidenciaSinsegComponent} from '../portal.reporte-ciudadano.module/evidence/evidencia-sinseg.component';
import {UbicacionSinsegComponent} from '../portal.reporte-ciudadano.module/ubication/ubicacion-sinseg.component';
import {AuthGuardInbox} from './auth/auth-guard.inbox';
import {ResourceGlobalConfig, ResourceQueryMappingMethod} from '@ngx-resource/core';
import {ZonaService} from './service/zona.service';
import {ZonaResource} from './service/zona.resource';
import {PagoEnLineaComponent} from './components/pago-en-linea.component/pago-en-linea.component';
import {PagoEnLineaService} from './components/pago-en-linea.component/service/pago-en-linea.service';
import {PagoEnLineaResource} from './components/pago-en-linea.component/service/pago-en-linea.resource';
import {PagoModalComponent} from './components/pago-modal.component/pago-modal.component';
import {PaseCajaResource} from './components/pago-modal.component/service/pase-caja.resource';
import {PaseCajaService} from './components/pago-modal.component/service/pase-caja.service';
import {AuthGuardChildren} from './auth/auth-guard-children';
import {ProviderFormatComponent} from './components/upload-requirements.component/visor/provider-pdf-format.component';
import {BpmEstadoProcesoDetalleService} from '../common.tramite-vu.module/entidad.formulario.component/service/bpm-estado-proceso-detalle.service';
import {BpmEstadoProcesoDetalleResource} from '../common.tramite-vu.module/entidad.formulario.component/service/bpm-estado-proceso-detalle.resource';
import {PaymentSantanderComponent} from './components/payment-santander-component/payment-santander.component';
import {PaymentComponent} from './components/payment-component/payment.component';
import {FormalitiesConfigService} from './service/formalitiesConfig.service';
import {FormalitiesConfigResource} from './resource/formalitiesConfig.resource';
import {EvaluateFormalityService} from './components/evaluate-formality/service/evaluate-formality.service';
import { PaymentMethodResource } from './components/payment-component/service/payment.resource';

import { ConfiguracionChatService } from './components/chat-component/service/configuracion-chat.service';
import { ConfiguracionChatResource } from './components/chat-component/service/configuracion.chat.resource';
import { FlujoService } from './components/plantilla-oficio/service/flujo.service';
import { FlujoResource } from './components/plantilla-oficio/service/resource/flujo.resource';
import { FlujoServiceR } from './components/plantilla-resolutivo/service/flujo.service';
import { FlujoResourceR } from './components/plantilla-resolutivo/service/resource/flujo.resource';
import { RolService } from './components/plantilla-oficio/service/rol.service';
import { RolResource } from './components/plantilla-oficio/service/resource/rol.resource';
import { RolServiceR } from './components/plantilla-resolutivo/service/rol.service';
import { RolResourceR } from './components/plantilla-resolutivo/service/resource/rol.resource';
import { DirectivasModule } from '../directivas.module/directivas.module';
import { ConfiguracionGeneralService } from './service/configuracion-general.service';
import { ConfiguracionGeneralResource } from './resource/configuracion-general.resource';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';


import {ListItemsComponentConsumos} from './components-consumos/list-items/list-items.component';
import {ListItemsComponentEstablecimientosConsumos} from './components-consumos/list-items/list-items-establecimientos.component';
import {ListItemsComponentProductosConsumos} from './components-consumos/list-items/list-items-productos.component'
import {ListItemsFamiliaresComponent } from './components/list-items/list-items-familiares.component';

import {ListItemsComponentMenuEmpleados} from './components-menu-empleados/list-items/list-items-menu-empleados.component';
import { ListItemsPaginacionComponent } from './components/list-items/list-items-paginacion.component';
import { UploadRequirementsCopyComponent } from './components/upload-requirements.component/upload-requirements-copy.component';
import { VisorComponent } from './components/upload-requirements.component/visor/visor.component';
import { UploadRequirementsComponentCopyCopy } from './components/upload-requirements.component/upload-requirements.component copy';
import { FormalitiesPaymentComponent } from './components/evaluate-formality/payment-modal/payment-modal.component';
import { AuthCorregidoraResource } from './auth/auth-corregidora.resource';
import { ListItemsMcComponent } from './components/list-items-mc/list-items-mc.component';
import { FormalityValidateMcResource } from './resource/formalities-validate-mc.resource';
import { EvaluateFormalityResource } from './components/evaluate-formality/service/evaluate-formality.resource';
import { SimResource } from './components/evaluate-formality/service/sim.resource';
import { SimNaResource } from './components/evaluate-formality/service/sim-na.resource';
import { PaymentMethodPortalResource } from './components/evaluate-formality/service/payment-method-portal.resource';
import { RequestsService } from './service/requests.service';
import { RequestsResource } from './service/requests.resource';
import { AuthCorregidoraService } from './auth/auth-corregidora.service';
import { EvaluateFormalityContratistasService } from './components/evaluate-formality/service/evaluate-formality-contratistas.service';
import { PaymentMethodContratisasResource } from './components/payment-component/service/payment-contratistas.resource';
import { OperacionesMapaService } from './service/operaciones-mapa.service';
import { ReporteTramitePdfService } from './service/reporte-tramite-pdf.service';
import { ModalConfirmComponentCenter } from './components/generic/modal-confirm-center/modal-confirm-center.component';
import { CnfFolioService } from '../admin.cnf-folio.module/service/cnf-folio.service';
import { CnfFolioResource } from '../admin.cnf-folio.module/service/cnf-folio.resource';
import { AuthGuardMcPortal } from './auth/auth-guard-mc.portal.service';
import {AdeudosService} from '../portal.consulta-adeudos.module/service/adeudos.service';
import {AdeudosResource} from '../portal.consulta-adeudos.module/resource/adeudos.resource';
import { EditorModule } from '@tinymce/tinymce-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// Configuracion global para el envio de los query params.
ResourceGlobalConfig.queryMappingMethod = ResourceQueryMappingMethod.None;

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    MDBBootstrapModule.forRoot(),
    MDBBootstrapModulePro.forRoot(),
    Ng2Webstorage.forRoot({prefix: 'mcp', separator: '-'}),
    ResourceModule.forRoot(),
    PerfectScrollbarModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDnD1KfsnmR-s5cysMgYNfVwUcyurCs8lo'
    }),
    GooglePlaceModule,
    DirectivasModule,
    EditorModule
  ],
  exports: [
    HttpModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MDBBootstrapModule,
    MDBBootstrapModulePro,
    Ng2Webstorage,
    ResourceModule,
    FilterByPipe,
    DatosCiudadanoComponent,
    EvidenciaComponent,
    NotificacionesComponent,
    UbicacionComponent,
    DatosCiudadanoSinsegComponent,
    EvidenciaSinsegComponent,
    UbicacionSinsegComponent,
    ModalConfirmComponent,
    PageNotFoundComponent,
    UploadRequirementsComponent,
    PagoEnLineaComponent,
    PagoModalComponent,
    HistoryCommentsComponent,
    HistoryCommentsAdminComponent,
    UploadRequirementsCopyComponent,
    UploadRequirementsComponentCopyCopy,
    VisorComponent,
    ListItemsComponent,
    ListItemsMcComponent,
    ListItemsReportesComponent,
    BillingComponent,
    ReceiptListComponent,
    ResolutiveComponent,
    MainListComponent,
    ItemsListComponent,
    CreationModalComponent,
    UpdateModalComponent,
    CreationFormComponent,
    DatosAdicionalesComponent,
    PlantillaOficioComponent,
    PlantillaResolutivoComponent,
    LoginCitizenComponent,
    ProviderFormatComponent,
    ListItemsComponentConsumos,
    ListItemsComponentEstablecimientosConsumos,
    ListItemsComponentProductosConsumos,
    ListItemsComponentMenuEmpleados,
    ListItemsFamiliaresComponent,
    ListItemsPaginacionComponent,
    PaymentSantanderComponent,
    FormalitiesPaymentComponent,
    PaymentComponent,
    ModalConfirmComponentCenter
  ],
  declarations: [
    FilterByPipe,
    DatosCiudadanoComponent,
    EvidenciaComponent,
    NotificacionesComponent,
    UbicacionComponent,
    DatosCiudadanoSinsegComponent,
    EvidenciaSinsegComponent,
    UbicacionSinsegComponent,
    ModalConfirmComponent,
    PageNotFoundComponent,
    LayoutListComponent,
    UploadRequirementsComponent,
    UploadRequirementsCopyComponent,
    UploadRequirementsComponentCopyCopy,
    VisorComponent,
    PagoEnLineaComponent,
    PagoModalComponent,
    HistoryCommentsComponent,
    HistoryCommentsAdminComponent,
    CommentsModalComponent,
    ListItemsComponent,
    ListItemsReportesComponent,
    BillingComponent,
    ReceiptListComponent,
    ResolutiveComponent,
    MainListComponent,
    ItemsListComponent,
    CreationModalComponent,
    UpdateModalComponent,
    CreationFormComponent,
    PlantillaOficioComponent,
    PlantillaResolutivoComponent,
    DatosAdicionalesComponent,
    LoginCitizenComponent,
    ProviderFormatComponent,
    ListItemsComponentConsumos,
    ListItemsComponentEstablecimientosConsumos,
    ListItemsComponentProductosConsumos,
    ListItemsComponentMenuEmpleados,
    ListItemsComponentProductosConsumos,
    ListItemsFamiliaresComponent,
    ListItemsPaginacionComponent,
    PaymentSantanderComponent,
    PaymentComponent,
    FormalitiesPaymentComponent,
    ListItemsMcComponent,
    ListItemsComponentProductosConsumos,
    ModalConfirmComponentCenter
  ],

  providers: [
    DataShareService,
    AuthGuardAdmin,
    AuthGuardPortal,
    AuthGuardMcPortal,
    AuthGuardPortalEmployee,
    AuthGuardLoged,
    AuthGuardInbox,
    AuthGuardChildren,
    ResourceAuth,
    AuthServerProvider,
    AuthService,
    RequirementsResource,
    ExcelService,
    RfcService,
    RfcResource,
    PaidPaymentResource,
    AuthGuardProviders,
    AuthGuardContractors,
    PagoEnLineaResource,
    PagoEnLineaService,
    PaseCajaResource,
    PaseCajaService,
    ResolutiveResource,
    ResolutiveService,
    UnlockFrmResource,
    UnlockFrmService,
    ZonaService,
    ConfiguracionChatService,
    FlujoService,
    FlujoServiceR,
    RolService,
    RolResource,
    FlujoResource,
    FlujoResourceR,
    ConfiguracionChatResource,
    ZonaResource,
    FormalitiesConfigResource,
    FormalitiesConfigService,
    PaymentMethodResource,
    EvaluateFormalityResource,
    EvaluateFormalityService,
    SimResource,
    SimNaResource,
    PaymentMethodPortalResource,
    RequestsService,
    RequestsResource,
    AdeudosService,
    AdeudosResource,
    EvaluateFormalityContratistasService,
    PaymentMethodContratisasResource,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NgProgressInterceptor,
      multi: true
    },
    FormalitiesNotesService,
    FormalityNotesResource,
    EventBusService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    FormalityValidateResource,
    FormalityValidateMcResource,
    VentanillaUnicaService,
    VentanillaUnicaResource,
    LocationsRepCiudService,
    LocationsRepCiudResource,
    BpmEstadoProcesoDetalleService,
    BpmEstadoProcesoDetalleResource,
    ConfiguracionGeneralService,
    ConfiguracionGeneralResource,
    FormalitiesConfigResource,
    FormalitiesConfigService,
    EvaluateFormalityService,
    PaymentMethodResource,
    AuthCorregidoraResource,
    AuthCorregidoraService,
    OperacionesMapaService,
    ReporteTramitePdfService,
    CnfFolioService,
    CnfFolioResource
  ]
})
export class AppCommonLibsModule {
}
