import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {ResourceAuth} from '../auth/authenticate.resource';
import {AuthServerProvider} from '../auth/auth-jwt.service';
/**
 * Created by EMOLINA on 24/10/2018.
 */
@Injectable()
@ResourceParams({
  // url: 'http://localhost:8082'
  pathPrefix: '/formalities'
})

export class UnlockFrmResource extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resourceHandler, authProv);
  }

  // export class UnlockFrmResource extends Resource {
  //   constructor(requestHandler: ResourceHandler) {
  //     super(requestHandler);
  //   }
    
  @ResourceAction({
    path: '/formality/unlock/{!id}',
    method: ResourceRequestMethod.Patch
  })
  unlockFormality: IResourceMethod<{id: number}, any>;

}
