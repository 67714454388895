import {Injectable} from '@angular/core';
import {PaseCajaResource} from './pase-caja.resource';

@Injectable({
  providedIn: 'root'
})
export class PaseCajaService {

  constructor(private paseCajaResource: PaseCajaResource) {
  }
}
