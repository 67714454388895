import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {Injectable} from '@angular/core';
import {LoginModel} from './login.model';
import {environment} from '../../../environments/environment';
import {JwtToken} from './jwt-token.model';
/**
 * Created by MTI on 09/05/2018.
 */

@Injectable()
@ResourceParams({
  url: environment.apiBaseUrl
})
export class AuthResource extends Resource {

  constructor(handler: ResourceHandler) {
    super(handler);
  }

  @ResourceAction({
    // IResourceAction
    method: ResourceRequestMethod.Post,
    path: '/auth'
  })
  login: IResourceMethod<LoginModel, JwtToken>; // will make an post poolRequest to /auth/login

  // @ResourceAction({
  //   // IResourceAction
  //   method: ResourceRequestMethod.Post,
  //   path: '/auth'
  // })
  // loginMc: IResourceMethod<LoginModel, JwtToken>; // will make an post poolRequest to /auth/login


  
}
