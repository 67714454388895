import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AvisosModel} from '../../admin.avisos.module/service/avisos.model';
import {ModalDirective} from 'angular-bootstrap-md';
import {PosicionesConstants} from '../../admin.avisos.module/service/posiciones.constants';

@Component({
  selector: 'app-avisos-ciudadano',
  templateUrl: './avisos-ciudadano.component.html',
  styles: []
})
export class AvisosCiudadanoComponent implements OnInit {
  public posicionesConst = new PosicionesConstants();
  @Input()
  public aviso: AvisosModel;
  @ViewChild('modalAviso') public modal: ModalDirective;

  constructor() {
  }

  ngOnInit() {
  }

}
