/**
 * Created by Raul on 03/05/2018.
 */

import {Injectable} from '@angular/core';
import {ProvidersResource} from './providers.resource';
import {EvaluationDetailModel} from './model/evaluation/evaluation-detail.model';
import {PassCashCreateModel} from './model/pass-cash-create.model';

@Injectable()
export class ProvidersService {

  constructor(private providersResource: ProvidersResource) {
  }

  getFiscalYears() {
    return this.providersResource.getFiscalYears({});
  }

  getPublicFile(id: number) {
    return this.providersResource.getPublicFile({id: id});
  }

  getValidRfc() {
    return this.providersResource.getValidRfc({});
  }

  getSanctions(pAnioSancion: string) {
    return this.providersResource.getSanctions({pAnioSancion: pAnioSancion});
  }

  getEvaluations() {
    return this.providersResource.getEvaluations();
  }

  getDetailEvaluation(idEvaluation: number): Promise<EvaluationDetailModel> {
    return this.providersResource.getDetailEvaluation({id: idEvaluation});
  }

  getCompetitions(pAnioCompetition: string) {
    return this.providersResource.getCompetitions({pAnioCompetition: pAnioCompetition});
  }

  getDetailCompetition(idCompetition: string, pAnioCompetition: string) {
    return this.providersResource.getDetailCompetition({id: idCompetition, pAnioCompetition: pAnioCompetition});
  }

  printCompetition(idCompetition: number) {
    return this.providersResource.printCompetition({id: idCompetition});
  }

  getPublicTenders(pAnioTender: string) {
    return this.providersResource.getPublicTenders({pAnioTender: pAnioTender});
  }

  getPublicTendersAuth(pAnioTender: string) {
    return this.providersResource.getPublicTendersAuth({pAnioTender: pAnioTender});
  }

  detailPublicTenders(pAnioTender: string, base_key: number) {
    return this.providersResource.detailPublicTenders({pAnioTender: pAnioTender, id: base_key});
  }

  detailProviderTenders(pAnioTender: string, base_key: number) {
    return this.providersResource.detailProviderTenders({pAnioTender: pAnioTender, id: base_key});
  }

  getPublicTendersNoLogin(pAnioTender: string) {
    return this.providersResource.getPublicTendersNoLogin({pAnioTender: pAnioTender});
  }

  detailPublicTendersNoLogin(pAnioTender: string, base_key: number) {
    return this.providersResource.detailPublicTendersNoLogin({pAnioTender: pAnioTender, id: base_key});
  }

  getProviderTenders(pAnioTender: string) {
    return this.providersResource.getProviderTenders({pAnioTender: pAnioTender});
  }

  saveCashPass(passCashCreateModel: PassCashCreateModel) {
    return this.providersResource.saveCassPass(passCashCreateModel);
  }

  printCashPass(base_key: number) {
    return this.providersResource.printCashPass({id: base_key});
  }

  downloadGivens(clave: string) {
    return this.providersResource.downloadGivens({clave: clave});
  }

  getReceiptPayments(pAnio: string) {
    return this.providersResource.getReceiptPayments({pAnio: pAnio});
  }

  detailReceiptPayment(pAnio: string, caja_univ_key: number) {
    return this.providersResource.detailReceiptPayment({pAnio: pAnio, id: caja_univ_key});
  }

  downloadXml(pase_caja: number) {
    return this.providersResource.downloadXml({pCve: pase_caja});
  }

  printReceipt(pCve: number) {
    return this.providersResource.printReceipt({pCve: pCve});
  }

  printCashPassReceipt(pase_caja: number) {
    return this.providersResource.printCashPassReceipt({id: pase_caja});
  }

}
