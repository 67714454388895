import { Injectable } from '@angular/core';
import {LocationsResource} from "./locations.resource";
import {LocationsModel} from "./locations.model";

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private locationsResource: LocationsResource) { }

  obtenerListaEstados(): Promise<LocationsModel []>{
    return this.locationsResource.obtenerEstados();
  }

  obtenerListaMunicipios(key: string): Promise<LocationsModel []>{
    return this.locationsResource.obtenerListaMunicipios({key:key});
  }

  obtenerListaColonias(municipio_id: string, key: string): Promise<LocationsModel []>{
    return this.locationsResource.obtenerListaColonias({municipio_id: municipio_id, key: key});
  }

  obtenerDatosLocation(municipio_id: string, key: string, township: string): Promise<LocationsModel>{
    return this.locationsResource.obtenerDatosLocation({municipio_id: municipio_id, key: key, township: township});
  }

}
