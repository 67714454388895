<div mdbModal #modalAviso="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true"
     [config]="{backdrop: aviso.oscurecer, ignoreBackdropClick: !aviso.oscurecer}"
     [ngClass]="{'fade bottom modal-scrolling':aviso.posicion == posicionesConst.ABAJO,
     'fade top modal-scrolling':aviso.posicion == posicionesConst.ARRIBA}" *ngIf="aviso">
  <div class="modal-dialog modal-notify modal-info" role="document"
       [ngClass]="{'modal-top':aviso.posicion == posicionesConst.ARRIBA,'modal-bottom':aviso.posicion == posicionesConst.ABAJO,
       'modal-frame': (aviso.posicion == posicionesConst.ARRIBA || aviso.posicion == posicionesConst.ABAJO)}">
    <div class="modal-content text-center">
      <div mti-color-theme class="modal-header d-flex justify-content-center" *ngIf="aviso.tieneTitulo && aviso.titulo != null">
        <p class="heading">{{aviso.titulo}}</p>
      </div>

      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <p [ngClass]="{'pt-3 pr-2':aviso.posicion != posicionesConst.CENTRO}">{{aviso.cuerpo}}</p>
          <a type="button" mdbBtn color="primary" outline="false" class="waves-light" mdbWavesEffect
             data-dismiss="modal"
             (click)="modalAviso.hide()" *ngIf="aviso.posicion != posicionesConst.CENTRO">Aceptar</a>
        </div>
      </div>

      <div class="modal-footer justify-content-center" *ngIf="aviso.posicion == posicionesConst.CENTRO">
        <a type="button" mdbBtn color="primary" outline="false" class="waves-light" mdbWavesEffect data-dismiss="modal"
           (click)="modalAviso.hide()">Aceptar</a>
      </div>
    </div>
  </div>
</div>
