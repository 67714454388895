/**
 * Created by MTI on 15/08/2018.
 */

import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageModel } from './message.model';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { EventBusService } from '../../util/event-bus.service';
import { ChatRequestResource } from './service/chat-request.resource';
import { UserAuth } from '../../auth/user-auth.model';
import { AuthServerProvider } from '../../auth/auth-jwt.service';
import { Title } from '@angular/platform-browser';
import { ChatGroupModel } from './chat-group.model';
import { ModalDirective, ToastService } from 'ng-uikit-pro-standard';
import { UsuarioService } from './../../../admin.admin.module/service/usuario.service';
import { ConfiguracionChatService } from './service/configuracion-chat.service';

@Component({
  selector: 'app-chat-admin-component',
  templateUrl: 'chat-admin.component.html'
})
export class ChatAdminComponent implements OnInit, OnDestroy {
  public message: MessageModel[] = [];
  public chatList: ChatGroupModel[] = [];
  public showChat = false;
  public connectionState: boolean;

  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild('basicModal') basicModal?: ModalDirective;
  public chatWindows;

  private token: string;
  private adminToken: string;
  private user: UserAuth;
  private notificationInterval: any;
  private originalTitle: string;
  private notificationTitle: string;
  private cuentaNotificaciones = 0;
  public citizenName: string;
  public formalityName: string;
  public test = true;

  constructor(private toastMsg: ToastService,
    private eb: EventBusService,
    private chatRequestResource: ChatRequestResource,
    private titleService: Title,
    private authProv: AuthServerProvider,
    private loggedUserRoles: UsuarioService,
    private configuracionChatService: ConfiguracionChatService) {
  }

  ngOnInit() {
    this.user = this.authProv.getUserDecoded();
    this.loggedUserCanAnswerChat().then(userCanAnswer => {
      if (userCanAnswer) {
        this.originalTitle = this.titleService.getTitle();
        this.titleService.setTitle(this.originalTitle);
        this.notificationTitle = 'Hay una nueva solicitud de chat';
        this.eb.registerHandler('ms.messages.new.chat', (error, message) => {
          if ((sessionStorage.getItem('mcp-id_token') != null)) {
            if (error) {
              return;
            }
            if (!message.body) {
              return;
            }
            this.token = message.body.token;
            if (message.body.request) {
              this.citizenName = message.body.request.name;
              if (message.body.request.formalityName && message.body.request.formalityName.trim() != '')
                this.formalityName = message.body.request.formalityName;
            }
            this.basicModal.show();
            this.newChatNotification();
          }
        });
        this.connectionState = this.eb.connected;
        window.addEventListener('beforeunload', ev => this.beforeunloadHandler(ev));
      }
    });
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(this.originalTitle);
    this.chatList.forEach(chat => {
      this.cleanMessages(chat.token, chat.adminToken, <MessageModel>{ error: '' });
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.chatList.forEach(chat => {
      this.cleanMessages(chat.token, chat.adminToken, <MessageModel>{ error: '' });
    });
    if (window.confirm('¿Está seguro que desea salir?')) {
      this.chatList.forEach(chat => {
        this.cleanMessages(chat.token, chat.adminToken, <MessageModel>{ error: '' });
      });
    }
    return false;
  }

  saveMessage(token: string, adminToken, message: MessageModel, indice?: number) {
    if (indice) {
      this.focusChat(indice);
    }
    if ((!message.message || message.message.trim() === '') && (!message.error || message.error.trim() === ''))
      return;
    message.created_at = new Date();
    message.token = token ? token : this.token;
    message.adminToken = adminToken;
    message.user_id = this.user._id;
    this.chatRequestResource.sendMessage(message).then(aVoid => {
      if (message.error)
        message.error = null;
    });
    setTimeout(() => {
      message.message = '';
      this.scrollMessages();
    }, 200);
  }

  scrollMessages() {
    setTimeout(ar => {
      if (this.componentRef)
        this.componentRef.directiveRef.scrollToBottom();
    }, 100);
  }

  acceptRequest() {
    this.chatRequestResource.acceptRequest(<MessageModel>{ token: this.token }).then(message => {
      this.adminToken = message.adminToken;
      this.clearDomModals();
      this.formalityName = null;
      this.createConversation(this.token, this.citizenName);
      this.saveMessage(this.token, this.adminToken, <MessageModel>{ message: 'Hola, buen día. ¿En que podemos ayudarle?' });
      this.showChat = true;
    }, err => {
      this.toastMsg.error('Error al aceptar la petición');
    });
  }

  createConversation(token, citizenName: string) {
    const address = 'ms.messages.chat.' + token;
    this.eb.registerHandler(address, (error, message) => {
      if (error) {
        return;
      }
      if (!message.body) {
        return;
      }
      const chatTemp = this.chatList.find(chat => chat.token === message.body.token);
      if (!chatTemp || chatTemp.adminToken === message.body.adminToken) {
        // Si viene un mensaje de error se agrega al mensaje principal
        if (message.body.error && message.body.error !== '') {
          message.body.message = message.body.error;
        }
        let chatExists = false;
        this.chatList.forEach(chat => {
          if (chat.token === token) {
            chatExists = true;
            message.body.visto = false;
            chat.messages.push(message.body);
          }
        });
        // Si no existe la ventana de chat con el usuario la crea
        if (!chatExists) {
          const newChat
            = {
            token: message.body.token,
            messages: [message.body],
            adminToken: message.body.adminToken,
            citizenName: citizenName
          };
          this.chatList.push(newChat);
          this.message.push(<MessageModel>{});
          setTimeout(() => {
            this.chatWindows.show();
            // this.chatWindows._el.nativeElement.className = 'collapse chat-height show';
            // this.chatWindows._el.nativeElement.style.setProperty('height', '389px');
            this.chatWindows.isExpanded = true;
          });
        }
        this.scrollMessages();
      }
    });
  }

  cleanMessages(token: string, adminToken: string, message: MessageModel) {
    message.error = 'El funcionario ha dejado la conversación';
    this.saveMessage(token, adminToken, message);
    this.eb.unregister('ms.messages.chat.' + token);
    this.chatList.splice(this.chatList.indexOf(this.chatList.find(chat => chat.token === token)), 1);
  }

  newChatNotification() {
    this.stopInterval();
    const audio = new Audio();
    audio.src = '../../../assets/audio/notification_sound.mp3';
    audio.load();
    const play = audio.play();
    play.then();

    this.notificationInterval = setInterval(() => {
      if (this.titleService.getTitle() === this.originalTitle) {
        this.titleService.setTitle(this.notificationTitle);
      } else {
        this.titleService.setTitle(this.originalTitle);
      }
    }, 1000);
  }

  stopInterval() {
    if (this.notificationInterval) {
      clearInterval(this.notificationInterval);
      this.notificationInterval = null;
      this.titleService.setTitle(this.originalTitle);
      this.test = !this.test;
    }
  }

  setSelectedChat(event) {
    this.chatWindows = event;
  }

  focusChat(indice) {
    this.chatList[indice].messages.forEach(message => {
      message.visto = true;
    });
  }
  /*
    Método que permite eliminar elementos modales adicionales que generan el bug
    que congela la pantalla.
  */
  clearDomModals() {
    this.basicModal.hide();
    let domModals = document.getElementsByClassName('fade in show modal-backdrop');
    if (domModals.length != 0) {
      domModals[0].className = '';
    }
  }

  /* 
    Método que determina en base a los roles del usuario autenticado si le es posible
    responder un chat. El método en cuestion manda a llamar los métodos que permiten
    obtener la lista de los roles del chat y los roles del usuario autenticado.
  */
  async loggedUserCanAnswerChat() {
    localStorage.setItem('loggedUserCanAnswerChat', false + '')
    let p1 = this.getChatRoles();
    let p2 = this.getLoggedUserData();
    let res1 = await p1;
    let res2 = await p2;
    let arr1 = []
    let arr2 = []

    for (let i = 0; i < res1.roles.length; i++) {
      arr1.push(res1.roles[i].nombre);
    }

    for (let i = 0; i < res2.roles.length; i++) {
      arr2.push(res2.roles[i].nombre);
    }

    for (let i = 0; i < arr1.length; i++) {
      if (arr2.includes(arr1[i])) {
        localStorage.setItem('loggedUserCanAnswerChat', true + '');
        return true;
      }
    }
    localStorage.setItem('loggedUserCanAnswerChat', false + '');
    return false;
  }
  /* 
    Método que obtiene los roles del usuario autenticado
  */
  async getLoggedUserData(): Promise<any> {
    return this.loggedUserRoles.obtenerUsuario(this.user._id);
  }
  /* 
    Método que obtiene los roles la configuración del chat
  */
  async getChatRoles(): Promise<any> {
    return this.configuracionChatService.obtenerConfiguracionChat();
  }

}
