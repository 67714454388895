import {Injectable} from "@angular/core";
import {
  IResourceMethod,
  Resource,
  ResourceAction,
  ResourceHandler,
  ResourceParams, ResourceRequestMethod,
} from "@ngx-resource/core";
import {ExpedientesElectModel} from "./expedientes-elect.model";
import {LocationsModel} from "./locations.model";
import {ResourceAuth} from "../../common.module/auth/authenticate.resource";
import {AuthServerProvider} from "../../common.module/auth/auth-jwt.service";

@Injectable()
@ResourceParams({
  pathPrefix: '/core'
  // url: 'localhost:8012'
})

export class LocationsResource extends ResourceAuth {
// export class LocationsResource extends Resource {

  constructor(restHandler: ResourceHandler, provService: AuthServerProvider) {
    super(restHandler, provService);
  }

/*  constructor(restHandler: ResourceHandler) {
    super(restHandler);
  }*/

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/cargar-estados'
  })
  obtenerEstados: IResourceMethod<{}, LocationsModel[]>;

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/cargar-municipios/{!key}'
  })
  obtenerListaMunicipios: IResourceMethod<{key: string}, LocationsModel[]>;

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/cargar-colonias/{!municipio_id}/{!key}'
  })
  obtenerListaColonias: IResourceMethod<{municipio_id: string, key: string}, LocationsModel[]>;

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/cargar-datos-location/{!municipio_id}/{!key}/{!township}'
  })
  obtenerDatosLocation: IResourceMethod<{municipio_id: string, key: string, township: string}, LocationsModel>;
}
