/**
 * Created by rsierra on 25/09/2017.
 */

import {NgModule} from '@angular/core';
//import {AppCommonLibsModule} from '../common.module/common.module';
import { AppCommonLibsModule } from 'src/app/common.module/common.module';
import {PaymentSuccessCreateComponent} from './payment-success-create/payment-success-create.component';
import {PaymentSuccessRoutingModule} from './payment-success.routing';
import {PaymentResource} from './service/payment.resource';
import {PaymentService} from './service/payment.service';

@NgModule({
  imports: [
    PaymentSuccessRoutingModule,
    AppCommonLibsModule
  ],
  declarations: [
    PaymentSuccessCreateComponent
  ],
  providers: [
      PaymentResource,
      PaymentService,
  ]
})
export class PaymentSuccessModule {
}
