/**
 * Created by Raul on 27/05/2018.
 */
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastService} from 'ng-uikit-pro-standard';
import {UsuarioService} from '../main-portal.component/login.component/service/usuario.service';
import {UsuarioModel} from '../main-portal.component/login.component/service/usuario.model';
import {ConfiguracionGeneralService} from '../admin.configuracion-general.module/service/configuracion-general.service';
import {ConfiguracionGeneralModel} from '../admin.configuracion-general.module/service/configuracion-general.model';

@Component({
  selector: 'app-registry-continue',
  templateUrl: 'registry-continue.component.html'
})
export class RegistryComponentComponent implements OnInit {
  public userModel: UsuarioModel = <UsuarioModel>{};
  public formRegister: FormGroup;
  public configuracion: ConfiguracionGeneralModel = {};

  constructor(private fb: FormBuilder,
              private usrService: UsuarioService,
              private toastMsg: ToastService,
              private route: ActivatedRoute,
              private configuracionGeneralService: ConfiguracionGeneralService,
              private router: Router) {
  }

  ngOnInit() {
    this.formRegister = this.fb.group({
      defaultFormName: ['', Validators.required],
      defaultFormFirstName: ['', Validators.required],
      defaultFormLastName: ['', Validators.required],
      defaultFormTelephone: [''],
    });
    this.route.queryParams.subscribe(params => {
      this.loadUserRegister(params['codcon']);
    });
    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion = configuracion;
    });
  }

  loadUserRegister(code: string) {
    this.usrService.obtenerUsuarioPorCodigoConfirmacion(code).then(res => {
      this.userModel = res;
      this.userModel.codigo_confirmacion = code;
    }, () => {
      this.toastMsg.warning('La cuenta ya ha sido validada con anterioridad.');
      this.router.navigate(['/portal']);
    });
  }

  finalRegister() {
    this.userModel.twoFactorAuth = false;
    this.usrService.saveRegister(this.userModel).then(res => {
      this.toastMsg.success('El registro se realizó exitosamente, por favor inicia sesión.');
      this.router.navigate(['/portal']);
    }, err => {
      this.toastMsg.error('No se ha podido finalizar el registro');
    });
  }
}
