<!--Modal: Login / Register Form-->
<div mdbModal #formLogin="mdb-modal" class="modal fade" id="modalLRForm" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" (onShow)="cleanDataModel(); showLogin()"
     [config]="{ignoreBackdropClick: true, backdrop:prueba}" style="overflow-y: auto;">
     <br>
     <div *ngIf="this.iniciarSesion" class="estiloLoginNombre_portal">
      <h5>{{this.nombrePortal}}</h5>
    </div>
    <div *ngIf="this.iniciarSesion" class="estiloCabeceraLogin">
      <img alt="Logo" *ngIf="this.logoCabecera"
      [src]="'data:image/jpg;base64,'+this.logoCabecera">
   </div>
  <div class="modal-dialog cascading-modal" role="document" style="margin-top:50px !important;">
    <!--Content-->
    <div class="modal-content">

      <!--Modal cascading tabs-->
      <div class="modal-c-tabs">

        <!-- Tab panels -->
        <mdb-tabset #staticTabs mti-color-theme [buttonClass]="'md-tabs tabs-2 light-blue darken-3'" [contentClass]="''"
                    class="tab-content">
          <!--Panel 7-->
          <mdb-tab class="tab-pane fade in show active" id="panel7" role="tabpanel"
                   heading="<i class='fa fa-user mr-1'></i> Iniciar sesión">
            <form [formGroup]="loginForm" (submit)="loginForm.valid && loginUser()">
              <!--Body-->
              <div class="modal-body mb-1">
                <div class="md-form form-sm" *ngIf="!esDobleAuth">
                  <i class="fa fa-envelope prefix"></i>
                  <input mdbInput type="text" id="form22" class="form-control"
                         formControlName="defaultFormEmail" [(ngModel)]="login.email">
                  <label for="form22">Correo electrónico</label>
                  <div *ngIf="loginForm.controls['defaultFormEmail'].dirty">
                    <div *ngIf="loginForm.controls['defaultFormEmail'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      El correo electrónico es obligatorio.
                    </div>
                    <div *ngIf="loginForm.controls['defaultFormEmail'].errors?.email"
                         class="text-danger animated fadeInDown fast-4 small">
                      Formato de correo incorrecto.
                    </div>
                  </div>
                </div>

                <div class="md-form form-sm" *ngIf="!esDobleAuth">
                  <i class="fa fa-lock prefix"></i>
                  <input mdbInput type="password" id="form23" class="form-control"
                         formControlName="defaultFormPass" [(ngModel)]="login.password">
                  <label for="form23">Contraseña</label>
                  <div *ngIf="loginForm.controls['defaultFormPass'].dirty">
                    <div *ngIf="loginForm.controls['defaultFormPass'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      La contraseña es obligatoria.
                    </div>
                  </div>
                </div>

                <div class="md-form form-sm" *ngIf="esDobleAuth">
                  <i class="fa fa-lock prefix"></i>
                  <input mdbInput type="text" id="form24" class="form-control" (ngModelChange)="validarCampo()"
                         formControlName="codigo" [(ngModel)]="login.codigo" maxlength="6">
                  <label for="form24">Código de autentificación</label>
                  <div *ngIf="esDobleAuth && !loginForm.controls['codigo'].valid">
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="loginForm.controls['codigo'].errors?.pattern">
                      Formato incorrecto
                    </div>
                    <div *ngIf="loginForm.controls['codigo'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      El código es obligatorio.
                    </div>
                  </div>
                </div>

                <div class="text-center mt-2" *ngIf="!esDobleAuth">
                  <button type="submit" mti-color-theme class="btn btn-theme-primary waves-light" mdbWavesEffect>
                    <i class="fa fa-sign-in ml-1"></i> Iniciar sesión
                  </button>
                </div>
                <div class="text-center mt-2" *ngIf="esDobleAuth">
                  <button type="submit" mti-color-theme class="btn btn-theme-primary waves-light" mdbWavesEffect
                          [disabled]="activaVal">
                    <i class="fa fa-sign-in ml-1"></i> Validar Código
                  </button>
                </div>
              </div>
            </form>
            <!--Footer-->
            <div class="modal-footer display-footer">
              <div *ngIf="!this.registrarNuevoUsuario && !this.iniciarSesion || this.iniciarSesion && this.registrarNuevoUsuario || !this.iniciarSesion && this.registrarNuevoUsuario" class="options text-center text-md-right mt-1">
                <p class="mb-1">¿No te has registrado? <a href="javascript: void(0)" class="blue-text"
                                                          (click)="showRegister()">Registrarte </a>
                </p>
                <p class="mb-1">¿Olvidaste <a href="javascript: void(0)" class="blue-text" (click)="showRecover()">tu
                  contraseña?</a></p>
              </div>
              <button *ngIf="!this.iniciarSesion"  type="button" class="btn btn-outline-info ml-auto" data-dismiss="modal"
                      (click)="formLogin.hide(); cerrarModal()" mdbWavesEffect>Cerrar
              </button>
            </div>

          </mdb-tab>
          <!--/.Panel 7-->

          <!--Panel 7-->
          <mdb-tab class="tab-pane fade" id="panel9" role="tabpanel"
                   heading="<i class='fa fa-user mr-1'></i> Recuperar cuenta">
            <form [formGroup]="recoverForm" (submit)="recoverForm.valid && recoverAccount()">
              <!--Body-->
              <div class="modal-body mb-1">
                <div class="md-form form-sm">
                  <i class="fa fa-envelope prefix"></i>
                  <input mdbInput type="text" id="defaultFormEmailRecover" class="form-control"
                         formControlName="defaultFormEmailRecover" [(ngModel)]="recoverModel.correo">
                  <label for="defaultFormEmailRecover">Correo electrónico de recuperación</label>
                  <div *ngIf="recoverForm.controls['defaultFormEmailRecover'].dirty">
                    <div *ngIf="recoverForm.controls['defaultFormEmailRecover'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      El correo electrónico es obligatorio.
                    </div>
                    <div *ngIf="recoverForm.controls['defaultFormEmailRecover'].errors?.email"
                         class="text-danger animated fadeInDown fast-4 small">
                      Formato de correo incorrecto.
                    </div>
                  </div>
                </div>
                <div class="text-center mt-2">
                  <button type="submit" mti-color-theme class="btn btn-theme-primary waves-light" mdbWavesEffect>
                    <i class="fa fa-send ml-1"></i> Solicitar recuperación
                  </button>
                </div>
              </div>
            </form>
            <!--Footer-->
            <div class="modal-footer display-footer">
              <div class="options text-center text-md-right mt-1">
                <p class="mb-1">
                  Ingrese su dirección de correo electrónico asociada a su cuenta de Portales.
                </p>
                <button *ngIf="!this.iniciarSesion"  type="button" class="btn btn-outline-info ml-auto" data-dismiss="modal"
                        (click)="formLogin.hide()" mdbWavesEffect>Cerrar
                </button>
              </div>
            </div>
          </mdb-tab>
          <!--/.Panel 7-->

          <!--Panel 8-->
          <mdb-tab *ngIf="!this.registrarNuevoUsuario && !this.iniciarSesion || this.iniciarSesion && this.registrarNuevoUsuario || !this.iniciarSesion && this.registrarNuevoUsuario" class="tab-pane fade" id="panel8" role="tabpanel"
                   heading="<i class='fa fa-user-plus mr-1'></i> Registro">
            <form [formGroup]="registerForm" (submit)="registerForm.valid &&
                                                    (newUser.contrasenia === newUser.contraseniaConfirma)  && registerUser()">
              <!--Body-->
              <div class="modal-body">
                <div class="md-form form-sm">
                  <i class="fa fa-envelope prefix"></i>
                  <input mdbInput type="text" id="form24" class="form-control"
                         formControlName="registerFormEmail" [(ngModel)]="newUser.correo">
                  <label for="form24">Correo electrónico</label>
                  <div *ngIf="registerForm.controls['registerFormEmail'].dirty">
                    <div *ngIf="registerForm.controls['registerFormEmail'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      El correo electrónico es obligatorio.
                    </div>
                    <div *ngIf="registerForm.controls['registerFormEmail'].errors?.email"
                         class="text-danger animated fadeInDown fast-4 small">
                      Formato de correo incorrecto.
                    </div>
                  </div>
                </div>

                <div class="md-form form-sm">
                  <i class="fa fa-lock prefix"></i>
                  <input mdbInput type="password" id="form25" class="form-control"
                         formControlName="registerFormPass"
                         [(ngModel)]="newUser.contrasenia">
                  <label for="form25">Contraseña</label>
                  <div *ngIf="registerForm.controls['registerFormPass'].dirty">
                    <div *ngIf="registerForm.controls['registerFormPass'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      La contraseña es obligatoria.
                    </div>
                  </div>
                </div>

                <div class="md-form form-sm">
                  <i class="fa fa-lock prefix"></i>
                  <input mdbInput type="password" id="form26" class="form-control"
                         formControlName="registerFormPassConf"
                         [(ngModel)]="newUser.contraseniaConfirma">
                  <label for="form26">Confirmar contraseña</label>

                  <div
                    *ngIf="registerForm.controls['registerFormPassConf'].dirty || newUser.contrasenia != newUser.contraseniaConfirma">
                    <div *ngIf="registerForm.controls['registerFormPassConf'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      La confirmación de contraseña es obligatoria.
                    </div>
                    <div *ngIf="newUser.contrasenia != newUser.contraseniaConfirma"
                         class="text-danger animated fadeInDown fast-4 small">
                      Las contraseñas no coinciden, favor de validar.
                    </div>
                  </div>
                </div>

                <div class="text-center form-sm mt-2">
                  <button type="submit" mti-color-theme class="btn btn-theme-primary waves-light" mdbWavesEffect>
                    <i class="fa fa-sign-in ml-1"></i> Registrarme
                  </button>
                </div>

              </div>
            </form>
            <!--Footer-->
            <div class="modal-footer">
              <div class="options text-right">
                <p class="pt-1">¿Ya tienes cuenta? <a  class="blue-text" (click)="showLogin()">Inicia sesión</a>
                </p>
              </div>
              <button *ngIf="!this.iniciarSesion" type="button" class="btn btn-outline-info ml-auto" data-dismiss="modal"
              (click)="formLogin.hide()" mdbWavesEffect>Cerrar
          </button>
            </div>
          </mdb-tab>
          <!--/.Panel 8-->
        </mdb-tabset>

      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Modal: Login / Register Form-->
