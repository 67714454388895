import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {PasesCajaListaComponent} from './pases-caja-lista/pases-caja-lista.component';


const routes: Routes = [
  {
    path: '',
    component: PasesCajaListaComponent,
    data: {
      pageTitle: 'Cancelar pases de caja'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CancelarPasesCajaRouting {
}