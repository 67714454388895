import {Injectable} from '@angular/core';
import {LocationsModel} from "./locations.model";
import {LocationsRepCiudResource} from "../../portal.reporte-ciudadano.module/service/locations-rep-ciud.resource";

@Injectable()
export class LocationsRepCiudService {
  constructor(private locationsRepCiudResource: LocationsRepCiudResource) {
  }

  obtenerListaEstados(): Promise<LocationsModel []>{
    return this.locationsRepCiudResource.obtenerEstados();
  }

  obtenerListaMunicipios(key: string): Promise<LocationsModel []>{
    return this.locationsRepCiudResource.obtenerListaMunicipios({key:key});
  }

  obtenerListaColonias(municipio_id: string, key: string): Promise<LocationsModel []>{
    return this.locationsRepCiudResource.obtenerListaColonias({municipio_id: municipio_id, key: key});
  }
}
