import {Component, OnInit, ViewChild} from '@angular/core';
import {AvisosCiudadanoResource} from './service/avisos-ciudadano.resource';
import {AvisosModel} from '../admin.avisos.module/service/avisos.model';
import {AvisosCiudadanoComponent} from './avisos-ciudadano/avisos-ciudadano.component';
import {ConfiguracionGeneralService} from '../admin.configuracion-general.module/service/configuracion-general.service';
import {ToastService} from 'ng-uikit-pro-standard';
import {ConfiguracionGeneralModel} from '../admin.configuracion-general.module/service/configuracion-general.model';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainPortalComponent implements OnInit {
  public aviso: AvisosModel;
  public configuracionModel: ConfiguracionGeneralModel = {};
  @ViewChild('avisoModal') avisoModal: AvisosCiudadanoComponent;

  constructor(
    // private avisosResource: AvisosCiudadanoResource
    private configuracionGeneralService: ConfiguracionGeneralService,
    private toastMsg: ToastService
  ) {
    localStorage.removeItem('theme');
  }

  ngOnInit(): void {
    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracionModel = configuracion;
      const obj = {
        groupElements1: configuracion.groupElements1,
        groupElements2: configuracion.groupElements2,
        groupElements3: configuracion.groupElements3,
        groupElements4: configuracion.groupElements4,
        color1: configuracion.color1,
        color2: configuracion.color2,
        color3: configuracion.color3,
        color4: configuracion.color4,
        fuenteTitulos: configuracion.fuenteTitulos,
        fuenteSubtitulos: configuracion.fuenteSubTitulos,
        fuenteContenidoLP: configuracion.fuenteContenidoLP,
        fuenteContenido: configuracion.fuenteGeneral,
        informacionVisual: configuracion.informacionVisual,
        tramiteVisual: configuracion.tramiteVisual,
      };
      localStorage.setItem('theme', JSON.stringify(obj));
    }, err => {
      this.toastMsg.error('Error al obtener configuración inicial, algunas funciones pueden no estar disponibles.');
    });
  }
}
