import {Injectable} from '@angular/core';
import {
  IResourceMethod,
  Resource,
  ResourceAction,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {UsuarioModel} from '../../main-portal.component/login.component/service/usuario.model';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AreaModel} from '../../admin.areas.module/services/models/area.model';
import {environment} from '../../../environments/environment';

@Injectable()
@ResourceParams({
  //url: 'http://localhost:8001'
  pathPrefix: '/administracion'
  // url: environment.apiBaseUrl + '/administracion'
})
 //export class UsuarioResource extends Resource {
  // constructor(handler: ResourceHandler) {
   // super(handler);
   //}
export class UsuarioResource extends ResourceAuth {
  constructor(restHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(restHandler, authProv);
  }

  @ResourceAction({
    path: '/usuario'
  })
  obtenerUsuarios: IResourceMethod<any, UsuarioModel[]>;

  @ResourceAction({
    path: '/usuario'
  })
  obtenerUsuariosPaginacion: IResourceMethod<any, UsuarioModel[]>;

  @ResourceAction({
    path: '/usuario/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtenerUsuario: IResourceMethod<{ id: any }, UsuarioModel>;

  @ResourceAction({
    path: '/administracion/usuario',
    method: ResourceRequestMethod.Post
  })
  crearUsuario: IResourceMethod<UsuarioModel, any>;

  @ResourceAction({
    path: '/usuario/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  actualizarUsuario: IResourceMethod<UsuarioModel, any>;

  @ResourceAction({
    path: '/area',
    method: ResourceRequestMethod.Get
  })
  obtenerAreas: IResourceMethod<any, AreaModel[]>;

  @ResourceAction({
    path: '/usuarioDA/{!check}',
    method: ResourceRequestMethod.Patch
  })
  actualizarUsuariosDA: IResourceMethod<{ check: any }, any>;

  @ResourceAction({
    path: '/ss/usuario'
  })
  usuarioSesion: IResourceMethod<any, UsuarioModel>;

}
