/**
 * Created by Daniel on 17/07/2018.
 */

export class FormalitiesConfigConstants {
  public static INMOBILIARIAS = 1;
  public static CONSTANCIA_OFICIAL = 2;
  public static AMBIENTAL = 6;
  public static DICTAMEN_US = 7;
  public static VOBO = 8;
  public static FACTIBILIDAD_GIRO = 9;
  public static CONTRATO_RECOLECCION = 10;
  public static FACTIBILIDAD_VIAL = 11;
  public static PREDIAL = 4;
  public static TRASLADO_DOMINIO = 12;
  public static ALBERCA = 3;
  public static CONTRATISTAS = 15;
  public static PROVEEDORES = 14;
  public static ACTUALIZACION_DATOS_FISCALES = 248;
}
