/**
 * Created by Rsierra.
 */

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';

/**
 * Servicio de comunicacion entre componentes, padre e hijjo.
 */
@Injectable()
export class DataShareService {

  private objectAnnouncedSource = new Subject<any>();
  private objectConfirmedSource = new Subject<any>();

  objectAnnounced$ = this.objectAnnouncedSource.asObservable();
  objectConfirmed$ = this.objectConfirmedSource.asObservable();

  announceObject(object: any) {
    this.objectAnnouncedSource.next(object);
  }

  confirmObject(object: any) {
    this.objectConfirmedSource.next(object);
  }
}
