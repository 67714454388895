/**
 * Created by Raul on 27/05/2018.
 */
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';
import {UsuarioModel} from '../main-portal.component/login.component/service/usuario.model';
import {UsuarioService} from '../main-portal.component/login.component/service/usuario.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfiguracionGeneralService} from '../admin.configuracion-general.module/service/configuracion-general.service';
import {ConfiguracionGeneralModel} from '../admin.configuracion-general.module/service/configuracion-general.model';

@Component({
  selector: 'app-recover-account',
  templateUrl: 'recover-account.component.html'
})
export class RecoverAccountComponent implements OnInit {
  public userModel: UsuarioModel = <UsuarioModel>{};
  public formRecover: FormGroup;
  public configuracion: ConfiguracionGeneralModel = {};

  constructor(private fb: FormBuilder,
              private usrService: UsuarioService,
              private toastMsg: ToastService,
              private route: ActivatedRoute,
              private configuracionGeneralService: ConfiguracionGeneralService,
              private router: Router) {
  }

  ngOnInit() {
    this.formRecover = this.fb.group({
      defaultFormPassword: ['', Validators.required],
      defaultFormPasswordConfirm: ['', Validators.required]
    });
    this.route.queryParams.subscribe(params => {
      this.loadUserRegister(params['codrec']);
    });
    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion = configuracion;
    }, err => {
      console.log('Error al traer la configuración en la pantalla de recuperación');
      console.error(err);
    });
  }

  loadUserRegister(code: string) {
    this.usrService.obtenerUsuarioPorCodigoRecuperacion(code).then(res => {
      this.userModel = res;
      this.userModel.codigo_recuperacion = code;
    }, err => {
      this.toastMsg.warning('Datos invalidos, intentelo mas tarde.');
      this.router.navigate(['/portal']);
    });
  }

  finalRegister() {
    if (this.userModel.contrasenia != this.userModel.contraseniaConfirma) {
      this.toastMsg.error('Las contraseñas de confirmación no coinciden.');
      return;
    }
    this.usrService.recuperarCuenta(this.userModel).then(res => {
      this.toastMsg.success('La recuperación de la cuenta fue exitosa, por favor inicia sesión.');
      this.router.navigate(['/portal']);
    }, err => {
      this.toastMsg.error('No se ha podido finalizar la recuperación de contraseña, intente mas tarde.');
    });
  }
}
