/**
 * Created by rsierra on 25/09/2017.
 */

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: 'not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
