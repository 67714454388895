/**
 * Created by MTI on 29/05/2018.
 */
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams} from '@ngx-resource/core';
import {Injectable} from '@angular/core';
import {State} from '../model/location/state.model';
import {County} from '../model/location/county.model';
import {Locality} from '../model/location/locality.model';
import {ResourceAuth} from '../auth/authenticate.resource';
import {AuthServerProvider} from '../auth/auth-jwt.service';

@Injectable()
@ResourceParams({
  pathPrefix: '/coremicro'
  // url: 'http://localhost:8020'
})
export class LocationResource extends ResourceAuth {
  constructor(requestHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(requestHandler, authProv);
  }
/*export class LocationResource extends Resource {
  constructor(requestHandler: ResourceHandler) {
    super(requestHandler);
  }*/

  @ResourceAction({
    path: '/state/{!id}',
    array: true
  })
  getStates: IResourceMethod<{ id: any }, State[]>;
  @ResourceAction({
    path: '/county/{!id}',
    array: true
  })
  getCounties: IResourceMethod<{ id: any }, County[]>;
  @ResourceAction({
    path: '/locality/{!id}',
    array: true
  })
  getLocalities: IResourceMethod<{ id: any }, Locality[]>;
}
