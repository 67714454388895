import {
  IResourceMethod,
  Resource,
  ResourceAction,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {Injectable} from '@angular/core';
import {ResourceAuth} from '../../../auth/authenticate.resource';
import {AuthServerProvider} from '../../../auth/auth-jwt.service';
import {PaymentMethodVmModel} from '../../../model/payment-method-vm.model';
// import { PaymentMethodPortalModel } from '../../../../portal.traslado.module/service/paymentMethodPortal.model';
import { TextConfigurationModel } from '../../../model/text-configuration.model';

@Injectable()
@ResourceParams({
  pathPrefix: '/coremicro'
})
export class PaymentMethodResource extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, provider: AuthServerProvider) {
    super(resourceHandler, provider);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/na/payment_method/{!formalities_config_id}'
  })
  getAllPaymentsByFormalitiesConfigId: IResourceMethod<{ formalities_config_id: number }, PaymentMethodVmModel[]>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/na/payment_method'
  })
  getAllPaymentsByNamePath: IResourceMethod<{ path: string }, PaymentMethodVmModel[]>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/text-config/{!key}'
  })
  getTextConfigByKey: IResourceMethod<{key: string}, TextConfigurationModel>;
}
