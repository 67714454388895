/**
 * Created by Raul on 15/05/2018.
 */

import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {UsuarioService} from './service/usuario.service';
import {UsuarioModel} from './service/usuario.model';
import {LoginModel} from './service/login.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthResource} from '../../main-login.component/service/auth.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {UserAuth} from '../../common.module/auth/user-auth.model';
import {ModalDirective, TabsetComponent, ToastService} from "ng-uikit-pro-standard";
import { ConfiguracionGeneralService } from 'src/app/admin.configuracion-general.module/service/configuracion-general.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login-citizen',
  templateUrl: 'login.component.html'
})
export class LoginCitizenComponent implements OnInit {
  @ViewChild('formLogin')
  public modal: ModalDirective;
  @ViewChild('staticTabs')
  public staticTabs: TabsetComponent;


  @Output() user = new EventEmitter<UserAuth>();

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public recoverForm: FormGroup;
  public esDobleAuth = false;
  public activaVal = true;
  public newUser: UsuarioModel = <UsuarioModel> {};
  public login: LoginModel = <LoginModel> {};
  public recoverModel: UsuarioModel = <UsuarioModel> {};

  public banRegister = false;
  public nombrePortal:string;
  public logoCabecera:string;
  public registrarNuevoUsuario:Boolean = false;
  public prueba:Boolean;
  public iniciarSesion: Boolean = true;

  constructor(private fb: FormBuilder,
              private authResource: AuthResource,
              private usuarioService: UsuarioService,
              private toastMsg: ToastService,
              private authProv: AuthServerProvider,
              private configuracion:ConfiguracionGeneralService,
              private router: Router) {
    this.configuracion.obtenerConfiguracionGeneral()
    .then(configuracion => {
      this.iniciarSesion=configuracion.inicio_sesion;
      this.nombrePortal=configuracion.nombre_portal;
      this.logoCabecera=configuracion.logoCabecera;
      this.registrarNuevoUsuario=configuracion.registrar_nuevo_usuario;
      this.prueba=!this.iniciarSesion;
      console.log(this.iniciarSesion);
    }, err => {
      console.log('error al obtener configuración');
      this.toastMsg.error('Error al obtener configuración del portal.');
    });

  }

  ngOnInit() {
    // Validators loginz
    this.loginForm = this.fb.group({
      defaultFormEmail: ['', Validators.compose([Validators.email, Validators.required])],
      defaultFormPass: ['', [Validators.required]],
      codigo: ['', Validators.pattern('^[0-9]+')]
    });

    // Validators register
    this.registerForm = this.fb.group({
      registerFormEmail: ['', Validators.compose([Validators.email, Validators.required])],
      registerFormPass: ['', [Validators.required]],
      registerFormPassConf: ['', [Validators.required]]
    });

    // Validators recover
    this.recoverForm = this.fb.group({
      defaultFormEmailRecover: ['', Validators.compose([Validators.email, Validators.required])]
    });

  }

  loginUser() {
    console.log("Entrando a loginUser...");
    let codigo;

    if (this.loginForm.controls['codigo'].value != undefined) {
      codigo = this.loginForm.controls['codigo'].value.toString();
    } else {
      codigo ="";
    }

    if (codigo != "") {
      this.loginForm.controls['codigo'].setValue(codigo);
    }

    // this.authResource.login(this.login).then(res => {
    //   if (res.token != undefined) {
    //     this.esDobleAuth = false;
    //     this.modal.hide();
    //     // Reditrect app
    //     // Save token locastorage
    //     this.authProv.logout();
    //     this.authProv.storeAuthenticationToken(res.token, true);
    //     this.user.emit(this.authProv.getUserDecoded());
    //     this.toastMsg.success('Se ha iniciado sesión.');
    //     this.router.navigate(['/portal']);
    //   } else {
    //     // Si no se ha proporcionado el código, se muestra mensaje de advertencia.
    //     if (this.esDobleAuth && (!codigo || codigo == '')) {
    //       this.toastMsg.warning('Se requiere el código de autentificación que se ha enviado por email.');
    //     } else {
    //       this.esDobleAuth = true;
    //       this.toastMsg.success("Código de autentificación enviado a tu correo electrónico.");
    //       // Se agrega validación required junto con la que ya tenía de pattern.
    //       //this.loginForm.controls["codigo"].setValidators([Validators.pattern('^[0-9]+'), Validators.required]);
    //     }
    //   }
    // }, err => {
    //   // Mensaje de error
    //   console.log(err);
    //   if (err && err.body)
    //     this.toastMsg.error(err.body.message);
    // });

    console.log("Haciendo login en corregidora...");
    this.authResource.login(this.login).then(res => {
      if (res.token != undefined) {
        this.esDobleAuth = false;
        this.modal.hide();
        // Reditrect app
        // Save token locastorage
        this.authProv.logout();
        this.authProv.storeAuthenticationToken(res.token, true);
        this.user.emit(this.authProv.getUserDecoded());
        this.toastMsg.success('Se ha obtenido la sesion de corregidora.');
        this.router.navigate(['/portal']);
      } else {
        // Si no se ha proporcionado el código, se muestra mensaje de advertencia.
        if (this.esDobleAuth && (!codigo || codigo == '')) {
          this.toastMsg.warning('Se requiere el código de autentificación que se ha enviado por email.');
        } else {
          this.esDobleAuth = true;
          this.toastMsg.success("Código de autentificación enviado a tu correo electrónico.");
          // Se agrega validación required junto con la que ya tenía de pattern.
          //this.loginForm.controls["codigo"].setValidators([Validators.pattern('^[0-9]+'), Validators.required]);
        }
      }
    }, err => {
      // Mensaje de error
      console.log(err);
      if (err && err.body)
        this.toastMsg.error(err.body.message);
    });


  }

  registerUser() {
    // this.toastMsg.info('Estamos generando tu cuenta, no tardamos.');
    this.usuarioService.registrarUsuario(this.newUser).then(res => {
      console.log(res);
      this.toastMsg.success('Confirma tu identidad en tu correo electrónico, por favor.');
      // Esperar el envio del correo, para continuar con el registro
    }, err => {
      console.log(err);
      if (err && err.body)
        this.toastMsg.error(err.body.message);
    });
  }

  recoverAccount() {
    this.usuarioService.solicitudRecuperarCuenta(this.recoverModel).then(res => {
      this.toastMsg.success('Hemos enviado un correo a su bandeja de entrada.');
    }, err => {
      if (err.status == 400 && err.body)
        this.toastMsg.error(err.body.message);
      else
        this.toastMsg.error('Se ha producido un error inesperado.');
    });
  }

  showRegister() {
    this.staticTabs.setActiveTab(3);
  }

  showRecover() {
    this.staticTabs.setActiveTab(2);
  }

  showLogin() {
    this.staticTabs.setActiveTab(1);
  }

  cleanDataModel() {
    this.newUser = <UsuarioModel> {};
    this.login = <LoginModel> {};
    this.recoverModel = <UsuarioModel>{};
  }

  cerrarModal() {
    this.esDobleAuth = false;
  }

  validarCampo() {
    if (this.loginForm.controls['codigo'].value != undefined) {
      this.activaVal = false;
    }
  }
}
