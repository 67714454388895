import {Injectable} from '@angular/core';
import {UsuarioModel} from './usuario.model';
import {UsuarioEntidadResource} from './usuario.resource';

@Injectable()
export class UsuarioService {
  constructor(private usuarioResource: UsuarioEntidadResource) {
  }
  obtenerUsuarioSesion(): Promise<UsuarioModel> {
    return this.usuarioResource.usuarioSesion();
  }
}
