import {Injectable} from '@angular/core';
import {ConsultaExpElecResource} from './consulta-exp-elec.resource';
import {ExpedientesElectModel} from './expedientes-elect.model';
import {ExpHistoricoClaveModel} from "./expHistoricoClave.model";

@Injectable({
  providedIn: 'root'
})
export class ConsultaExpElecService {

  constructor(private consultaExpElecResource: ConsultaExpElecResource) { }

  obtenerListaExpedientes(parametros: any): Promise<ExpedientesElectModel []>{
    return this.consultaExpElecResource.obtenerListaExpediente(parametros);
  }

  obtenerExpediente(id: string): Promise<ExpedientesElectModel>{
    return this.consultaExpElecResource.obtenerSolExpediente({id:id});
  }
  obtenerINE(curp: string): Promise<ExpHistoricoClaveModel> {
    return this.consultaExpElecResource.obtenerINE({curp: curp});
  }

  guardarExpedienteServ(expedientesElectModel: ExpedientesElectModel): Promise<any>{
    return this.consultaExpElecResource.guardarExpedienteResource({expedientesElectModel:expedientesElectModel});
  }

  actualizaExpedienteServ(expedientesElectModel: ExpedientesElectModel): Promise<any>{
    return this.consultaExpElecResource.actualizaExpedienteResource({expedientesElectModel:expedientesElectModel});
  }

  actualizaVigencia(Vigencia): Promise<any> {
    return this.consultaExpElecResource.actualizaVigencia(Vigencia);
  }

  obtVigencia(id: string): Promise<any> {
    return this.consultaExpElecResource.obtVigenciaArchivo({id: id});
  }
}
