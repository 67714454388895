/**
 * Created by dorozco on 23/06/2019.
 *
 * Servicio que se usa para que los trámites inactivos sean redirigidos a la página 'unavailable'.
 */
import {Injectable} from '@angular/core';
import {FormalitiesConfigResource} from '../resource/formalitiesConfig.resource';
import {Router} from '@angular/router';
@Injectable()
export class FormalitiesConfigService {

  constructor(private resource: FormalitiesConfigResource,
              private router: Router) {}

  getFormalityConfig(id): Promise<any> {
    console.log('Entrando al formalitiesConfigSErvice');
    return this.resource.getFormalityConfigAll({id: id}).then(formalityConfig => {
      const promise = new Promise( (resolve, reject) => {
          resolve(formalityConfig.active);
      });
      if (formalityConfig.active == false)
        this.router.navigateByUrl('/unavailable');
      return promise;
    });
  }

  getFormalityConfigAll(id): Promise<any> {
    return this.resource.getFormalityConfigAll({id: id});
  }
}
