import { PredialModel } from './../model/predial.model';
import { Injectable } from '@angular/core';
import { AdeudosResource } from '../resource/adeudos.resource';
import { ArchivoPaseCaja } from '../model/archivoPaseCaja.model';
import { ParametroModel } from '../model/Parametro.model';
import { ConsultaPredioModel } from '../model/consulta-predio.model';
import { PaseCajaModel } from '../model/paseCaja.model';

@Injectable()
export class AdeudosService {
  constructor(private adeudosResource: AdeudosResource) { }

  obtenerAdeudos(filtros: any): Promise<any> {
    return this.adeudosResource.obtenerAdeudos(filtros);
  }

  obtenerPredio(filtros: any): Promise<any> {
    return this.adeudosResource.obtenerPredio(filtros);
  }

    generarPaseCajaPredial(predial: PredialModel): Promise<PredialModel> {
        return this.adeudosResource.generarPaseCajaPredial(predial);
    }

    descargarPaseCaja(desPaseCaja: any): Promise<ArchivoPaseCaja> {
        return this.adeudosResource.descargarPaseCaja(desPaseCaja);
      }

      obtenerConfiguracionParametros(endPoint: any): Promise<ParametroModel[]> {
        return this.adeudosResource.obtenerConfiguracionParametros(endPoint);
      }

    public consultaPredio(params: any): Promise<ConsultaPredioModel> {
        return this.adeudosResource.consultaPredio(params);
      }

      public pasePredial(params: any): Promise<PaseCajaModel> {
        return this.adeudosResource.pasePredial(params);
      }

      public obtenerTipoConexion(): Promise<any> {
        return this.adeudosResource.obtenerTipoConexion();
      }
}
