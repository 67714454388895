import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';

/**
 * Servicio de comunicacion entre la cabecera y el body. Usado para los inicios de sesión.
 */
@Injectable()
export class DataSessionShareService {

  private objectAnnouncedSource = new Subject<any>();
  private objectConfirmedSource = new Subject<any>();

  objectAnnounced$ = this.objectAnnouncedSource.asObservable();
  objectConfirmed$ = this.objectConfirmedSource.asObservable();

  announceObject(object: any) {
    this.objectAnnouncedSource.next(object);
  }

  confirmObject(object: any) {
    this.objectConfirmedSource.next(object);
  }
}
