import {MtiColorThemeDirective} from './directivas/mti-color-theme.directive';
import {MtiFontSubtitulosDirective} from './directivas/mti-font-subtitulos.directive';
import {MtiFontTitulosDirective} from './directivas/mti-font-titulos.directive';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MtiFontContenidoDirective} from './directivas/mti-font-contenido.directive';
import {MtiFontContenidolpDirective} from './directivas/mti-font-contenidolp.directive';
import {MtiColorHoverItemDirective} from './directivas/mti-color-hover-item.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    MtiFontContenidoDirective,
    MtiFontContenidolpDirective,
    MtiFontTitulosDirective,
    MtiFontSubtitulosDirective,
    MtiColorThemeDirective,
    MtiColorHoverItemDirective
  ],
  exports: [
    MtiFontContenidoDirective,
    MtiFontContenidolpDirective,
    MtiFontTitulosDirective,
    MtiFontSubtitulosDirective,
    MtiColorThemeDirective
  ]
})
export class DirectivasModule {
}
