/**
 * Created by MTI on 09/10/2018.
 */
import {Injectable} from '@angular/core';
import {LocationResource} from '../resource/location.resource';
import {ResolutiveResource} from '../resource/resolutive.resource';
@Injectable()
export class ResolutiveService {
  constructor(private rResource: ResolutiveResource) {}

  getResolutive(id: number, table: string) {
    return this.rResource.getRes({id: id, table: table});
  }
}
