<div mti-font-contenido>
  <!-- Menu -->
  <app-left-slider></app-left-slider>
  <!-- #Menu -->
  <!-- Main Container -->
  <div class="container-fluid main-container-app">
    <router-outlet></router-outlet>
  </div>
  <!-- #Main Container -->
  <app-chat-admin-component></app-chat-admin-component>
</div>