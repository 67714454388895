/**
 * Created by MTI on 15/08/2018.
 */
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { isNullOrUndefined } from 'util';

import { ConfiguracionChatModel } from '../../../admin.configuracion-chat.module/service/configuracion-chat.model';
import { DiaChatModel } from '../../../admin.configuracion-chat.module/service/dia-chat.model';
import { DiaExclusionModel } from '../../../admin.configuracion-chat.module/service/dia-exclusion.model';
import { FormalitiesResource } from '../../../main-portal.component/service/formalities.resource';
import { AuthServerProvider } from '../../auth/auth-jwt.service';
import { UserAuth } from '../../auth/user-auth.model';
import { EventBusService } from '../../util/event-bus.service';
import { ValidatorHelper } from '../../util/validator.helper';
import { ChatRequestModel } from './chat-request.model';
import { MessageModel } from './message.model';
import { ChatConfiguracionResource } from './service/chat-configuracion.resource';
import { ChatRequestResource } from './service/chat-request.resource';


@Component({
  selector: 'app-chat-component',
  templateUrl: 'chat.component.html'
})
export class ChatComponent implements OnInit, OnDestroy {
  public message: MessageModel = {};
  public messagesList: MessageModel[] = [];
  public showChat = false;
  public showConversation = false;
  public connectionState: boolean;
  public chatForm: FormGroup;
  public expanded = false;
  public chatRequest = <ChatRequestModel>{inWorkingTime: true};
  public formalitiesList: Array<any>;

  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild('outOfWorkModal') modal;
  @ViewChild('backdrop') modalBackdrop;
  @ViewChild('ventanaChat') chatWindow;

  private token: string;
  private adminToken: string;
  private user: UserAuth;
  public chatConfiguracionModel: ConfiguracionChatModel = {habilitado: false};
  private hoy: DiaChatModel;
  private diaExclusion: DiaExclusionModel;

  constructor(private toastMsg: ToastService,
              private eb: EventBusService,
              private chatRequestResource: ChatRequestResource,
              private fb: FormBuilder,
              private formalitiesResource: FormalitiesResource,
              private changeDetector: ChangeDetectorRef,
              private chatConfiguracionResource: ChatConfiguracionResource,
              private authProv: AuthServerProvider) {
  }

  ngOnInit() {
    this.adminToken = null;
    this.connectionState = this.eb.connected;
    this.user = this.authProv.getUserDecoded();
    if (!this.user)
      this.user = {id: 0};
    this.chatForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      subject: [''],
      formality: []
    });
    this.formalitiesResource.getFormalities({s: ''}).then(formalitiesConfig => {
      this.formalitiesList = formalitiesConfig.map(formalityConfig => {
        return {value: formalityConfig._id, label: formalityConfig.nombre};
      });
    });
    this.chatConfiguracionResource.obtenerConfiguracionChat().then(chatConfiguracion => {
      this.chatConfiguracionModel = chatConfiguracion;
      const hoy = new Date();
      this.diaExclusion = this.chatConfiguracionModel.listaExclusion.find(dia =>
        +dia.dia.substring(0, 2) == hoy.getDate()
        && +dia.dia.substring(3, 5) == (hoy.getMonth() + 1)
        && +dia.dia.substring(6, 10) == hoy.getFullYear());
      this.disableControls();
      if (!this.diaExclusion) {
        this.hoy = this.chatConfiguracionModel.dias.find(dia => dia.numero === new Date().getDay());
      }
    });
  }

  ngOnDestroy(): void {
    this.cleanMessages();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    this.cleanMessages();
    return false;
  }

  saveMessage(adminToken: string) {
    this.focusChat();
    if ((!this.message.message || this.message.message.trim() === '') && (!this.message.error || this.message.error.trim() === ''))
      return;
    this.message.created_at = new Date();
    this.message.token = this.token;
    this.message.adminToken = adminToken;
    this.message.user_id = (this.user) ? this.user._id : '0';
    this.chatRequestResource.sendMessage(this.message).then(aVoid => {
      if (this.message.error)
        this.message.error = null;
    });

    setTimeout(() => {
      this.message = {};
      this.scrollMessages();
    }, 100);
  }

  getConversation() {
    if (this.chatForm.valid == true) {
      this.validateWorkingTime();
      // Revisar si seleccionó un valor
      if (this.chatRequest.formality) {
        const formality = this.formalitiesList.find(option => option.value == this.chatRequest.formality);
        if (formality != undefined)
          this.chatRequest.formalityName = formality.label;
      }
      this.chatRequestResource.getChatRequest(this.chatRequest).then(res => {
        if (this.chatRequest.inWorkingTime == true) {
          this.toastMsg.info('Espere un momento por favor, estamos solicitando que un funcionario le atienda. Gracias.');
          this.token = res.token;
          this.createConversation(this.token);
        } else {
          this.toastMsg.info('Se ha enviado un mensaje al funcionario encargado, favor de esperar su respuesta');
          this.chatForm.reset();
          this.cleanMessages();
        }
      });
    } else {
      ValidatorHelper.validateAllFormFields(this.chatForm);
    }
  }

  openChat() {
    this.showChat = true;
    this.validateWorkingTime();
    this.changeDetector.detectChanges();
    setTimeout(() => {
      this.expanded = true;
      // this.chatWindow._el.nativeElement.className = 'collapse chat-height show';
      this.chatWindow.show();
      this.chatWindow.isExpanded = true;
    });
  }

  scrollMessages() {
    setTimeout(ar => {
      if (this.componentRef)
        this.componentRef.directiveRef.scrollToBottom();
    }, 100);
  }

  createConversation(token) {
    const address = 'ms.messages.chat.' + token;
    this.eb.registerHandler(address, (error, message) => {
      if (error) {
        return;
      }
      if (!message.body) {
        return;
      }
      if (this.messagesList.length === 0 && message.body && this.showChat == true) {
        this.showConversation = true;
      }
      // Si viene un mensaje de error se agrega al mensaje principal
      if (message.body.error && message.body.error !== '') {
        message.body.message = message.body.error;
      }
      if (!this.adminToken) {
        this.adminToken = message.body.adminToken;
      }
      if (message.body.adminToken === this.adminToken) {
        message.body.visto = false;
        this.messagesList.push(message.body);
        this.scrollMessages();
      } else {
        // Si se recibe un mensaje de aceptación de otro funcionario, se le manda la notificación de que ya no es necesario
        // Pero si el mensaje es de error, se ignora para que no se cicle el mensaje
        if (!message.body.error || message.body.error.trim() === '') {
          this.message.error = 'Otro funcionario ya aceptó la conversación';
          this.saveMessage(message.body.adminToken);
        }
      }
    });
  }

  cleanMessages() {
    if (this.showChat) {
      if (!isNullOrUndefined(this.adminToken)) {
        this.message.error = 'El ciudadano ha dejado la conversación';
        this.saveMessage(this.adminToken);
        this.adminToken = null;
      }
      this.showChat = false;
      this.showConversation = false;
      this.eb.unregister('ms.messages.chat.' + this.token);
      this.messagesList = [];
      this.chatRequest = <ChatRequestModel>{inWorkingTime: true};
    }
  }

  // Método para cambiar el valor de la variable expanded, que controla la altura predeterminada del componente de chat
  public change(expand: boolean) {
    if (expand == true) {
      setTimeout(() => {
        this.expanded = expand;
      });
    } else {
      this.expanded = expand;
    }
  }

  /**
   * Método para cambiar el valor de 'inWorkingTime' dependiendo de si la hora y fecha entra en horario laboral o no.
   * También se cambian las clases CSS del modal del chat para que aparezca cuando no sea horario laboral
   */
  private validateWorkingTime() {
    const dateTime = new Date();
    let inWorkingTime = false;
    let horaInicio = 0;
    let minutosInicio = 0;
    let horaFin = 0;
    let minutosFin = 0;
    if (this.diaExclusion) {
      inWorkingTime = false;
    } else if (isNullOrUndefined(this.hoy) || isNullOrUndefined(this.hoy.habilitado) || isNullOrUndefined(this.hoy.horaInicio) || isNullOrUndefined(this.hoy.horaFin)
      || this.hoy.horaInicio == this.hoy.horaFin) {
      inWorkingTime = false;
    } else if (!this.hoy.habilitado) {
      inWorkingTime = false;
    } else {
      const esInicioPM: boolean = this.hoy.horaInicio.substring(this.hoy.horaInicio.length - 2, this.hoy.horaInicio.length) == 'PM';
      horaInicio = +this.hoy.horaInicio.substring(0, 2);
      if (esInicioPM){
        if (horaInicio != 12)
          horaInicio += 12;
      }
      minutosInicio = +this.hoy.horaInicio.substring(this.hoy.horaInicio.indexOf(':') + 1, this.hoy.horaInicio.indexOf(':') + 3);
      const esFinPM: boolean = this.hoy.horaFin.substring(this.hoy.horaFin.length - 2, this.hoy.horaFin.length) == 'PM';
      horaFin = +this.hoy.horaFin.substring(0, 2);
      if (esFinPM){
        if (horaFin != 12)
          horaFin += 12;
      }
      minutosFin = +this.hoy.horaFin.substring(this.hoy.horaFin.indexOf(':') + 1, this.hoy.horaFin.indexOf(':') + 3);
      inWorkingTime = ((dateTime.getHours() > horaInicio && dateTime.getHours() < horaFin)
        || (dateTime.getHours() == horaInicio && dateTime.getMinutes() >= minutosInicio)
        || (dateTime.getHours() == horaFin && dateTime.getMinutes() < minutosFin));
    }

    // Si la variable 'inWorkingTime' tiene el mismo valor, no hay necesidad de hacer los siguientes pasos
    if (this.chatRequest.inWorkingTime == inWorkingTime) {
      return;
    }

    this.chatRequest.inWorkingTime = inWorkingTime;
    if (this.chatRequest.inWorkingTime == false) {
      this.chatForm.controls['subject'].setValidators(Validators.required);
      this.chatForm.controls['subject'].reset();
      setTimeout(() => {
        if (this.modal) {
          this.modal.nativeElement.className = 'modal fade show d-flex flex-column';
          this.modal.nativeElement.style.setProperty('display', 'block');
        }
        if (this.modalBackdrop)
          this.modalBackdrop.nativeElement.className = 'modal-backdrop-chat fade in show';
      }, 100);
    } else {
      this.chatForm.controls['subject'].clearValidators();
      this.chatForm.controls['subject'].reset();
    }
  }

  /**
   * Método para quitar las clases del modal de chat para que desaparezca
   */
  public closeChatModal() {
    this.modal.nativeElement.className = 'modal fade';
    this.modal.nativeElement.style.setProperty('display', 'none');
    this.modalBackdrop.nativeElement.className = '';
  }

  /**
   * Método para obtener el nombre de un día de la lista de configuración basado en el número proporcionado
   * @param numero Número del día a obtener
   * @return Nombre del día a obtener
   */
  public obtenerHorario(numero: number): string {
    const dia = this.chatConfiguracionModel.dias.find(diaLista => diaLista.numero == numero);
    if (dia && dia.habilitado == true && dia.horaInicio && dia.horaFin) {
      return dia.horaInicio + ' - ' + dia.horaFin;
    } else {
      return 'Día no hábil';
    }
  }

  focusChat() {
    this.messagesList.forEach(message => {
      message.visto = true;
    });
  }

  disableControls() {
    if(this.diaExclusion) this.chatForm.disable();
  }

  ifDayIsEnabled(): boolean {
    this.hoy = this.chatConfiguracionModel.dias.find(dia => dia.numero === new Date().getDay());
    return this.hoy.habilitado;
  }

  ifCurrentTimeIsInRange(): boolean {
    this.hoy = this.chatConfiguracionModel.dias.find(dia => dia.numero === new Date().getDay());
    const currentDay = new Date();

    let horaInicioHour = (Number(this.hoy.horaInicio[0]+this.hoy.horaInicio[1]));
    let horaInicioMinutes = (Number(this.hoy.horaInicio[3]+this.hoy.horaInicio[4]));
    if(this.hoy.horaInicio[5]+this.hoy.horaInicio[6]=='PM'){
      if (horaInicioHour != 12)
        horaInicioHour+=12;
    } 
    else if(horaInicioHour == 12) horaInicioHour = 0;
    let horaFinHour = (Number(this.hoy.horaFin[0]+this.hoy.horaFin[1]));
    let horaFinMinutes = (Number(this.hoy.horaFin[3]+this.hoy.horaFin[4]));
    if(this.hoy.horaFin[5]+this.hoy.horaFin[6]=='PM'){
      if (horaFinHour != 12)
        horaFinHour+=12;
    }
    else if(horaFinHour == 12) horaFinHour = 0;
    const horaInicio = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),
    horaInicioHour,horaInicioMinutes);

    const horaFin = new Date(currentDay.getFullYear(),currentDay.getMonth(),currentDay.getDate(),
    horaFinHour,horaFinMinutes);
    return currentDay >= horaInicio && currentDay <= horaFin;
  }

  ifThereIsWorkToday(): boolean{
    for (let i = 0; i < this.chatConfiguracionModel.dias.length; i++) {
      if(!this.chatConfiguracionModel.dias[i].habilitado) return false;
    }
    return true;
  }
}
