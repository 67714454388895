import {
  IResourceMethod,
  Resource,
  ResourceAction,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod
} from "@ngx-resource/core";
import {Injectable} from "@angular/core";
import {ResourceAuth} from "../../common.module/auth/authenticate.resource";
import {AuthServerProvider} from "../../common.module/auth/auth-jwt.service";
import {PagosModel} from "../../common.tramite-vu.module/entidad.pago.component/service/models/pase-caja-response/pagos.model";
import {ConsultaPagoModel} from "./consulta-pago.model";

@Injectable()
@ResourceParams({
  pathPrefix: '/pagos'
})

export class PdfXmlResource extends ResourceAuth {
  constructor(res: ResourceHandler, auth: AuthServerProvider) {
    super(res, auth);
  }

  @ResourceAction({
    path: '/na/factura',
    method: ResourceRequestMethod.Get
  })
  getFactura: IResourceMethod<{ folioPaseCaja: any }, any>;

  @ResourceAction({
    path: '/na/factura-pdf-configurable',
    method: ResourceRequestMethod.Get
  })
  descargarPdfFacturaConfigurable: IResourceMethod<{ serie: string, folio: string, importe: string }, any>;

  @ResourceAction({
    path: '/na/factura-xml',
    method: ResourceRequestMethod.Get
  })
  getFacturaXml: IResourceMethod<{ folio: any, serie: any }, any>;

  @ResourceAction({
    path: '/na/factura-xml-configurable',
    method: ResourceRequestMethod.Get
  })
  descargarXmlFacturaConfigurable: IResourceMethod<{ serie: string, folio: string, importe: string }, any>;

  @ResourceAction({
    path: '/na/comprobante',
    method: ResourceRequestMethod.Get
  })
  getComprobante: IResourceMethod<{ folioPaseCaja: any }, any>;

  @ResourceAction({
    path: '/na/comprobante-configurable',
    method: ResourceRequestMethod.Get
  })
  descargarComprobanteConfigurable: IResourceMethod<{ serie: string, folio: string }, any>;
}
