<!--Modal: Login / Register Form-->
<div mdbModal #notificationsModal="mdb-modal" class="modal fade modal-overflow" id="modalLRForm" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" (onShow)="loadNotifications()">
  <div class="modal-dialog cascading-modal" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div mti-color-theme class="modal-header light-blue darken-3 white-text">
        <h4 class="title"><i class="fa fa-list-alt"></i> Notificaciones.</h4>
        <button type="button" class="close waves-effect waves-light" data-dismiss="modal" aria-label="Close"
                (click)="notificationsModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <!--Body-->
      <div class="modal-body mb-0">
        <perfect-scrollbar style="max-height: 450px">
          <ul class="list-group">
            <li class="list-group-item text-muted px-2 py-1 cursor-pointer" *ngFor="let notification of notifications"
                (click)="seenNotification(notification)"
                (blur)="seenNotification(notification)">
              <small class="float-right"><strong>{{notification?.fecha_creacion?.$date | date: 'medium' }}</strong>
              </small>
              <div class="clearfix"></div>
              <div class="d-flex align-items-center">
                <small>
                  {{notification.detalle + " "}}
                  <a *ngIf="notification.app_path != null && notification.app_path != undefined"
                     href="{{notification.app_path}}"
                     class="position-relative">
                    Ir a trámite
                  </a>
                </small>
                <div class="ml-1" *ngIf="notification.fecha_visto==null" style="width: 10px;min-width: 10px;max-width: 10px;height: 10px;min-height: 10px;max-height: 10px;background-color: rgb(0, 81, 231);border-radius: 50%;"></div>
              </div>
            </li>
            <li class="list-group-item text-muted text-center" *ngIf="notifications?.length == 0">
              <small>Sin notificaciones por el momento.</small>
            </li>
          </ul>
        </perfect-scrollbar>
      </div>
      <!--/.Content-->
    </div>
  </div>
  <!--Modal: Login / Register Form-->
</div>
