import {FormControl, FormGroup} from '@angular/forms';

export class ValidatorHelper {
  public static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.controls[field];
      if(control instanceof FormGroup)
        this.validateAllFormFields(control);
      if(control instanceof FormControl)
        control.markAsTouched({onlySelf: true});
    });
    return true;
  }
}
