import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {AvisosModel} from '../../admin.avisos.module/service/avisos.model';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';

@Injectable()
@ResourceParams({
  pathPrefix: '/configuracion'
  // url: 'http://localhost:8003'
})
export class AvisosCiudadanoResource extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resourceHandler, authProv);
  }

// export class AvisosCiudadanoResource extends Resource {
//   constructor(resourceHandler: ResourceHandler) {
//     super(resourceHandler);
//   }

  @ResourceAction({
    path: '/na/aviso',
    method: ResourceRequestMethod.Get
  })
  obtenerAviso: IResourceMethod<any, AvisosModel>;
}
