import {Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormalitiesResource} from './service/formalities.resource';
import {FormalityConfigModel} from './service/formality-config.model';
import {NavigationEnd, Router} from '@angular/router';
import {AuthServerProvider} from '../common.module/auth/auth-jwt.service';
import {UserAuth} from '../common.module/auth/user-auth.model';
import {NotificationsResource} from './service/notifications.resource';
import {NotificationModel} from './service/notification.model';
import {EventBusService} from '../common.module/util/event-bus.service';
import {ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {ConfiguracionGeneralService} from '../admin.configuracion-general.module/service/configuracion-general.service';
import {ConfiguracionGeneralModel} from '../admin.configuracion-general.module/service/configuracion-general.model';
import {DataSessionShareService} from '../common.module/util/data-share-session.service';
import {AuthExtendedResource} from '../main-login.component/service/auth-extended.resource';
import {Subject} from 'rxjs/Subject';
import { UsuarioService } from 'src/app/admin.admin.module/service/usuario.service';
import { ActualizaComponenteService } from './service/actualiza-componente.service';
import {VentanillaUnicaService} from '../common.module/service/ventanilla-unica.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @ViewChild('infoModal')
  public infoModal: ModalDirective;
  public formalities: FormalityConfigModel[] = [];
  public showBusqueda = false;
  public showSearchBar = false;
  public searchStr: { s: any } = {
    s: ''
  };
  public configuracion: ConfiguracionGeneralModel = {};

  public notifications: NotificationModel[] = [];
  public showNotifications = false;
  public notificationsCount: any = 0;

  public user: UserAuth;
  public userActivity;
  public userInactive: Subject<any> = new Subject();

  private porPaginaR = 3;
  private porPaginaT = 4;
  public menu: any = [];

  @ViewChild('carouselMegaMenu') carousel: any;

  public verBusqueda = true;
  public verMenuMovil = false;
  public verContratistas = false;
  public verProveedores = false;
  public verTraslado = false;
  public tramitesMM: any = [];
  public tipoCategoriaMM: any = [];
  public resultadoBusqueda: any = [];
  public mensajeNoBusqueda = 'No se han encontrado coincidencias en la búsqueda.';
  public sinTramites = 'Cargando trámites';
  private tramitesMenu: any = [];
  public routeLanding = true;
  public mostrarPagoPredial:Boolean;
  public mostrarPagoAgua:Boolean;
  public usuarioPortal:string;
  public consultarComprobantes:Boolean;
  public inicioSesion:Boolean;
  @ViewChild('myLink') myLink: ElementRef;
  public ocultarNavegacion: Boolean = true;

  public canShowInmobiliaria = false


  constructor(private formalitiesResource: FormalitiesResource,
              private toastMsg: ToastService,
              private authServerProvider: AuthServerProvider,
              private router: Router,
              private notificationsResource: NotificationsResource,
              private configuracionGeneralService: ConfiguracionGeneralService,
              protected dataShare: DataSessionShareService,
              private authExtendedResource: AuthExtendedResource,
              private eb: EventBusService,
              private renderer: Renderer2,
              private el: ElementRef,
              private usuarioService:UsuarioService, private actualizarComponenteService:ActualizaComponenteService,
              private ventanillaUnicaService: VentanillaUnicaService
              ) {
    this.mostrarLoginUsuarioNoLogueado();
    this.dataShare.objectConfirmed$.subscribe(usr => {
      console.log('Algo llegó al body del header :P');
      this.verContratistas = false;
      this.verProveedores = false;
      this.verTraslado = false;

      if (usr && usr.discriminator == 'sessionUser') {
        this.setUserSession(usr);
        console.log('Se inicio sesíon desde el header');
        this.actualizarComponenteService.reloadComponent('footer');

        if(usr && usr.correo)
          this.canShowInmobiliaria = true
        else
          this.canShowInmobiliaria = false
        /*
        if (usr != null) {
          this.ventanillaUnicaService.obtenerUsuarioOrganizacion(usr._id).then(usuario => {
            usuario.organizacion.forEach(org => {
              if (org.tipo_organizacion.toUpperCase() == "CONTRATISTAS") {
                this.verContratistas = true;
              } else if (org.tipo_organizacion.toUpperCase() == "PROVEEDORES") {
                this.verProveedores = true;
              } else 
              if (org.tipo_organizacion.toUpperCase() == "NOTARÍA") {
                this.verTraslado = true;
              }
            });
          });
        }
        */
      }
    });
    /* Función para identificar cuando se cambie la ruta para
       validar si se muestra o no la búsqueda en el header */
    this.router.events.subscribe((val) => {
      this.routeLanding = true;
      if (this.router.url !== '/portal') {
        this.routeLanding = false;
      }
    });

  }

  ngOnInit(): void {
    this.user = this.authServerProvider.getUserDecoded();
    /*
    if (this.user) {
      this.ventanillaUnicaService.obtenerUsuarioOrganizacion(this.user._id).then(usuario => {
        usuario.organizacion.forEach(org => {
          if (org.tipo_organizacion.toUpperCase() == "CONTRATISTAS") {
            this.verContratistas = true;
          } else if (org.tipo_organizacion.toUpperCase() == "PROVEEDORES") {
            this.verProveedores = true;
          } else
          if (org.tipo_organizacion.toUpperCase() == "NOTARÍA") {
            this.verTraslado = true;
          }
        });
      });
    }
    */

    this.loadNotifications();
    this.loadConnectionNotifications();
    this.userSession();
    this.loadCategorias();
    this.mostrarUsuarioPortal();

     this.configuracionGeneralService.obtenerConfiguracionGeneral()
      .then(configuracion => {
        console.log(configuracion.tiempo_sesion);
        this.configuracion = configuracion;
        this.menu = configuracion.menuGrupo;
        this.mostrarPagoPredial=configuracion.predial;
        this.mostrarPagoAgua=configuracion.agua;
        this.consultarComprobantes=configuracion.consultar_comprobantes;
        this.inicioSesion=configuracion.inicio_sesion;
        this.formalitiesResource.obtenerCategorias().then(categorias => {
          const long = categorias.length;
          let i = 0;
          categorias.forEach(categoria => {
            if (categoria.tipo == 'Trámites y Servicios')
              categoria.tipo = 'TS';
            categoria.tramites.forEach(tramite => {
              tramite['tipoP'] = tramite.tipo;
              if (tramite.tipo == 'TS' || tramite.tipo == 'ST') {
                tramite.tipo = 't';
              } else {
                tramite.tipo = 'r';
              }
            });
            this.menu.forEach(grupo => {
              grupo.tiposCategoria.forEach(secciones => {
                secciones.tipos.forEach(tipo => {
                  if (tipo === categoria.tipo) {
                    secciones.categorias.push(categoria);
                  }
                });
              });
            });
            i++;
          });
          const watcher = setInterval(() => {
            if (i == long) {
              clearInterval(watcher);
              this.getScreenSize();
            }
          }, 500);
        }, err => {
          this.toastMsg.error('Error al obtener configuración del menú');
        });
      }, err => {
        console.log('error al obtener configuración');
        this.toastMsg.error('Error al obtener configuración del portal.');
      });

  }

  mostrarMenuMovil() {
    this.verMenuMovil = !this.verMenuMovil;
  }

  getMenuWithCheckScreen() {
    this.verBusqueda = true;
    if (window.innerWidth <= 767) {
      this.porPaginaR = 2;
      this.porPaginaT = 2;
      this.verBusqueda = false;
    }
    if (window.innerWidth <= 575) {
      this.porPaginaR = 1;
      this.porPaginaT = 1;
    }
    this.getMenu();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.verBusqueda = true;
    if (window.innerWidth <= 767) {
      this.porPaginaR = 2;
      this.porPaginaT = 2;
      this.verBusqueda = false;
    } else if (window.innerWidth <= 575) {
      this.porPaginaR = 1;
      this.porPaginaT = 1;
    } else {
      this.porPaginaR = 3;
      this.porPaginaT = 4;
    }
    this.getMenu();
  }

  remStyle(e) {
    const hijo = e.target.nextElementSibling;
    hijo.removeAttribute('style');
  }

  verMenu(e, index) {
    this.ocultarMenu();
    const hijo = e.target.nextElementSibling;
    hijo.setAttribute('style', 'display:block;');
    let i = 0;
    this.menu.forEach(itm => {
      itm.active = false;
      if (i == index)
        itm.active = true;
      i++;
    });
  }

  checkDropdownMM(e) {
    e.stopPropagation();
  }

  ocultarMenu() {
    const dropdownMenu = Array.from(this.el.nativeElement.querySelectorAll('.menu-mega-head'));
    dropdownMenu.forEach(dropdown => {
      this.renderer.setStyle(dropdown, 'display', 'none');
    });
    this.menu.forEach(itm => {
      itm.active = false;
    });
  }

  ocutarMenuReporte() {
    setTimeout(() => {
      this.ocultarMenu();
    }, 2000);
  }

  getMenu() {
    for (let i = 0; i < this.menu.length; i++) {
      this.menu[i].active = false;
      for (let a = 0; a < this.menu[i].tiposCategoria.length; a++) {
        for (let e = 0; e < this.menu[i].tiposCategoria[a].categorias.length; e++) {
          if (a == 0 && e == 0) {
            this.tipoCategoriaMM[i] = this.menu[i].tiposCategoria[a].categorias[e].tipo;
          }
          this.tramitesMenu.push(this.menu[i].tiposCategoria[a].categorias[e]);
          this.menu[i].tiposCategoria[a].categorias[e].active = (a == 0 && e == 0) ? true : false;
          for (let t = 0; t < this.menu[i].tiposCategoria[a].categorias[e].tramites.length; t++) {
            this.menu[i].tiposCategoria[a].categorias[e].tramites[t]['active'] = false;
          }
        }
      }
      const iTiposCatgeoria = this.checkMenu(i);
      if (iTiposCatgeoria > -1) {
        const cat = this.menu[i].tiposCategoria[iTiposCatgeoria].categorias[0];
        this.verTramites(i, cat.nombre, cat._id, true);
      } else {
        this.sinTramites = 'Sin trámites configurados';
      }
    }
  }

  checkMenu(index) {
    for (let i = 0; i < this.menu[index].tiposCategoria.length; i++) {
      if (this.menu[index].tiposCategoria[i].categorias.length > 0) {
        return i;
      }
    }
    return -1;
  }

  verTramites(index, nombre, id, primero): void {
    this.tramitesMM[index] = [];
    this.sinTramites = 'No hay trámites configurados';
    const iteracion1 = this.menu[index].tiposCategoria.length;
    for (let a = 0; a < iteracion1; a++) {
      const iteracion2 = this.menu[index].tiposCategoria[a].categorias.length;
      for (let e = 0; e < iteracion2; e++) {
        this.menu[index].tiposCategoria[a].categorias[e].active = true;
        if (id != this.menu[index].tiposCategoria[a].categorias[e]._id) {
          this.menu[index].tiposCategoria[a].categorias[e].active = false;
        }
      }
    }
    let cate = [];
    const results = this.tramitesMenu.filter(function (categoria) {
      return categoria._id === id;
    });
    const obj = (results.length > 0) ? results[0] : 0;
    if (obj != 0) {
      cate = results[0];
      let res = [];
      res = results[0].tramites;
      if (res.length < 1)
        this.sinTramites = 'No hay trámites configurados';
      if (cate['tipo'] == 'TS')
        this.tipoCategoriaMM[index] = 't';
      else
        this.tipoCategoriaMM[index] = 'r';

      const porPagina = (cate['tipo'] == 'DC' || cate['tipo'] == 'DCS') ? this.porPaginaR : this.porPaginaT;
      // operación para calcular los sliders

      const operacion1 = (res.length / porPagina);
      const total = res.length;
      this.tramitesMM[index] = [];
      this.tramitesMM[index]['sliders'] = [];
      let inicio;
      let fin;
      for (let i = 0; i < operacion1; i++) {
        if (total < porPagina) {
          const trams = {
            tramites: res
          };
          this.tramitesMM[index]['sliders'].push(trams);
        } else {
          if (i <= 0) {
            inicio = i;
            fin = porPagina;
          } else {
            inicio = (porPagina * i);
            fin = inicio + porPagina;
          }

          const bloque = res.slice(inicio, fin);
          const trams1 = {
            tramites: bloque
          };
          this.tramitesMM[index]['sliders'].push(trams1);
        }
      }
    } else {
      this.sinTramites = 'No hay trámites configurados';
    }
  }

  userSession() {
    if (this.user != null || this.user != undefined) {
      this.activityTeamout();
    }
  }

  activityTeamout() {

    this.setTimeout();
    this.userInactive.subscribe(() => this.logout());
  }

  setTimeout() {

    if(this.configuracion.cierre_sesion){
      this.userActivity = setTimeout(() => this.userInactive.next(undefined), this.configuracion.tiempo_sesion);
    }

  }

  @HostListener('window:mousemove') refreshUserState() {
    if (this.user != null || this.user != undefined) {
      clearTimeout(this.userActivity);
      this.setTimeout();
    }
  }

  loadConnectionNotifications() {
    if (this.user)
      this.eb.registerHandler('ms.notifications.user.' + this.user._id, (error, message) => {
        if (error) {
          console.error('AppEventBusService.handleAction error', error);
          return;
        }
        if (!message.body) {
          console.error('AppEventBusService.handleAction - body is required in message', message);
          return;
        }
        this.toastMsg.info(message.body.titulo);
        this.notifications.unshift(message.body);
        this.notificationsCount++;
      });
    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion = configuracion;
    }, err => {
      console.log('Error al cargar la configuración en la cabecera');
      console.error(err);
    });
  }

  loadFormalities() {
    this.resultadoBusqueda = [];
    this.mensajeNoBusqueda = 'Buscando...';
    if (this.searchStr.s != '') {
      this.formalitiesResource.getFormalities(this.searchStr).then(res => {
        if (res.length < 1)
          this.mensajeNoBusqueda = 'No se han encontrado coincidencias en la búsqueda.';
        this.resultadoBusqueda = res;
      }, err => {
        if (err && err.body)
          this.toastMsg.error(err.body.message);
        else
          this.toastMsg.error('Error al obtener resultados de búsqueda');
      });
    } else {
      this.loadCategorias();
    }
  }

  hideList() {
    setTimeout(res => {
      this.showBusqueda = false;
    }, 500);
  }

  showList() {
    setTimeout(res => {
      this.showBusqueda = true;
    }, 500);
  }

  loadCategorias() {
    this.resultadoBusqueda = [];
    this.mensajeNoBusqueda = 'Buscando...';
    if (this.searchStr.s.length < 1) {
      this.formalitiesResource
        .obtenerCategorias()
        .then(categoriaS => {
            if (categoriaS.length > 1)
              this.mensajeNoBusqueda = 'No se han encontrado coincidencias en la búsqueda.';
            this.resultadoBusqueda = categoriaS;
          },
          err => {
            if (err && err.body) {
              this.toastMsg.error(err.body.message);
            } else {
              this.toastMsg.error('Error al obtener resultados de búsqueda');
            }
          });
    } else {
      this.loadFormalities();
    }
  }

  detalleTramite(tramite) {
    console.log(tramite);
    console.log(tramite.enLinea);
    let tipo;
    if (tramite.hasOwnProperty('tipoP')) {
      tipo = tramite.tipoP;
    } else {
      tipo = tramite.tipo;
    }
    let id;
    if (tramite.idTramite)
      id = tramite.idTramite;
    else
    id = tramite._id;

    switch (tipo) {
      case 'RC':
        if (tramite.enLinea) {
          this.router.navigate(['portal/reporte-ciudadano/' + id]);
        } else {
          this.toastMsg.warning('Por el momento no es posible realizar el reporte en línea, contacte a un funcionario.');
        }

        break;
      case 'RCS':
        if (tramite.enLinea) {
          this.router.navigate(['portal/bpm_tramites/rcs/' + id]);
        } else {
          this.toastMsg.warning('Por el momento no es posible realizar el reporte en línea, contacte a un funcionario.');
        }

        break;
      case 'RI':
        this.router.navigate(['portal/bpm_tramites/ti/' + id]);
        break;
      case 'ST':
      case 'TS':
        this.router.navigate(['portal/detail/'], {queryParams: {tramite: id}});
        break;
    }
    setTimeout(res => {
      this.ocultarMenu();
      this.verMenuMovil = false;
    }, 500);

  }

  detalleCategoria(categoriaId: string) {
    this.router.navigate(['portal/detail/'], {queryParams: {categoria: categoriaId}});
    this.ocultarMenu();
    this.verMenuMovil = false;
  }

  logout() {
    this.authExtendedResource.logout().then(aVoid => {
      console.log('Cerrado de sesión guardado en bitácora');
    }, err => console.error(err));
    this.authServerProvider.logout();
    this.toastMsg.info('Hemos cerrado la sesión.');
    this.mostrarLoginUsuarioNoLogueado();
    this.user = {discriminator: 'closeSession'};
    this.dataShare.confirmObject(this.user);
    delete this.user;
    this.mostrarUsuarioPortal();
    this.actualizarComponenteService.reloadComponent('footerActive');
  }

  setUserSession(usr: UserAuth) {
    if (this.user == null) {
      this.user = usr;
      console.log("AQUI VIENE EL USUARIO: ", this.user);
      this.user.discriminator = 'sessionUser';
      this.dataShare.confirmObject(this.user);
      this.loadConnectionNotifications();
      this.loadNotifications();
      this.activityTeamout();
      this.mostrarUsuarioPortal();
    }
    // this.user = usr;
    // this.loadConnectionNotifications();
    // this.loadNotifications();
  }

  checkIfUserShowBoxNotifications() {
    return localStorage.getItem("showBoxNoti");
  }

  setShowBoxNotifications() {
    if (this.notificationsCount > 0) {
      // Se agrega el objeto al local storage
      let now = new Date();
      let show = {
        day: now.getDate(),
        month: now.getMonth(),
        year: now.getFullYear(),
        hours: now.getHours(),
        minutes: now.getMinutes(),
        seconds: now.getSeconds()
      };
      localStorage.setItem("showBoxNoti", JSON.stringify(show));
      this.setNotificationsCount(show);
    }
  }

  setNotificationsCount(dateShowNoti) {
    // Validar si se recibe la fecha de visualización
    let dateLS = null;
    if (dateShowNoti != null) {
      dateLS = new Date(dateShowNoti.year, dateShowNoti.month, dateShowNoti.day, dateShowNoti.hours, dateShowNoti.minutes, dateShowNoti.seconds);
    } else {
      let check = this.checkIfUserShowBoxNotifications();
      if (check != null) {
        let showBoxNoti = JSON.parse(check);
        dateLS = new Date(showBoxNoti.year, showBoxNoti.month, showBoxNoti.day, showBoxNoti.hours, showBoxNoti.minutes, showBoxNoti.seconds);
      } else {
        this.notificationsCount = this.notifications.length;
        return;
      }
    }
    if (this.notifications) {
      this.notificationsCount = this.notifications.length;
    }
    /*this.notificationsCount = this.notifications.filter(noti => {
      let ultimaFecha = noti.fecha_creacion.$date.split(".");
      let dateNoti = new Date(ultimaFecha[0]);
      return dateNoti > dateLS;
    }).length;*/
  }

  loadNotifications() {
    if (this.user)
      this.notificationsResource.getNewNotifications({s: 1, empleado: 'A', userid: this.user._id}).then(res => {
        this.notifications = res;
        this.notifications.sort(function(a, b) {
          return a._id - b._id;
        }).reverse();
        this.setNotificationsCount(null);
      }, err => console.error(err));
  }

  seenNotification(notification: NotificationModel, navegar: boolean = false) {
    if (notification && !notification.fecha_visto) {
      this.notificationsResource.seenNotifications({_id: notification._id}).then(obj => {
        this.borrarNotificacionDeListaYDisminuirContador(notification);
      });
    }
  }


  /**
   * Enlaza este método para pasarlo al modal de notificaciones y poder acceder a él desde ese modal.
   */
  get borrarNotificacionDeListaYDisminuirContadorFunc() {
    return this.borrarNotificacionDeListaYDisminuirContador.bind(this);
  }

  /**
   * Elimina la notificación de la lista y disminuye el contador de notificaciones en uno.
   * @param notification Instancia de NotificationModel con la notificación que se va a quitar de la lista.
   */
  private borrarNotificacionDeListaYDisminuirContador(notification: NotificationModel) {
    const index = this.notifications.findIndex(el => el._id === notification._id);
    if (index != -1) {
      this.notifications.splice(index, 1);
      // Disminuye conteo de notificaciones
      this.notificationsCount--;
    }
  }

  hideNav(e) {
    e.hide();
  }



  cargarTramite(tramite) {
    console.log(tramite);

    console.log(tramite._id);
    if (tramite.tipoP == 'RC') {
      this.router.navigate(['portal/reporte-ciudadano/' + tramite._id]);
      this.ocutarMenuReporte();
    } else if (tramite.tipoP == 'RCS') {
      this.router.navigate(['portal/bpm_tramites/rcs/' + tramite._id]);
      this.ocutarMenuReporte();
    } else if (tramite.tipoP == 'ST') {
      this.router.navigate(['portal/bpm_tramites/vu/' + tramite._id]);
      this.ocutarMenuReporte();
    } else if (tramite.tipoP == 'RI') {
      this.router.navigate(['portal/bpm_tramites/ti/' + tramite._id]);
      this.ocutarMenuReporte();
    }
  }

    //metodo para validar si el usuario no esta logueado que aparezca el modal login (claro se valida si desde la configuracion del funcionario esta activo la opcion de inicio_sesion de login)
   // aqui tambien se valida que no tenga acceso a diferentes rutas si es que login empleados esta activo.
    mostrarLoginUsuarioNoLogueado() {
      this.configuracionGeneralService.obtenerConfiguracionGeneral()
      .then(config => {
        if(config.inicio_sesion && this.user ==null){
          //aqui se valida la ruta para que no tenga acceso a ninguna otra si aun no se ha logueado
          this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd && config.inicio_sesion && this.user == null) {
              const url = this.router.url.toLowerCase();
              if (url === '/portal/loginprincipal' || url === '/login' || url.startsWith('/app')) {
                return; // permitir la navegación en estas páginas
              } else {
                this.router.navigate(['/portal/loginPrincipal'], { skipLocationChange: true });
              }
            }
          });
          this.ocultarNavegacion=true;   // variable para ocultar el header
          const link = this.myLink.nativeElement;
          this.router.navigate(['/portal/loginPrincipal'], { skipLocationChange: true });
          link.click();
        }else{
          this.ocultarNavegacion=false;  //variable para mostrar el header
          this.actualizarComponenteService.reloadComponent('footer');
        }
      });
    }
  //metodo para mostrar el nombre y apellido de el usuario logueado , se realiza validacion
  mostrarUsuarioPortal() {
    console.log(this.user, 'ppppppppppppppppppppppppppppppppppppppppppp')
    if (this.user) {
      this.canShowInmobiliaria = true
      this.ocultarNavegacion = false;
      this.usuarioService.obtenerUsuario(this.user._id).then(userRes => {
        this.usuarioPortal = "Hola, " + userRes.nombre + " " + userRes.apellido_p;
      }, err => {
        console.log('error al obtener configuración');
        this.toastMsg.error('Error al obtener configuración del portal.');
      });
    } else {
      this.canShowInmobiliaria = false
      this.usuarioPortal = "";
    }
  }
}
