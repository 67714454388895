import {Component, OnInit, HostListener} from '@angular/core';
import {AuthServerProviderConsumos} from '../common.module/auth/auth-jwt-consumos.service';
import {Router} from '@angular/router';
import {UserAuthConsumos} from '../common.module/auth/user-auth-consumos.model';
import {ToastService} from 'ng-uikit-pro-standard';
import {ConfiguracionGeneralServiceConsumos} from '../main-login-app-consumos.component/service/configuracion-general.service';
import {ConfiguracionGeneralModel} from '../main-login-app-consumos.component/model/configuracion-general.model';
import {AuthExtendedResourceConsumos} from '../main-login-app-consumos.component/resource/auth-extended.resource';
import { Subject } from 'rxjs';
import {NotificationModel} from "../main-portal.component/service/notification.model";
import {NotificationsResource} from "../main-portal.component/service/notifications.resource";
import {EventBusService} from "../common.module/util/event-bus.service";

@Component({
    selector: 'app-left-slider-consumos',
    templateUrl: './sidebar-consumos.component.html'
})

export class SidebarComponentConsumos implements OnInit {
  user: UserAuthConsumos;
  public configuracion: ConfiguracionGeneralModel = {};
  public notifications: NotificationModel[] = [];
  public showNotifications = false;
  public notificationsCount: any = 0;
  validViews: Array<any>;
  userActivity;
  userInactive: Subject<any> = new Subject();
  public username: any = 'Usuario';
  public logoSidebar:string="";

  constructor(private authServerProvider: AuthServerProviderConsumos,
              private router: Router,
              //@TODO Variable no econtrada,
              private notificationsResource: NotificationsResource,
              private configuracionGeneralService: ConfiguracionGeneralServiceConsumos,
              private authExtendedResource: AuthExtendedResourceConsumos,
              private eb: EventBusService,
              private toastMsg: ToastService) {
    this.user = authServerProvider.getUserDecoded();
    console.log('usuario obtenido');
    console.log(this.user);
    const username = this.user.correo.split('@', 1);
    this.username = username[0];





  }




  /**
   * Validación de roles a ver la opción.
   * @param rols: Array
   */
  validateRole(rols: any[]) {
    return true;
  }

  userSession(){
    if(this.user != null || this.user != undefined){
      this.setTimeout();
      this.userInactive.subscribe(() => this.signOut());
    }
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 14400000);
  }

  @HostListener('window:mousemove') refreshUserState() {
    if(this.user != null || this.user !=undefined){
      clearTimeout(this.userActivity);
      this.setTimeout();
    }
  }

  signOut() {
    this.authExtendedResource.logout().then(aVoid => {
      console.log('Cerrado de sesión guardado en bitácora');
    }, err => console.error(err));
    this.authServerProvider.logout();
    this.router.navigate(['/login-consumos']);
  }

/*
  generatePublicFileWith64(idFilePublic) {
    this.providersService.getPublicFile(idFilePublic).then(res => {
      console.log(res[0]);
      if (res[0].file_public_64 != null) {

        this.downloadFile(res[0].name, res[0].file_public_64);
      } else {
        this.toastMsg.error('El archivo no esta disponible por el momento');
      }
    }, err => {
      if (err.body.message) {
        this.toastMsg.error(err.body.message);
      } else {
        this.toastMsg.error('Error en la visualización del PDF');
      }
    });
  }*/

  downloadFile(filename, base64) {
    const dataURI = this.base64ToArrayBuffer(base64);
    const blob = new Blob([dataURI], {type: 'application/pdf'});
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      window.open(window.URL.createObjectURL(blob), '_blank');
    }
  }

  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  ngOnInit(): void {

    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion.logoPrincipalFuncionarioConsumos = configuracion.logoPrincipalFuncionarioConsumos;

      if(!this.user.is_admin){
        this.obtenerUsuario();
      }else{
        this.logoSidebar = configuracion.logoLoginFuncionarioConsumos;
      }

    });

    this.userSession();
    this.loadNotifications();
    this.loadConnectionNotifications();


  }


  obtenerUsuario(){
    this.configuracionGeneralService.obtenerUsuario(this.user._id).then(usr => {
      let logoEstablecimiento =null;

      console.log("usuario obtenido:"+usr.id_establecimiento);

      this.configuracionGeneralService.obtenerEstablecimiento(usr.id_establecimiento).then(establecimiento=>{
        logoEstablecimiento = JSON.parse(JSON.stringify(establecimiento.logo));
        this.configuracion.logoSidebarConsumos = logoEstablecimiento.base64;
        this.logoSidebar = logoEstablecimiento.base64;
      })
    });
  }

  checkIfUserShowBoxNotifications() {
    return localStorage.getItem("showBoxNotiAdmin");
  }

  setShowBoxNotifications() {
    if (this.notificationsCount > 0) {
      // Se agrega el objeto al local storage
      let now = new Date();
      let show = {
        day: now.getDate(),
        month: now.getMonth(),
        year: now.getFullYear(),
        hours: now.getHours(),
        minutes: now.getMinutes(),
        seconds: now.getSeconds()
      };
      localStorage.setItem("showBoxNotiAdmin", JSON.stringify(show));
      this.setNotificationsCount(show);
    }
  }

  setNotificationsCount(dateShowNoti) {
    // Validar si se recibe la fecha de visualización
    let dateLS = null;
    if (dateShowNoti != null) {
      dateLS = new Date(dateShowNoti.year, dateShowNoti.month, dateShowNoti.day, dateShowNoti.hours, dateShowNoti.minutes, dateShowNoti.seconds);
    } else {
      let check = this.checkIfUserShowBoxNotifications();
      if (check != null) {
        let showBoxNoti = JSON.parse(check);
        dateLS = new Date(showBoxNoti.year, showBoxNoti.month, showBoxNoti.day, showBoxNoti.hours, showBoxNoti.minutes, showBoxNoti.seconds);
      } else {
        this.notificationsCount = this.notifications.length;
        return;
      }
    }
    if (this.notifications) {
      this.notificationsCount = this.notifications.length;
    }
    /*this.notificationsCount = this.notifications.filter(noti => {
      let ultimaFecha = noti.fecha_creacion.$date.split(".");
      let dateNoti = new Date(ultimaFecha[0]);
      // return dateNoti > dateLS;
    }).length;*/
  }

  loadNotifications() {
    if (this.user)
      this.notificationsResource.getNewNotifications({s: 1, empleado: 'A', userid: this.user._id}).then(res => {
        this.notifications = res;
        this.notifications.sort(function(a, b) {
          return a._id - b._id;
        }).reverse();
        this.setNotificationsCount(null);
      }, err => console.error(err));
  }

  loadConnectionNotifications() {
    if (this.user)
      this.eb.registerHandler('ms.notifications.user.' + this.user._id, (error, message) => {
        if (error) {
          console.error('AppEventBusService.handleAction error', error);
          return;
        }
        if (!message.body) {
          console.error('AppEventBusService.handleAction - body is required in message', message);
          return;
        }
        this.toastMsg.info(message.body.titulo);
        this.notifications.unshift(message.body);
        this.notificationsCount++;
      });
    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion = configuracion;
    }, err => {
      console.log('Error al cargar la configuración en la cabecera');
      console.error(err);
    });
  }

  seenNotification(notification: NotificationModel, navegar: boolean = false) {
    if (notification && !notification.fecha_visto)
      this.notificationsResource.seenNotifications({_id: notification._id}).then(obj => {
        this.borrarNotificacionDeListaYDisminuirContador(notification);
      });
  }

  /*
    Método que comparan las pantallas obtenidas con el DOM y en función de estas
    se ocultan los elementos del slider.
  */
  showViewIfThereIsAccess(route: string): boolean {
    for(let i=0; i<this.validViews.length; i++) {
      for(let j=0; j<this.validViews[i].length; j++){
        if(this.validViews[i][j].tiene_acceso && this.validViews[i][j].ruta == route){
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Enlaza este método para pasarlo al modal de notificaciones y poder acceder a él desde ese modal.
   */
  get borrarNotificacionDeListaYDisminuirContadorFunc() {
    return this.borrarNotificacionDeListaYDisminuirContador.bind(this);
  }

  /**
   * Elimina la notificación de la lista y disminuye el contador de notificaciones en uno.
   * @param notification Instancia de NotificationModel con la notificación que se va a quitar de la lista.
   */
  private borrarNotificacionDeListaYDisminuirContador(notification: NotificationModel) {
    const index = this.notifications.findIndex(el => el._id === notification._id);
    if (index != -1) {
      this.notifications.splice(index, 1);
      // Disminuye conteo de notificaciones
      this.notificationsCount--;
    }
  }
}

