
import {PaymentResource} from './payment.resource';
import {Injectable} from '@angular/core';
import {QuestionsSuccessfulPayment} from './questionssuccessfulpayment.model';
/**
 * Created by MTI on 25/06/2018.
 */

@Injectable()
export class PaymentService {
  constructor(private  paymentResource: PaymentResource) {
  }
  save(questionssuccessfulpayment: QuestionsSuccessfulPayment) {
    return this.paymentResource.save(questionssuccessfulpayment);
  }
}
