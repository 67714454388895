/**
 * Created by Rsierra.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {ToastService} from 'ng-uikit-pro-standard';
import {AuthServerProvider} from './auth-jwt.service';


@Injectable()
export class AuthGuardMcPortal implements CanActivate {

  constructor(private auth: AuthService,
              private router: Router,
              private toastMsg: ToastService,
              private authServ: AuthServerProvider) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auth.loggedIn()) {
        this.toastMsg.warning('Iniciar sesión para continuar.');
        this.router.navigate(['portal']);
        return false;
      } else {
        return true;
      }
  }
}
