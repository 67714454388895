import {Injectable} from '@angular/core';
import {
  IResourceMethod,
  ResourceAction,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {ZonaModel} from './zona.model';
import {ResourceAuth} from '../auth/authenticate.resource';
import {AuthServerProvider} from '../auth/auth-jwt.service';

@Injectable()
@ResourceParams({
  pathPrefix: '/configuracion'
  // url: 'http://localhost:8003'
})
export class ZonaResource extends ResourceAuth {
  constructor(restHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(restHandler, authProv);
  }
// export class ZonaResource extends Resource {
//   constructor(restHandler: ResourceHandler) {
//     super(restHandler);
//   }

  @ResourceAction({
    path: '/na/zona',
    method: ResourceRequestMethod.Get,
    isArray: true
  })
  lodListaZonasNa: IResourceMethod<any, ZonaModel[]>;

  @ResourceAction({
    path: '/zona',
    method: ResourceRequestMethod.Get,
    isArray: true
  })
  lodListaZonas: IResourceMethod<any, ZonaModel[]>;

  @ResourceAction({
    path: '/zona/{!id}',
    method: ResourceRequestMethod.Get
  })
  lodZona: IResourceMethod<{ id: string }, ZonaModel>;

  @ResourceAction({
    path: '/zona',
    method: ResourceRequestMethod.Post
  })
  crtZona: IResourceMethod<ZonaModel, any>;

  @ResourceAction({
    path: '/zona/{!id}',
    method: ResourceRequestMethod.Patch
  })
  updZona: IResourceMethod<ZonaModel, any>;

  @ResourceAction({
    path: '/zona/{!id}',
    method: ResourceRequestMethod.Delete,

  })
  dltZona: IResourceMethod<{ id: string }, any>;
}
