import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[mti-font-titulos]'
})
export class MtiFontTitulosDirective {
  constructor(public el: ElementRef,
              private renderer: Renderer2) {
  }

  ngAfterViewChecked() {
    enum VALORES {
      theme = <any>JSON.parse(localStorage.getItem('theme')) != null ? JSON.parse(localStorage.getItem('theme')) : null
    }

    if (VALORES.theme != null) {
      this.renderer.addClass(this.el.nativeElement, VALORES.theme['fuenteTitulos']);
    } else {
      this.renderer.addClass(this.el.nativeElement, 'roboto');
    }
  }
}
