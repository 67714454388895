import {Component, OnInit} from '@angular/core';
import {ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {EventBusService} from './common.module/util/event-bus.service';
import {environment} from '../environments/environment';
import {ToastService} from "ng-uikit-pro-standard";
import {DbBaseRepository} from "./common.module/util/db.base.repository";
import { ConfiguracionGeneralService } from './admin.configuracion-general.module/service/configuracion-general.service';
import { UserAuth } from './common.module/auth/user-auth.model';
import { AuthServerProvider } from './common.module/auth/auth-jwt.service';
import { ActualizaComponenteService } from './main-portal.component/service/actualiza-componente.service';
import { changePortalIcon } from './admin.configuracion-general.module/configuracion-general/configuracion-general-domain';

@Component({
  selector: 'app-root',
  template: `
    <ng-progress [positionUsing]="'marginLeft'" [minimum]="0.15" [maximum]="1" [speed]="'200'" [showSpinner]="'false'"
                 [direction]="'leftToRightIncreased'" [trickleSpeed]="250" [thick]="false" [ease]="'linear'"
                 [color]="'#00e2ff'"></ng-progress>
    <router-outlet *ngIf="!cargando"></router-outlet>
  `
})
export class AppComponent implements OnInit {
public user:UserAuth;
public validacionRuta: Boolean = true;
public cargando:Boolean=true;

  constructor(private router: Router,
              private titleSrv: Title,
              private eb: EventBusService,
              private toastMsg: ToastService,
              private dbBaseRepository: DbBaseRepository,
              private configuracionGeneralService:ConfiguracionGeneralService,
              private authServerProvider:AuthServerProvider,
              private actualizarComponenteService:ActualizaComponenteService) {

  }

  ngOnInit(): void {
    this.dbBaseRepository.initDB();
    this.eventTitleSubscriber();
    this.eb.connect(environment.apiBaseUrl + '/ws/eb/', {});
    this.eb.close.subscribe(() => {
      console.log('Hemos cerrado la sesión');
      this.toastMsg.warning('Se ha detectado inestabilidad en su red, téngalo en cuenta.');

    });
    this.eb.open.subscribe(() => {
      console.log('Estamos conectados.');
    });

    this.mostrarLoginUsuarioNoLogueado();
  }

  /**
   * Modificación del titulo de la aplicacion
   *
   * @param routeSnapshot
   * @returns {string}
   */
  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title: string = (routeSnapshot.data && routeSnapshot.data['pageTitle']) ? routeSnapshot.data['pageTitle'] : 'App';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  private eventTitleSubscriber() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let title: string = this.getPageTitle(this.router.routerState.snapshot.root);
        if (!title && this.titleSrv.getTitle()) {
          title = this.titleSrv.getTitle();
        }
        this.titleSrv.setTitle(title);
      }
    });
  }
// Aqui se crea el metodo para obtener los valores de la bd  y verificar si el login empleados esta en true(con inicio_sesion) y lo redirige
  mostrarLoginUsuarioNoLogueado() {
    this.user = this.authServerProvider.getUserDecoded();
    this.configuracionGeneralService.obtenerConfiguracionGeneral()
    .then(config => {
      if(config && config.appIconFile) changePortalIcon(config.appIconFile)
      if (config.inicio_sesion && this.user == null) {
        const url = this.router.url.toLowerCase();
        if (url === '/portal/loginprincipal' || url === '/login' || url.startsWith('/app')) {
          // permitir la navegación en estas páginas
          this.cargando=false;
        } else {
          this.cargando=false;
          this.router.navigate(['/portal/loginPrincipal'], { skipLocationChange: true });
        }
      }else{
        this.cargando=false;
      }
    });
  }
}
