import {Injectable} from '@angular/core';
import {OrganizacionesResource} from './organizaciones.resource';
import {OrganizacionModel} from './OrganizacionModel.model';
import { resolve } from 'url';

@Injectable()
export class OrganizacionesService {

  constructor(private organizacionesResource: OrganizacionesResource) {}

  
  obtenerOrganizacionesPaginado(model: any) {
    return this.organizacionesResource.paginadoOrganizacion(model);
  }

}
