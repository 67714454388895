/**
 * Created by ANUNEZ on 11/03/2019.
 */

/**
 * Lista de nombre de las colecciones que se crearan en bd
 *
 *
 */
export enum CollectionsDBName {
  ITEMS = "Items",
  SYNC_ITEMS = "SyncItems"
}
