import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { PaseCajaCancelModel } from '../service/pase_caja_cancel.model';
import {ModalDirective} from 'angular-bootstrap-md';
import { PaseCajaService } from '../service/pase_caja.service';
import { PagosModel } from 'src/app/common.tramite-vu.module/entidad.pago.component/service/models/pase-caja-response/pagos.model';
import { ToastService } from 'ng-uikit-pro-standard';
import { ValidatorHelper } from 'src/app/common.module/util/validator.helper';
import { UserAuth } from 'src/app/common.module/auth/user-auth.model';
import { AuthServerProvider } from 'src/app/common.module/auth/auth-jwt.service';
import {ModalConfirmComponent} from "../../common.module/components/generic/modal-confirm/modal-confirm.component";

@Component({
  selector: 'app-pase-caja-detalle',
  templateUrl: './pase-caja-detalle.component.html'
})
export class PaseCajaDetalleComponent implements OnInit {

  @Output()
  reload: EventEmitter<any> = new EventEmitter();
  @ViewChild('NewCancelacion')
  public modal: ModalDirective;
  public formCancel: FormGroup;
  @Input()
  public pase_caja: PaseCajaCancelModel = <PaseCajaCancelModel>{};
  public pago: PagosModel;
  public leyendaPaseCaja: string = '';
  public leyendaContribuyente: string = '';
  public mostrarLeyenda: boolean = true;
  public canInsertMotivo: boolean = true;
  public canSave: boolean = true;
  public canSearchPaseCaja: boolean = true;
  user: UserAuth;
  public username: any = 'Usuario';
  @ViewChild(ModalConfirmComponent) public modalConfirmacion;
  public mensajeEliminacion: string;
  public btnSearch: boolean = false;


  constructor(private fb: FormBuilder,
    private paseCajaService: PaseCajaService,
    private toastMsg: ToastService,
    private authServerProvider: AuthServerProvider,) 
    {
      this.user = authServerProvider.getUserDecoded();
      console.log("usuario obtenido");
      console.log(this.user);
      const username = this.user.correo.split('@', 1);
      this.username = username[0];
      console.log("usuario_: " + this.username);
    }

  ngOnInit() {
    this.formCancel = this.fb.group({
      defaultFormPaseCaja: ['', Validators.required],
      defaultFormMotivoCancel: ['', Validators.required],
      defaultFormUsuarioCancela: [],
      defaultFormFechaCancelacion: []
    });
  }

  ngOnChange() {
  
  }

  enEdit(estatus: boolean) {
      this.formCancel.controls['defaultFormPaseCaja'].disable();
      this.formCancel.controls['defaultFormMotivoCancel'].disable();
      this.formCancel.controls['defaultFormUsuarioCancela'].disable(); 
      this.formCancel.controls['defaultFormFechaCancelacion'].disable(); 
      this.btnSearch = true;      
      this.toastMsg.info('Información solo de consulta');
      
  }

  enBusqueda(estatus: boolean) {
      this.formCancel.controls['defaultFormPaseCaja'].enable();
      this.formCancel.controls['defaultFormMotivoCancel'].enable();    
  }

  limpiaVariables(){
    this.pase_caja.pase_caja = '';
    this.canInsertMotivo = true;
    this.leyendaPaseCaja = '';
    this.leyendaContribuyente = '';
    this.mostrarLeyenda = true;
    this.canSave = true;
    this.formCancel.reset();
    this.pase_caja = <PaseCajaCancelModel>{}; 
  }

  searchPaseCaja(){
    this.addControlsWhenNot();
    if(this.pase_caja.pase_caja !== undefined) {
      this.paseCajaService.obtenerPaseCaja(this.pase_caja.pase_caja).then(data => {
       console.log("Estos son los datos: ", data);
       this.pago = data;
        this.mostrarLeyenda = false;
        console.log("Estatus: " + data.estatus);
        let auxLeyenda = this.pago.detalle_pase_caja.contribuyente.nombre_completo != "" ? this.pago.detalle_pase_caja.contribuyente.nombre_completo : this.pago.detalle_pase_caja.contribuyente.razon_social;
         this.leyendaContribuyente = 'Contribuyente: '+ auxLeyenda;
        switch(this.pago.estatus) {
          case '001': {
            this.leyendaPaseCaja = 'El pase de caja \"' + this.pase_caja.pase_caja + '\" ya está pagado';
        //  this.leyendaContribuyente = 'Contribuyente: ' + this.pago.detalle_pase_caja.contribuyente.nombre_completo;
            this.canInsertMotivo = true; 
            this.addControlsWhenNot();
            this.canSave = true;
          break;
          }
          case '002': {
            this.leyendaPaseCaja = 'El pase de caja \"' + this.pase_caja.pase_caja + '\" ya está cancelado';
          //  this.leyendaContribuyente = 'Contribuyente: ' + this.pago.detalle_pase_caja.contribuyente.nombre_completo;
            this.canInsertMotivo = true;
            this.addControlsWhenNot();
            this.canSave = true;
          break;
          }
          case '000': {
            this.leyendaPaseCaja = 'El pase de caja ' + this.pase_caja.pase_caja + ' puede cancelarse';
          //  this.leyendaContribuyente = 'Contribuyente: ' + this.pago.detalle_pase_caja.contribuyente.nombre_completo;
            this.canInsertMotivo = false;
            this.canSave = false;
          break;
          }
          default: {
            break;
          }
        }
      }).catch(er=>{
        this.toastMsg.warning(er.body.message);
      })
    }
  }

  addControls() {
    this.formCancel.controls['defaultFormPaseCaja'].setValidators(Validators.required);
    this.formCancel.controls['defaultFormPaseCaja'].reset();
    this.formCancel.controls['defaultFormMotivoCancel'].setValidators(Validators.required);
    this.formCancel.controls['defaultFormMotivoCancel'].reset();
  }

  addControlsWhenNot(){
    this.formCancel.controls['defaultFormMotivoCancel'].setValidators(Validators.required);
    this.formCancel.controls['defaultFormMotivoCancel'].reset();
  }

  validateAllFormFields(){
  }

  save(){
    this.canSave = true;
    if (this.formCancel.valid === false) {
      this.toastMsg.warning('Favor de llenar todos los campos obligatorios');
      ValidatorHelper.validateAllFormFields(this.formCancel);
      this.canSave = false;
      return;
    }
    this.mensajeEliminacion = "¿Esta seguro que desea cancelar el pase de caja?";
    this.modalConfirmacion.modal.show();
  }

  saveCancel(event:boolean){
    if(!event){
      this.canSave = false;
      return;
    }
      
    this.pase_caja.id_usuario_cancelacion = this.user._id;
    this.paseCajaService.crearPaseCajaCancel(this.pase_caja).then(res => { 
        this.toastMsg.success("El pase de caja ha sido cancelado");
        this.reload.emit();
        this.limpiaVariables();
        this.modal.hide();
        this.formCancel.reset();
        this.pase_caja = <PaseCajaCancelModel>{}; 
        this.addControls();

    }, err => {
      if (err.body && err.body.message) {
        this.toastMsg.error(err.body.message);
        this.canSave = false;
      } else {
        this.toastMsg.error('Ocurrio un error al guardar');
        this.canSave = false;
      }
      //this.inputFile.nativeElement.value = null;
    })
  }
}
