import {Component, OnInit} from '@angular/core';
import {AuthServerProvider} from '../common.module/auth/auth-jwt.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ToastService} from "ng-uikit-pro-standard";
import {isNullOrUndefined} from 'util';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfiguracionGeneralServiceConsumos} from '../main-login-app-consumos.component/service/configuracion-general.service';

@Component({
  selector: 'app-main-admin-consumos',
  templateUrl: './main.component.html'
})
export class MainAdminConsumosComponent implements OnInit {
  public validWin = false;
  constructor(private auth: AuthServerProvider,
              private toast: ToastService,
              private router: Router,
              private configuracionGeneralService: ConfiguracionGeneralServiceConsumos) {
    localStorage.removeItem('theme');
  }

  ngOnInit() {
    const helper = new JwtHelperService();
    const userDecode = helper.decodeToken(this.auth.getToken());
    console.log('Usuario iniciado');
    // if (this.route.url)
    if (isNullOrUndefined(userDecode)) {
      setTimeout(() => {
        this.toast.error('Para realizar una denuncia en línea es necesario iniciar sesión.');
          this.router.navigate(['login-consumos']);
      }, 50);
    }
    // Obtiene la información del tema seleccionado
    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      const obj = {
        groupElements1: configuracion.groupElements1,
        groupElements2: configuracion.groupElements2,
        groupElements3: configuracion.groupElements3,
        groupElements4: configuracion.groupElements4,
        color1: configuracion.color1,
        color2: configuracion.color2,
        color3: configuracion.color3,
        color4: configuracion.color4,
        fuente: configuracion.fuenteGeneral
      };
      localStorage.setItem('theme', JSON.stringify(obj));
    });
  }
}
