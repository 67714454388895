import {LoginRepository} from "./login.repository";
import {Injectable} from "@angular/core";
/**
 * Created by ANUNEZ on 12/03/2019.
 */

@Injectable()
export class LoginService{

  constructor(private loginRepository: LoginRepository){
  }

  addItem(collectionName: string , value): any{
    this.loginRepository.addItem(collectionName, value);
  }

  getItemById(collectionName: string, id){
    return this.loginRepository.getItemById(collectionName,id);
  }

  deleteItemById(collectionName: string, id){
    return this.loginRepository.deleteItemById(collectionName,id);
  }

  getAllItems(collectionName: string) {
    return this.loginRepository.getAllItems(collectionName);
  }
}
