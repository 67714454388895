import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod, Resource} from '@ngx-resource/core';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {NotificationModel} from './notification.model';

/**
 * Created by Raul on 20/07/2018.
 */

@Injectable()
@ResourceParams({
  pathPrefix: '/notificaciones'
  //url: 'http://localhost:8001'
})
/*export class NotificationsResource extends Resource {
  constructor(requestHandler: ResourceHandler) {
    super(requestHandler);
  }*/
export class NotificationsResource extends ResourceAuth {
  constructor(handler: ResourceHandler, authProv: AuthServerProvider) {
    super(handler, authProv);
  }

  @ResourceAction({
    path: '/notifications'
  })
  getNotifications: IResourceMethod<any, NotificationModel[]>;

  @ResourceAction({
    path: '/notifications?newNotifications={!s}'
  })
  getNewNotifications: IResourceMethod<{ s: any, empleado: string, userid: string }, NotificationModel[]>;

  @ResourceAction({
    path: '/notifications?newNotifications={!s}'
  })
  getNotificationsB: IResourceMethod<{ s: any, empleado: string, userid: string }, NotificationModel[]>;

  @ResourceAction({
    path: '/notifications/seen/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  seenNotifications: IResourceMethod<NotificationModel, any>;
}
