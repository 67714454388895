<div class="row no-gutters">
  <div class="card mt-lg-3 mb-3 mt-sm-3 col-lg-12">
    <div mti-color-theme class="card-header unique-color lighten-1 white-text">
      <div class="row">
        <div class="col-auto mr-auto align-self-center"><strong>Catálogo de expedientes electrónicos</strong></div>
        <div class="col-auto ml-auto">
          <a class="btn-floating btn-sm red" (click)="pdf()" mdbTooltip="Descargar PDF" placement="bottom"
             mdbWavesEffect><i class="far fa-file-pdf white-text" aria-hidden="true"></i></a>
          <a class="btn-floating btn-sm green" (click)="xls()" mdbTooltip="Descargar Excel" placement="bottom"
             mdbWavesEffect><i class="far fa-file-excel white-text" aria-hidden="true"></i></a>
        </div>
      </div>
    </div>
    <app-list-items [configItems]="configItems"
                    [list]="listaElementos"
                    [count]="count"
                    [listToExport]="listaAExportar"
                    [filter]="filterModel"
                    [appRoute]="'/portal'"
                    [validateStatus]="true"
                    [disableValidate]="true"
                    [disableTableResponsive]="false"
                    (actionEmitterCallback)="cargarDetalle($event)"
                    (actionSecondEmitterCallback)="editarDetalle($event)"
                    (load)="cargaListaExpElecUsuario()"></app-list-items>
  </div>
</div>

<app-cons-exp-elec-detalle [listaElementos]=listaElementos
                           [expedienteElect] =expedienteElect
                         (reload)="cargaListaExpElecUsuario()"></app-cons-exp-elec-detalle>
<app-cons-exp-elec-duplicado [listaElementosDuplicado]=listaElementosDuplicado></app-cons-exp-elec-duplicado>

