<div mdbModal [config]="{backdrop: 'static'}" #detExpElectDuplicadoModal="mdb-modal" class="modal fade overflow-auto" tabindex="-1" role="textbox"
     aria-labelledby="myBasicModalLabel" aria-hidden="true" >
  <div class="modal-dialog modal-lg" role="textbox">
    <div class="modal-content">
      <div mti-color-theme class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="detExpElectDuplicadoModal.hide();">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">RFC Duplicados</h4>
      </div>
      <div class="modal-body">
        <table mdbTable>
          <thead>
          <tr>
            <th scope="col">CURP</th>
            <th scope="col">RFC</th>
            <th scope="col">Nombre completo</th>
          </tr>
          </thead>
          <tbody>
          <tr mdbTableCol *ngFor="let ins of listaElementosDuplicado">
            <td>{{ins.curp}}</td>
            <td>{{ins.rfc}}</td>
            <td>{{ins.nombre+" "+ins.apellido_p+" "+ins.apellido_m}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">

      </div>
    </div>
  </div>
</div>
