import {FormalityNotesResource} from './formality-notes.resource';
import {Injectable} from '@angular/core';
import {FormalitiesNotesModel} from './formality-notes.model';
/**
 * Created by Raul on 20/07/2018.
 */
@Injectable()
export class FormalitiesNotesService {

  constructor(private formalityNotesResource: FormalityNotesResource) {
  }

  retrieveNotesPerFormality(formalityId: number): Promise<FormalitiesNotesModel[]> {
    return this.formalityNotesResource.getFormalityNotes({formalities_id: formalityId});
  }

  saveFormalityNote(formalityNote: FormalitiesNotesModel): Promise<any> {
    return this.formalityNotesResource.saveFormalityNotes(formalityNote);
  }
}
