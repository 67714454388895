/**
 * Created by MTI on 29/05/2018.
 */
import {Injectable} from '@angular/core';
import {LocationResource} from '../resource/location.resource';
@Injectable()
export class LocationService {
  constructor(private sResource: LocationResource) {}
  getStates(id): Promise <any>  {
    return this.sResource.getStates({id: id});
  }
  getCounties(id): Promise <any>  {
    return this.sResource.getCounties({id: id});
  }
  getLocalities(id): Promise <any>  {
    return this.sResource.getLocalities({id: id});
  }
}
