import {Injectable} from '@angular/core';
import {
  IResourceMethod,
  ResourceAction,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod,
  Resource
} from '@ngx-resource/core';
import { AuthServerProvider } from 'src/app/common.module/auth/auth-jwt.service';
import { ResourceAuth } from 'src/app/common.module/auth/authenticate.resource';
import {OrganizacionModel} from './OrganizacionModel.model';

@Injectable()
@ResourceParams({
  pathPrefix: '/administracion'
   //url: 'http://localhost:8001'
})
 //export class OrganizacionesResource extends Resource {
   //constructor(requestHandler: ResourceHandler) {
    //super(requestHandler);
  //}
export class OrganizacionesResource extends ResourceAuth {
  constructor(restHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(restHandler, authProv);
  }
  @ResourceAction({
    path: '/paginacion-organizaciones',
    method: ResourceRequestMethod.Get
  })
  paginadoOrganizacion: IResourceMethod<any, OrganizacionModel[]>;

  
}
