/**
 * Created by Rigo G M on 04/01/2024.
 */
import {Injectable} from '@angular/core';
import * as L from 'leaflet';
import html2canvas from "html2canvas";

@Injectable()
export class OperacionesMapaService {
  
  constructor(){}

  public crearMapa(latitud: number, logitud: number, dragging?: boolean, agregarAlbody?: boolean) {
    return new Promise<HTMLDivElement|undefined>((resolve, reject) => {
      const fecha = new Date();
      
      const divContedor = document.createElement('div');
      divContedor.id = 'map-container';
      divContedor.style.position = 'absolute';
      divContedor.style.top = '0';
      divContedor.style.right = '0';
      divContedor.style.left = '0';
      divContedor.style.bottom = '0';
      divContedor.style.width = `900px`;
      divContedor.style.opacity = '0';

      const divMap = document.createElement('div');
      divMap.id = 'map';
      divMap.style.height = '100%';
      divMap.style.cursor = 'progress';

      divContedor.appendChild(divMap);

      const iconoRojo = L.icon({
        iconUrl: 'assets/img/indicador.png',
        iconSize:     [38, 55], // tamanio del icono
        iconAnchor:   [22, 54], // punto del icono correspondiente al marke 
      });

      try {
        const map = L.map(divMap);
        if (!dragging) {
          map.dragging.disable();
          map.boxZoom.disable();
          map.doubleClickZoom.disable();
          map.scrollWheelZoom.disable(); 
          map.touchZoom.disable();
          map.zoomControl.remove();
        }
        // En caso de no hacerlo, puede ser que no se cargue por completo el mapa
        if (agregarAlbody) {
          document.body.appendChild(divContedor);
        }
        map.whenReady(() => {
          console.log('se creo el mapa');
          resolve(divMap);
        })

        map.on('error', (e) => {
          console.error(e);
          document.body.removeChild(divContedor);
          resolve(undefined);
        })
        
        map.setView([latitud, logitud], 17);

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map); 

        L.marker([latitud, logitud], {icon: iconoRojo}).addTo(map);
      } catch(e) {
        document.body.removeChild(divContedor); 
        resolve(e);
      }
    })
  }

  public convertirElementABase64(mapContainer: HTMLElement) {
    try {
      return html2canvas(mapContainer, {
        useCORS: true,
        width: mapContainer.offsetWidth,
        height: mapContainer.offsetHeight,
      }).then(function(canvas) {
        // const base64Canvas = canvas.toDataURL("image/png").split(';base64,')[1];
        const base64Canvas = canvas.toDataURL("image/png");
  
        // const link = document.createElement('a');
        // link.setAttribute('download', 'mi_mapa.png');
        // link.setAttribute('href', canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
        // link.click();
  
        return base64Canvas;
      })
    } catch(e) {
      return Promise.resolve('');
    }
  }

  
}
