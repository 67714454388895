/**
 * Created by Raul on 20/07/2018.
 */

import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {AuthServerProvider} from '../../../auth/auth-jwt.service';
import {ResourceAuth} from '../../../auth/authenticate.resource';
import {ConfiguracionChatModel} from '../../../../admin.configuracion-chat.module/service/configuracion-chat.model';

@Injectable()
@ResourceParams({
  pathPrefix: '/configuracion'
  // url: 'http://localhost:8003'
})
export class ChatConfiguracionResource extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resourceHandler, authProv);
  }

// export class ChatConfiguracionResource extends Resource {
//   constructor(resourceHandler: ResourceHandler) {
//     super(resourceHandler);
//   }

  @ResourceAction({
    path: '/na/configuracion-chat',
    method: ResourceRequestMethod.Get
  })
  obtenerConfiguracionChat: IResourceMethod<any, ConfiguracionChatModel>;
}
