/**
 * Created by Rsierra.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {ToastService} from "ng-uikit-pro-standard";
import {AuthServerProvider} from './auth-jwt.service';
import {isNullOrUndefined} from 'util';

@Injectable()
export class AuthGuardPortal implements CanActivate {

  constructor(private auth: AuthService,
              private router: Router,
              private toastMsg: ToastService,
              private authServ: AuthServerProvider) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
    if (this.auth.loggedIn()) {
      console.log(state);
      const usr = this.authServ.getUserDecoded();
      if (usr && usr.authorities && usr.authorities.length > 0) {
        const index = usr.authorities.find(obj =>
        obj.permissions && obj.permissions.some(per => ((state.url.indexOf(per.res_path) !== -1)
        || (state.url.indexOf(per.frm_path) !== -1)) && per.can_read));
        if (isNullOrUndefined(index)) {
          this.toastMsg.warning('Usted no cuenta con los permisos necesarios para esta acción, consulte al administrador.');
          return false;
        } else
          return true;
        // return true;
      } else
        return false;
      // return true;
    } else {
      this.toastMsg.warning('Iniciar sesión para continuar.');
      this.router.navigate(['portal']);
      return false;
    }
  }
}
