/**
 * Created by Rsierra.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {ToastService} from "ng-uikit-pro-standard";
import {AuthServerProvider} from './auth-jwt.service';
import 'rxjs-compat/add/observable/of';
import {RequestsService} from "../service/requests.service";

@Injectable()
export class AuthGuardContractors implements CanActivate {
  private RFC_VALID = 'S';

  constructor(private auth: AuthService,
              private router: Router,
              private toastMsg: ToastService,
              private authServ: AuthServerProvider,
              private contractorsService: RequestsService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.loggedIn()) {
      return this.contractorsService.getValidRfc().then(res => {
        console.log(res);
        if (res.p_acceso != null) {
          if (res.p_acceso == this.RFC_VALID) {
            return true;
          } else {
            this.toastMsg.warning(res.error);
            return false;
          }
        } else {
          this.toastMsg.warning('Error en la validación de RFC para acceso al catálogo contratistas');
        }
      }, err => {
        if (err.body) {
          if (err.body.message)
            this.toastMsg.warning(err.body.message);
          else
            this.toastMsg.error('Ocurrió un error inesperado al validar el RFC del usuario');
        } else
          this.toastMsg.error('Ocurrió un error inesperado al validar el RFC del usuario');
        return false;
      });
    } else {
      this.toastMsg.warning('Iniciar sesión para continuar.');
      // this.router.navigate(['portal']);
      return false;
    }
  }

}
