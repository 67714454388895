import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'angular-bootstrap-md';
import {ExpedientesElectModel} from '../service/expedientes-elect.model';
import {ConfigItem} from '../../common.module/model/config-item.model';
@Component({
  selector: 'app-cons-exp-elec-duplicado',
  templateUrl: 'cons-exp-elec-duplicados.component.html'
})
export class ConsExpElecDuplicadoComponent implements OnInit {

  constructor() {
  }

  @Input()
  public listaElementosDuplicado: ExpedientesElectModel[];

  @ViewChild('detExpElectDuplicadoModal')
  public modale: ModalDirective;
  public configItems: ConfigItem[] = [];

  ngOnInit() {
    this.configItems = [
      {
        title: 'CURP',
        nameAttribute: 'curp',
        nameFilter: 'curp'
      },
      {
        title: 'RFC',
        nameAttribute: 'rfc',
        nameFilter: 'rfc'
      },
      {
        title: 'Nombre ',
        nameAttribute: 'nombre',
        nameFilter: 'nombre'
      },
      {
        title: 'Apellido paterno',
        nameAttribute: 'apellido_p',
        nameFilter: 'apellido_p'
      },
      {
        title: 'Apellido materno',
        nameAttribute: 'apellido_m',
        nameFilter: 'apellido_m'
      }
    ];
  }
}




