/**
 * Created by Rsierra.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {ToastService} from "ng-uikit-pro-standard";
import {AuthServerProvider} from './auth-jwt.service';
import 'rxjs-compat/add/observable/of';
import {ProvidersService} from "../../portal-legacy/portal.providers.module/service/providers.service";
import {from, Observable} from "rxjs";
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class AuthGuardProviders implements CanActivate {

  private RFC_VALID = 'S';
  constructor(private auth: AuthService,
              private router: Router,
              private toastMsg: ToastService,
              private authServ: AuthServerProvider,
              private providersService: ProvidersService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.auth.loggedIn()) {
      const toObs: Observable<any> = from(this.providersService.getValidRfc());
      return toObs.map(res => {
        console.log(res);
        if (res.p_acceso != null) {
          if (res.p_acceso == this.RFC_VALID) {
            return true;
          } else {
            this.toastMsg.warning(res.error);
            return false;
          }
        } else {
          this.toastMsg.warning('Error en la validación de RFC para acceso al catálogo proveedores');
        }
      }).catch(() => {
        // @ts-ignore
        return Observable.of(false);
        /*if (err.body.message) {
          this.toastMsg.error(err.body.message);
        } else {
          this.toastMsg.error('Error en la validación de RFC para acceso al catalogo proveedores');
        }*/
      });
    } else {
      this.toastMsg.warning('Iniciar sesión para continuar.');
      // this.router.navigate(['portal']);
      return false;
    }
  }

}
