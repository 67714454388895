<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-4 pt-4 mt-3 animated fadeIn fast">
      <div class="mx-auto mb-5 d-flex justify-content-center">
        <img [src]="'data:image/jpg;base64,'+configuracion.logoLoginFuncionario" class="img-fluid">
        <!--<img src="assets/img/logo.png" class="img-fluid">-->
      </div>
      <!-- Login form -->
      <form [formGroup]="loginForm" (submit)="loginForm.valid && login()">
        <p class="h5 text-center mb-4" *ngIf="!esDobleAuth">Iniciar sesión</p>
        <p class="h5 text-center mb-4" *ngIf="esDobleAuth">Código de autentificación</p>
        <div class="md-form" *ngIf="!esDobleAuth">
          <i class="fa fa-envelope prefix grey-text"></i>
          <input type="email" formControlName="email" id="defaultForm-email" class="form-control"
                 data-error="Datos invalidos" data-success="Correcto"
                 mdbInput>


          <label for="defaultForm-email">Correo electrónico</label>
          <div *ngIf="loginForm.controls['email'].dirty">
            <div *ngIf="loginForm.controls['email'].errors?.required"
                 class="text-danger animated fadeInDown fast-4 small">
              El correo electrónico es obligatorio.
            </div>
            <div *ngIf="loginForm.controls['email'].errors?.email"
                 class="text-danger animated fadeInDown fast-4 small">
              Formato de correo incorrecto.
            </div>
          </div>
        </div>
        <div class="md-form" *ngIf="!esDobleAuth">
          <i class="fa fa-lock prefix grey-text"></i>
          <input type="password" formControlName="password" id="defaultForm-pass" class="form-control"
                 mdbInput>
          <label for="defaultForm-pass">Contraseña</label>
          <div *ngIf="loginForm.controls['password'].dirty">
            <div *ngIf="loginForm.controls['password'].errors?.required"
                 class="text-danger animated fadeInDown fast-4 small">
              La contraseña es obligatoria.
            </div>
          </div>
        </div>

        <div class="md-form" *ngIf="esDobleAuth">
          <i class="fa fa-lock prefix grey-text"></i>
          <input  type="text" formControlName="codigo" id="defaultForm-codigo" class="form-control"
                 data-error="Datos invalidos" data-success="Correcto"  maxlength="6"
                 mdbInput>
          <label for="defaultForm-codigo">Código</label>
            <div class="text-danger animated fadeInDown fast-4 small"
            *ngIf="loginForm.controls['codigo'].errors?.pattern">
            Formato incorrecto
        </div>
        </div>
        <div class="text-center">
          <input type="submit" class="btn btn-default waves-light" mdbWavesEffect value="Login">
        </div>
      </form>
      <!-- Login form -->

    </div>
  </div>
</div>
