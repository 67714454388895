<div class="row align-items-stretch">
  <div class="col-12">
    <div class="card mt-lg-3 mt-sm-3">
      <div mti-color-theme class="card-header unique-color lighten-1 white-text">
        <div class="row">
          <div class="col-auto mr-auto align-self-center"><strong>Cancelar pases de caja</strong></div>
            <div class="col-auto align-self-center mr-0 pr-0">
            <a class="btn-floating btn-sm red" (click)="pdf()" mdbTooltip="Descargar pdf" placement="bottom"
               mdbWavesEffect><i class="far fa-file-pdf white-text" aria-hidden="true"></i></a>
            <a class="btn-floating btn-sm green" (click)="xls()" mdbTooltip="Descargar excel" placement="bottom"
               mdbWavesEffect><i class="far fa-file-excel white-text" aria-hidden="true"></i></a>
          </div>
          <div class="col-auto ml-0 pl-0 col-uno-btn">
            <button class="btn btn-sm btn-deep-orange waves-light btn-cat-principal"
                    (click)="createModal(NewCancelacion); cleanModal();" mdbWavesEffect="">
              <i class="fa fa-plus mr-1" aria-hidden="true"></i>
              Nuevo
            </button>
          </div>
        </div>
      </div>
      <app-list-items [configItems]="configItems"
                      [list]="pases_caja"
                      [listToExport]="listToExport"
                      [filter]="filterModel"
                      [disableValidate]="true"
                      [storeFilterHistory]="false"
                      [emptyListMessage]="'No se han encontrado registros.'"
                      (actionEmitterCallback)="cleanModal(); detalle($event)"
                      [disableTableResponsive]="false"
                      (load)="loadPasesCajaCancel()">
      </app-list-items>
  </div>
</div>
<app-pase-caja-detalle #NewCancelacion [pase_caja]="pase_caja" (reload)="loadPasesCajaCancel()"></app-pase-caja-detalle>
