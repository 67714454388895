<div mdbModal #NewCancelacion="mdb-modal" class="modal fade overflow-auto" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
  <div class="modal-dialog" style="max-width:70%;" role="document">    
    <div class="modal-content">
      <div mti-color-theme class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close"
          (click)="NewCancelacion.hide(); limpiaVariables();">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Cancelación de pase de caja</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <form [formGroup]="formCancel">
              <div class="row no-gutters">
                <div class="col-6">
                  <div class="md-form">
                    <div class="input-group">
                      <input mdbInput type="text" id="pase_caja" class="form-control overflow-ellipsis"
                        formControlName="defaultFormPaseCaja" [(ngModel)]="pase_caja.pase_caja"
                        [ngClass]="{disabled: false}" autocomplete="off">
                      <label for="pase_caja" [ngClass]="{disabled: false}">Pase de caja</label>
                      <div class="input-group-append" type="button" [ngClass]="{disabled: btnSearch}">
                        <i class="fa fa-search" (click)="searchPaseCaja()"></i>
                      </div>
                    </div>
                    <div *ngIf="formCancel.controls['defaultFormPaseCaja'].touched">
                      <div *ngIf="formCancel.controls['defaultFormPaseCaja'].errors?.required"
                        class="text-danger animated fadeInDown fast-4 small">
                        El pase de caja es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="btnSearch" class="col-6">
                  <div class="md-form">
                    <div class="input-group">
                      <input mdbInput type="text" id="user_cancelo" class="form-control overflow-ellipsis" 
                      formControlName="defaultFormUsuarioCancela" [(ngModel)]="pase_caja.nombre"
                        [ngClass]="{disabled: false}" autocomplete="off">
                      <label for="user_cancelo" [ngClass]="{disabled: false}">Usuario que canceló</label>                   
                    </div>                   
                  </div>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-7">
                    <div>
                      <p  style="color: #868686; font-size: medium;" [ngClass]="{disabled: mostrarLeyenda}">{{leyendaPaseCaja}}</p>
                    </div>
                    <div>
                      <p style="color: #868686; font-size: medium;" [ngClass]="{disabled: mostrarLeyenda}">{{leyendaContribuyente}}</p>
                    </div>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-6">
                  <div class="md-form">
                    <input mdbInput type="text" id="motivo_cancel" class="form-control"
                      formControlName="defaultFormMotivoCancel" [(ngModel)]="pase_caja.motivo_cancelacion"
                      [ngClass]="{disabled: canInsertMotivo}">
                    <label for="motivo_cancel" [ngClass]="{disabled: canInsertMotivo}">Motivo de cancelación</label>
                    <div *ngIf="formCancel.controls['defaultFormMotivoCancel'].touched">
                      <div *ngIf="formCancel.controls['defaultFormMotivoCancel'].errors?.required"
                        class="text-danger animated fadeInDown fast-4 small">
                        El motivo de cancelación es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="btnSearch" class="col-6">
                  <div class="md-form">
                    <div class="input-group">
                      <input mdbInput type="text" id="fecha_cancelacion" class="form-control overflow-ellipsis" 
                      formControlName="defaultFormFechaCancelacion" [(ngModel)]="pase_caja.fecha_cancelacion"
                        [ngClass]="{disabled: false}" autocomplete="off">
                      <label for="fecha_cancelacion" [ngClass]="{disabled: false}">Fecha de cancelación</label>                   
                    </div>                   
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="NewCancelacion.hide(); limpiaVariables(); addControls();"
          class="btn btn-danger waves-light btn-md" aria-label="Close" mdbWavesEffect>
          Cancelar
        </button>
        <button class="btn btn-info btn-theme-primary btn darken-3 waves-ligth btn-md" mdbWavesEffect
          (click)="save()" [ngClass]="{disabled: canSave}" mti-color-theme>
          <i class="fa fa-save" aria-hidden="true"></i> Guardar
        </button>
      </div>
    </div>
  </div>
</div>
<app-modal-confirm [mainText]="mensajeEliminacion"
                   [okText]="'Aceptar'"
                   [cancelText]="'Cancelar'"
                   (resolveAction)="saveCancel($event)"></app-modal-confirm>