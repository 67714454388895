/**
 * Created by Rsierra.
 */
import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuardLoged implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate() {
    if (!this.auth.loggedIn()) {
      return true;
    } else {
      this.router.navigate(['app']);
      return true;
    }
  }

}
