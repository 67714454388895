/**
 * Created by Daniel on 09/07/2018.
 */
import { Injectable } from '@angular/core';
import { EvaluateFormalityResource } from './evaluate-formality.resource';
import { FormalitiesModel } from './formalities.model';
import { SimResource } from './sim.resource';
import { StatusConstants } from '../../../util/status.constants';
import { FormalitiesConfigConstants } from '../../../util/formalities-config.constants';
import { SimNaResource } from './sim-na.resource';
import { PaseCaja } from '../../../../portal-legacy/admin.albercas.module/service/pase-caja.model/pase-caja.model';//'../../../../admin.albercas.module/service/pase-caja.model/pase-caja.model';
import { PaymentMethodVmModel } from '../../../model/payment-method-vm.model';
import { PaymentMethodContratisasResource } from '../../payment-component/service/payment-contratistas.resource';
import { PaymentMethodPortalModel } from '../../../../portal-legacy/portal.traslado.module/service/paymentMethodPortal.model';
import { PaymentMethodPortalResource } from './payment-method-portal.resource';
import { TextConfigurationModel } from '../../../model/text-configuration.model';

@Injectable()
export class EvaluateFormalityContratistasService {
  constructor(private evaluateFormalityResource: EvaluateFormalityResource,
    private simResource: SimResource,
    private simNaResource: SimNaResource,
    private paymentMethodResource: PaymentMethodContratisasResource,
    private paymentMethodPortalResource: PaymentMethodPortalResource) {
  }

  sendRejectNotification() {
    return this.evaluateFormalityResource.sendRejectNotification();
  }

  sendGreenLightNotification() {
    return this.evaluateFormalityResource.sendGreenLightNotification();
  }

  registerFormality(formality: any) {
    return this.simResource.registerFormality(formality);
  }

  registerTaxpayer(taxpayer: any) {
    return this.simResource.setTaxpayerFact(taxpayer);
  }

  updateFolio(object: any) {
    return this.simResource.updateFolio(object);
  }

  getPredialFolio(cadastralKey: string) {
    return this.simResource.getPredialFolio({ cadastralKey: cadastralKey });
  }

  getStatus(id: number) {
    return this.simNaResource.getStatus({ id: id });
  }

  updateStatusHistory(statusHistory: any) {
    return this.simResource.updateStatusHistory(statusHistory);
  }

  updateStatusHistoryWithFormalityId(statusHistory: any) {
    return this.simResource.updateStatusHistoryWithFormalityId(statusHistory);
  }

  getCaseFile(caseFile: any) {
    return this.simNaResource.getCaseFile({ id_req: caseFile.id_req, id_form: caseFile.id_form });
  }

  get_idSIM(formalities_config_id: number): number {
    switch (formalities_config_id) {
      case FormalitiesConfigConstants.CONSTANCIA_OFICIAL:
        return 21;
      case FormalitiesConfigConstants.AMBIENTAL:
        return 58;
      case FormalitiesConfigConstants.DICTAMEN_US:
        return 38;
      case FormalitiesConfigConstants.VOBO:
        return 59;
      case FormalitiesConfigConstants.FACTIBILIDAD_GIRO:
        return 32;
      // case FormalitiesConfigConstants.RECOLECCION_BASURA:
      //   return 61;
      case FormalitiesConfigConstants.FACTIBILIDAD_VIAL:
        return 60;
      default:
        return 0;
    }
  }

  getSimPayment(id: number): Promise<any> {
    return this.simNaResource.getTicket({ id: id });
  }

  updataInspection(inspection: number): Promise<any> {
    return this.simResource.updateInspection({ inspection: inspection });
  }

  getDetailCashPass(liquidacion: string): Promise<PaseCaja> {
    return this.simResource.getDetailCashPass({ liq: liquidacion });
  }

  getAllPaymentByFormalitiesConfigId(formalities_config_id: number): Promise<PaymentMethodVmModel[]> {
    return this.paymentMethodResource.getAllPaymentsByFormalitiesConfigId({ formalities_config_id: formalities_config_id });
  }

  getAllPaymentByPathName(path: string): Promise<PaymentMethodVmModel[]> {
    return this.paymentMethodResource.getAllPaymentsByNamePath({ path: path });
  }

  getPaymentMethodPortalList(): Promise<PaymentMethodPortalModel[]> {
    return this.paymentMethodPortalResource.getAllPaymentMethodPortal();
  }

  getTextConfigByKey(key: string): Promise<TextConfigurationModel> {
    return this.paymentMethodResource.getTextConfigByKey({ key });
  }
}
