import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelarPasesCajaRouting } from './cancelarpasescaja.routing';
import { PasesCajaListaComponent } from './pases-caja-lista/pases-caja-lista.component';
import { DirectivasModule } from '../directivas.module/directivas.module';
import {AppCommonLibsModule} from '../common.module/common.module';
import { PaseCajaDetalleComponent } from './pase-caja-detalle/pase-caja-detalle.component';
import { PaseCajaService } from './service/pase_caja.service';
import { PaseCajaResource } from './service/pase_caja.resource';

@NgModule({
  declarations: [PasesCajaListaComponent, PaseCajaDetalleComponent
  ],
  imports: [
    CancelarPasesCajaRouting,
    CommonModule,
    DirectivasModule,
    AppCommonLibsModule
  ],
  providers: [
    PaseCajaService,
    PaseCajaResource
  ]
})
export class CancelarpasescajaModule { 
}