<div mti-font-contenidolp>
  <!-- Top Bar -->
  <app-header></app-header>
  <!-- #Top Bar -->
  <!-- Main Container -->
  <div class="container main-container mb-5 container-landing">
    <router-outlet></router-outlet>
  </div>
  <!-- #Main Container -->
  <!-- Footer Bar -->
  <app-footer></app-footer>
  <!-- #Footer Bar -->
  <app-chat-component></app-chat-component>

  <app-avisos-ciudadano #avisoModal [aviso]="aviso"></app-avisos-ciudadano>
</div>
