/**
 * Created by rsierra on 25/09/2017.
 */

import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {PaymentSuccessCreateComponent} from './payment-success-create/payment-success-create.component';


const paymentSuccessRoutes: Routes = [
  {
    path: '',
    component: PaymentSuccessCreateComponent,
    data: {
      pageTitle: 'Pago aplicado'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(paymentSuccessRoutes)],
  exports: [RouterModule]
})
export class PaymentSuccessRoutingModule {
}
