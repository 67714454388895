/**
 * Created by ANUNEZ on 11/03/2019.
 */
import {openDb, deleteDb, DB} from 'idb';
import {environment} from '../../../environments/environment';
import {Injectable} from "@angular/core";
import {CollectionsDBName} from "./collections.db.enum";

@Injectable()
export class DbBaseRepository {
  dbPromise: Promise<DB>;

  /**
   * inicia la conexion de la base de datos y crea las colecciones
   * @returns {any}
   */
  initDB(){
    return this.dbPromise= openDb(environment.dbLocalName, environment.dbLocalVersion, UpgradeDB => {
      for (let collectionName in CollectionsDBName) {
        if (!UpgradeDB.objectStoreNames.contains(CollectionsDBName[collectionName.valueOf()])) {
          UpgradeDB.createObjectStore(CollectionsDBName[collectionName.valueOf()], {keyPath: 'id', autoIncrement: true});
        }
      }
    });
  }
  /**
   * agrega un registro a una coleccion y si ya existe lo actualiza
   * @param collectionName nombre de la coleccion
   * @param value registro que se agregara
   */
  addItem(collectionName: string, value): any{
    if(!this.dbPromise){
      this.dbPromise =  this.initDB();
    }
    return this.dbPromise.then(db => {
      const tx = db.transaction(collectionName, 'readwrite');
      tx.objectStore(collectionName).put(value);
      return tx.complete;
    });
  }


  /**
   * Regresa el registros por id
   * @param collectionName nombre de la coleccion  a consultar
   * @param id identidficador por el cual se consultara
   * @returns {Promise<DB>} regresa una promesa con el objeto encotrado
   */
  getItemById(collectionName: string, id){
    if(!this.dbPromise){
      this.dbPromise =  this.initDB();
    }
    return this.dbPromise.then(db => {
      return db.transaction(collectionName,'readonly')
        .objectStore(collectionName).get(id);
    });
  }

  /**
   * regresa todos los registros de una coleccion
   * @param collectionName nombre de la coleccion
   * @returns {Promise<DB>} promesa con el listado de la coleccion
   */
  getAllItems(collectionName: string) {
    if(!this.dbPromise){
      this.dbPromise =  this.initDB();
    }
    return this.dbPromise.then(db => {
      return db.transaction(collectionName,'readonly')
        .objectStore(collectionName).getAll();
    });
  }

  /**
   * Elimina un registro de la coleccion
   * @param collectionName nombre de la coleccion
   * @param id identificador del elemento que se quiere eliminar
   * @returns {Promise<DB>} promesa
   */
  deleteItemById(collectionName: string, id){
    if(!this.dbPromise){
      this.dbPromise =  this.initDB();
    }
    return this.dbPromise.then(db => {
      return db.transaction(collectionName,'readwrite')
        .objectStore(collectionName).delete(id);
    });
  }


}
