import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {PaseCajaCancelModel} from './pase_caja_cancel.model';
import { PagosModel } from 'src/app/common.tramite-vu.module/entidad.pago.component/service/models/pase-caja-response/pagos.model';

@Injectable()
@ResourceParams({
  // url: 'http://localhost:8014'
  pathPrefix: '/pagos'
})
//  export class PaseCajaResource extends Resource {
//   constructor(handler: ResourceHandler) {
//    super(handler);
//    }
export class PaseCajaResource extends ResourceAuth {
  constructor(restHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(restHandler, authProv);
  }

  @ResourceAction({
    path: '/obtenerPaseCaja/{!folio}',
    method: ResourceRequestMethod.Get
  })
  obtenerPaseCaja: IResourceMethod<{folio: any}, PagosModel>;

  @ResourceAction({
    path: '/obtenerPaseCajaCancel/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtenerPaseCajaCancel: IResourceMethod<{id: string}, PaseCajaCancelModel>;

  @ResourceAction({
    path: '/paseCaja',
    method: ResourceRequestMethod.Post
  })
  crearPaseCajaCancel: IResourceMethod<PaseCajaCancelModel, any>;

  @ResourceAction({
    path: '/obtenerPasesCancelados',
    method: ResourceRequestMethod.Get
  })
  loadPasesCajaCancelados: IResourceMethod<any, PaseCajaCancelModel[]>;

//   @ResourceAction({
//     path: '/usuario'
//   })
//   obtenerUsuarios: IResourceMethod<any, UsuarioModel[]>;

//   @ResourceAction({
//     path: '/usuario'
//   })
//   obtenerUsuariosPaginacion: IResourceMethod<any, UsuarioModel[]>;

//   @ResourceAction({
//     path: '/usuario/{!id}',
//     method: ResourceRequestMethod.Get
//   })
//   obtenerUsuario: IResourceMethod<{ id: any }, UsuarioModel>;

//   @ResourceAction({
//     path: '/administracion/usuario',
//     method: ResourceRequestMethod.Post
//   })
//   crearUsuario: IResourceMethod<UsuarioModel, any>;

//   @ResourceAction({
//     path: '/usuario/{!id}',
//     method: ResourceRequestMethod.Patch
//   })
//   actualizarUsuario: IResourceMethod<UsuarioModel, any>;

//   @ResourceAction({
//     path: '/area',
//     method: ResourceRequestMethod.Get
//   })
//   obtenerAreas: IResourceMethod<any, AreaModel[]>;

//   @ResourceAction({
//     path: '/usuarioDA/{!check}',
//     method: ResourceRequestMethod.Patch
//   })
//   actualizarUsuariosDA: IResourceMethod<{ check: any }, any>;

//   @ResourceAction({
//     path: '/ss/usuario'
//   })
//   usuarioSesion: IResourceMethod<any, UsuarioModel>;

}