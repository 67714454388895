<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-12 col-lg-4 pt-3 pb-3 mt-0 animated fadeIn fast">
      <div class="w-100 mx-auto">
        <!--<img src="assets/img/logo.png" class="w-100">-->
        <img class="w-100"  alt="" [src]="'data:image/jpg;base64,'+configuracion.logoRegistro">
      </div>
      <!-- Login form -->
      <form [formGroup]="formRegister" (submit)="formRegister.valid && finalRegister()">
        <p class="h5 text-center mb-4">Finaliza tu registro en Portales</p>
        <p class="text-center mb-4 text-muted">{{ userModel.correo }}</p>

        <div class="md-form">
          <input type="text" formControlName="defaultFormName" id="defaultForm-name" class="form-control"
                 data-error="Datos invalidos" data-success="Correcto"
                 [(ngModel)]="userModel.nombre"
                 mdbInput>
          <label for="defaultForm-name">Nombre del ciudadano</label>
          <div *ngIf="formRegister.controls['defaultFormName'].dirty">
            <div *ngIf="formRegister.controls['defaultFormName'].errors?.required"
                 class="text-danger animated fadeInDown fast-4 small">
              El nombre es obligatorio.
            </div>
          </div>
        </div>
        <div class="md-form">
          <input type="text" formControlName="defaultFormFirstName" id="defaultForm-first" class="form-control"
                 [(ngModel)]="userModel.apellido_p"
                 mdbInput>
          <label for="defaultForm-first">Apellido Paterno</label>
          <div *ngIf="formRegister.controls['defaultFormFirstName'].dirty">
            <div *ngIf="formRegister.controls['defaultFormFirstName'].errors?.required"
                 class="text-danger animated fadeInDown fast-4 small">
              El apellido paterno es obligatorio.
            </div>
          </div>
        </div>
        <div class="md-form">
          <input type="text" formControlName="defaultFormLastName" id="defaultForm-last" class="form-control"
                 [(ngModel)]="userModel.apellido_m"
                 mdbInput>
          <label for="defaultForm-last">Apellido Materno</label>
          <div *ngIf="formRegister.controls['defaultFormLastName'].dirty">
            <div *ngIf="formRegister.controls['defaultFormLastName'].errors?.required"
                 class="text-danger animated fadeInDown fast-4 small">
              El apellido materno es obligatorio.
            </div>
          </div>
        </div>
        <div class="md-form">
          <input type="text" formControlName="defaultFormTelephone" id="defaultForm-telephone" class="form-control"
                 [(ngModel)]="userModel.telefono"
                 mdbInput>
          <label for="defaultForm-telephone">Número telefónico</label>
        </div>

        <div class="text-center">
          <input type="submit" class="btn btn-default waves-light" mdbWavesEffect value="Finalizar registro">
        </div>
      </form>
      <!-- Login form -->

    </div>
  </div>
</div>

