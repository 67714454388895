import {Injectable} from '@angular/core';
import {
  IResourceMethod,
  Resource,
  ResourceAction,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod,
} from '@ngx-resource/core';
import {ExpedientesElectModel} from './expedientes-elect.model';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {VigenciaReqModel} from './vigencia-req.model';
import {ExpHistoricoClaveModel} from "./expHistoricoClave.model";

@Injectable()
@ResourceParams({
    pathPrefix: '/expediente'
   //url: 'http://localhost:8005'
})

 export class ConsultaExpElecResource extends ResourceAuth {
//  export class ConsultaExpElecResource extends Resource {

  constructor(restHandler: ResourceHandler, provService: AuthServerProvider) {
    super(restHandler, provService);
  }

  /*constructor(restHandler: ResourceHandler) {
    super(restHandler);
  }*/

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/ver-lista-exp-elec'
  })
  obtenerListaExpediente: IResourceMethod<any, ExpedientesElectModel[]>;

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/exp-historico-clave/{!curp}'
  })
  obtenerINE: IResourceMethod<{curp: string}, ExpHistoricoClaveModel>;

  @ResourceAction({
    isArray: true,
    method: ResourceRequestMethod.Get,
    path: '/na/exp-electronico/{!id}'
  })
  obtenerSolExpediente: IResourceMethod<{id: string}, ExpedientesElectModel>;

  @ResourceAction({
    isArray: false,
    method: ResourceRequestMethod.Post,
    path: '/guardar-exp-elect'
  })
  guardarExpedienteResource: IResourceMethod<{expedientesElectModel: ExpedientesElectModel}, any>;

  @ResourceAction({
    isArray: false,
    method: ResourceRequestMethod.Patch,
    path: '/actualiza-exp-elect'
  })
  actualizaExpedienteResource: IResourceMethod<{expedientesElectModel: ExpedientesElectModel}, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Patch,
    path: '/vigencia'
  })
  actualizaVigencia: IResourceMethod<VigenciaReqModel, any>;

  @ResourceAction({
    path: '/vigencia/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtVigenciaArchivo: IResourceMethod<{ id: any }, VigenciaReqModel>;
}
