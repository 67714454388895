import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';
import {COMPONENTES} from '../elementos';

@Directive({
  selector: '[mti-color-theme]'
})
export class MtiColorThemeDirective implements OnInit {

  constructor(public el: ElementRef,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    const watcher = setInterval(() => {
      enum GENERAL {
        COLORES = <any>JSON.parse(localStorage.getItem('theme')) != null ? JSON.parse(localStorage.getItem('theme')) : {}
      }

      if (GENERAL.COLORES.hasOwnProperty('color1') && GENERAL.COLORES['GROUPELEMENTS1'] != '') {
        clearInterval(watcher);

        enum SECCION {
          GROUPELEMENTS1 = <any>GENERAL.COLORES['groupElements1'] != null ? GENERAL.COLORES['groupElements1'] : '#fff',
          GROUPELEMENTS2 = <any>GENERAL.COLORES['groupElements2'] != null ? GENERAL.COLORES['groupElements2'] : '#fff',
          GROUPELEMENTS3 = <any>GENERAL.COLORES['groupElements3'] != null ? GENERAL.COLORES['groupElements3'] : '#fff',
          GROUPELEMENTS4 = <any>GENERAL.COLORES['groupElements4'] != null ? GENERAL.COLORES['groupElements4'] : '#fff',
          COLOR1 = <any>GENERAL.COLORES['color1'] != null ? GENERAL.COLORES['color1'] : '#fff',
          COLOR2 = <any>GENERAL.COLORES['color2'] != null ? GENERAL.COLORES['color2'] : '#fff',
          COLOR3 = <any>GENERAL.COLORES['color3'] != null ? GENERAL.COLORES['color3'] : '#fff',
          COLOR4 = <any>GENERAL.COLORES['color4'] != null ? GENERAL.COLORES['color4'] : '#fff',
        }

        const self = this;

        enum EL {
          ELEMENTO = self.el.nativeElement.outerHTML.split(' ')
        }

        switch (EL.ELEMENTO[0]) {
          case COMPONENTES.NAVBAR:
            const nav = this.el.nativeElement.querySelector('nav');
            if (nav != null) {
              const valores = 'background-color: ' + SECCION.GROUPELEMENTS1 + ' !important; color: ' + SECCION.COLOR1 + ' !important;';
              this.renderer.setProperty(nav, 'style', valores);
            }
            break;
          case COMPONENTES.BUTTON:
            const btn_theme_primary = this.el.nativeElement.outerHTML.indexOf('btn-theme-primary');
            const btn_theme_secondary = this.el.nativeElement.outerHTML.indexOf('btn-theme-secondary');
            const btn_theme_tertiary = this.el.nativeElement.outerHTML.indexOf('btn-theme-tertiary');
            const btn_theme_quaternary = this.el.nativeElement.outerHTML.indexOf('btn-theme-quaternary');
            if (btn_theme_primary !== -1) {
              const valores1 = 'background-color: ' + SECCION.GROUPELEMENTS1 + ' !important; color: ' + SECCION.COLOR1 + ' !important;';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores1);
            } else if (btn_theme_secondary !== -1) {
              const valores2 = 'background-color: ' + SECCION.GROUPELEMENTS2 + ' !important; color: ' + SECCION.COLOR2 + ' !important;';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores2);
            } else if (btn_theme_tertiary !== -1) {
              const valores3 = 'background-color: ' + SECCION.GROUPELEMENTS3 + ' !important; color: ' + SECCION.COLOR3 + ' !important;';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores3);
            } else if (btn_theme_quaternary !== -1) {
              const valores4 = 'background-color: ' + SECCION.GROUPELEMENTS4 + ' !important; color: ' + SECCION.COLOR4 + ' !important;';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores4);
            }
            break;
          case COMPONENTES.ACCORDIONS:
            const card = this.el.nativeElement.querySelector('i');
            if (card != null) {
              this.renderer.setProperty(card, 'style', 'color: ' + SECCION.GROUPELEMENTS1 + ' !important;');
            }
            break;
          case COMPONENTES.TABSET:
            const modal_header = this.el.nativeElement.querySelector('ul');
            if (modal_header != null) {
              const valores5 = 'background-color:' + SECCION.GROUPELEMENTS1 + '!important; color: ' + SECCION.COLOR1 + ' !important;';
              this.renderer.setProperty(modal_header, 'style', valores5);
            }
            break;
          case COMPONENTES.DIV:
            // El footer bottom
            const div_foot_bot = this.el.nativeElement.outerHTML.indexOf('pie-fondo-color');
            // Fondo de card-header
            const div_card_header = this.el.nativeElement.outerHTML.indexOf('card-header');
            const div_bg_icon = this.el.nativeElement.outerHTML.indexOf('bg-icon');
            const div_title = this.el.nativeElement.outerHTML.indexOf('title');
            const div_modal_header = this.el.nativeElement.outerHTML.indexOf('modal-header');
            const div_header_top = this.el.nativeElement.outerHTML.indexOf('header-top');
            const div_border_lf = this.el.nativeElement.outerHTML.indexOf('border-lf');
            const div_bg_tram_popular = this.el.nativeElement.outerHTML.indexOf('bg-lista-tramites-populares');
            const div_bg_mm = this.el.nativeElement.outerHTML.indexOf('bg-mega-menu');
            const div_color_mm = this.el.nativeElement.outerHTML.indexOf('color-mega-menu');
            const div_head_theme_historial = this.el.nativeElement.outerHTML.indexOf('head-theme-historial');
            const div_chat_modal_aviso = this.el.nativeElement.outerHTML.indexOf('chat-modal-aviso');
            const div_header_body_chat = this.el.nativeElement.outerHTML.indexOf('header-body-chat');
            if (div_foot_bot !== -1) {
              const valores6 = 'background-color: ' + SECCION.GROUPELEMENTS1 + ' !important; color: ' + SECCION.COLOR1 + ' !important;';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores6);
            } else if (div_card_header !== -1) {
              const valores7 = 'background-color: ' + SECCION.GROUPELEMENTS1 + ' !important; color: ' + SECCION.COLOR1 + ' !important;';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores7);
            } else if (div_bg_icon !== -1) {
              const valores8 = 'border-top: 50px solid ' + SECCION.GROUPELEMENTS1 + ' !important;';
              const valores8_1 = ' color: ' + SECCION.COLOR1 + ' !important;';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores8 + valores8_1);
            } else if (div_title !== -1) {
              const valores9 = 'background-color: ' + SECCION.GROUPELEMENTS1 + ' !important; color: ' + SECCION.COLOR1 + ' !important';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores9);
            } else if (div_modal_header !== -1) {
              const valores10 = 'background-color: ' + SECCION.GROUPELEMENTS1 + ' !important; color: ' + SECCION.COLOR1 + ' !important';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores10);
            } else if (div_header_top !== -1) {
              const valores11 = 'background-color: ' + SECCION.GROUPELEMENTS1 + ' !important; color: ' + SECCION.COLOR1 + ' !important';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores11);
            } else if (div_border_lf !== -1) {
              const valores12 = 'border-left:' + SECCION.GROUPELEMENTS1 + ' 5px solid !important;';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores12);
            } else if (div_bg_tram_popular !== -1) {
              const valores13 = 'background-color: ' + SECCION.GROUPELEMENTS2 + ' !important; color: ' + SECCION.COLOR2 + ' !important';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores13);
            } else if (div_bg_mm !== -1) {
              const valores14 = 'background-color: ' + SECCION.GROUPELEMENTS1 + ' !important';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores14);
            } else if (div_color_mm !== -1) {
              const valores15 = 'color: ' + SECCION.COLOR1 + ' !important';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores15);
            } else if (div_head_theme_historial !== -1) {
              const valores16 = 'background-color: ' + SECCION.GROUPELEMENTS1 + ' !important; color: ' + SECCION.COLOR1 + ' !important';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores16);
            } else if (div_chat_modal_aviso !== -1) {
              const valoresMC = 'background-color: ' + SECCION.GROUPELEMENTS2 + ' !important; color: ' + SECCION.COLOR2 + ' !important';
              this.renderer.setProperty(this.el.nativeElement, 'style', valoresMC);
            } else if (div_header_body_chat !== -1) {
              const valoresHC = 'background-color: ' + SECCION.GROUPELEMENTS1 + ' !important; color: ' + SECCION.COLOR1 + ' !important';
              this.renderer.setProperty(this.el.nativeElement, 'style', valoresHC);
            } else {
              this.renderer.setProperty(this.el.nativeElement, 'style', 'background-color: #fff !important;');
            }
            break;
          case COMPONENTES.FOOTER:
            const valores17 = 'background-color: ' + SECCION.GROUPELEMENTS4 + ' !important; color: ' + SECCION.COLOR4 + ' !important;';
            this.renderer.setProperty(this.el.nativeElement, 'style', valores17);
            const i_facebook = this.el.nativeElement.querySelector('i.fa-facebook-square');
            const i_twitter = this.el.nativeElement.querySelector('i.fa-twitter-square');
            if (i_facebook !== -1 && i_twitter !== -1) {
              this.renderer.setProperty(i_facebook, 'style', 'color: ' + SECCION.COLOR4 + ' !important');
              this.renderer.setProperty(i_twitter, 'style', 'color: ' + SECCION.COLOR4 + ' !important');
            }
            break;
          case COMPONENTES.A:
            const a_nav_link = this.el.nativeElement.outerHTML.indexOf('nav-link');
            const a_chat_link = this.el.nativeElement.outerHTML.indexOf('chat-theme-component');
            if (a_nav_link !== -1) {
              this.renderer.setProperty(this.el.nativeElement, 'style', 'color: ' + SECCION.COLOR1 + '!important');
              const nav_link_icon = this.el.nativeElement.querySelector('i');
              if (nav_link_icon != null) {
                this.renderer.setProperty(nav_link_icon, 'style', 'color: ' + SECCION.COLOR1 + ' !important');
              }
            } else if (a_chat_link !== -1) {
              const valoresChat = 'background-color: ' + SECCION.GROUPELEMENTS2 + ' !important; color: ' + SECCION.COLOR2 + ' !important;';
              this.renderer.setProperty(this.el.nativeElement, 'style', valoresChat);
            }
            break;
          case COMPONENTES.SPAN:
            const span_tram_populares = this.el.nativeElement.outerHTML.indexOf('tp-elementos-color');
            if (span_tram_populares !== -1) {
              const valores18 = 'background-color: ' + SECCION.GROUPELEMENTS2 + ' !important; color: ' + SECCION.COLOR2 + ' !important';
              this.renderer.setProperty(this.el.nativeElement, 'style', valores18);
            }
            break;
          case COMPONENTES.CAROUSEL:
            const carousel_content_mega_menu = this.el.nativeElement.outerHTML.indexOf('carousel-content-mega-menu');
            if (carousel_content_mega_menu !== -1) {
              const indicators = Array.from(this.el.nativeElement.querySelectorAll('.carousel-indicators li'));
              indicators.forEach(li => {
                this.renderer.setProperty(li, 'style', 'background-color: ' + SECCION.GROUPELEMENTS1);
              });
              const array = this.el.nativeElement.querySelectorAll('.carousel-content-mega-menu .controls-top > .btn-floating');
              const controls = Array.from(array);
              controls.forEach(control => {
                this.renderer.setProperty(control, 'style', 'background-color: ' + SECCION.GROUPELEMENTS1);
              });
            }
            break;
          case COMPONENTES.ICONOS:
            const icon_theme_historial = this.el.nativeElement.outerHTML.indexOf('icon-theme-historial');
            if (icon_theme_historial !== -1) {
              const icon = this.el.nativeElement.querySelector('i');
              if (icon != null) {
                this.renderer.setProperty(icon, 'style', 'color: ' + SECCION.COLOR1 + ' !important');
              }
            }
            break;
          case COMPONENTES.BADGE:

            break;
          default:
            this.renderer.setProperty(this.el.nativeElement, 'style', 'background-color: #fff !important');
            break;
        }
      }
    }, 500);
  }
}
