import { Injectable } from '@angular/core';
import {ConfiguracionGeneralResource} from './configuracion-general.resource';
import {ConfiguracionGeneralModel} from './configuracion-general.model';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionGeneralService {

  constructor(private configuracionGeneralResource: ConfiguracionGeneralResource) { }

  obtenerConfiguracionGeneral(): Promise<ConfiguracionGeneralModel> {
    return this.configuracionGeneralResource.obtenerConfiguracionGeneral();
  }

  actualizarConfiguracionGeneral(configuracion: ConfiguracionGeneralModel): Promise<any> {
    return this.configuracionGeneralResource.actualizarConfiguracionGeneral(configuracion);
  }
}
