<div class="mt-2 group-container-chat d-flex align-items-end">
  <ng-template *ngIf="showChat" ngFor let-chat="$implicit" [ngForOf]="chatList" let-i="index">
    <div class="card container-chat fadeIn fast-5" (click)="focusChat(i)">
      <div class="chat-card-header grey lighten-2 pr-2 pl-3 py-0 d-flex justify-content-between">
        <i aria-hidden="true"
           class="fa fa-ellipsis-h fa-3x grey-text chat-minimize-icon float-left  pt-1 cursor-pointer"
           (click)="test.toggle()"></i>
        <div class="mr-2">{{chat.citizenName}}</div>
        <i class="fa fa-times float-right ml-1 pt-1 cursor-pointer mr-1"
           (click)="cleanMessages(chat.token, chat.adminToken, message[i]);"></i>
      </div>
      <div mdbCollapse #test="bs-collapse" (collapsed)="setSelectedChat($event)" class="chat-height">
        <div class="card-body p-0">
          <div class="row px-3 text-white blue darken-2 mx-0 py-2">
            <div class="col-2 px-0">
              <img src="assets/img/headset-icon.png" class="img-fluid invert-color px-1 pt-1">
            </div>
            <div class="col-10 pl-2 pr-0 text-uppercase">
              <div class="font-weight-bold">
                Chat
              </div>
              <div class="font-weight-light">
                De orientación ciudadana
              </div>
            </div>
          </div>
          <perfect-scrollbar style="max-height: 280px;">
            <ul class="list-group" style="height: 270px">
              <li class="list-group-item px-2 py-1 animated flipInX fast-4" *ngFor="let message of chat.messages"
                  [ngClass]="{'grey lighten-4': user?._id == message?.user_id,
                  'text-danger': (message.error && message.error !== ''),
                  'cyan lighten-3': message.visto == false}">
                <p class="mb-1"
                   [ngClass]="{'float-right': user?._id == message?.user_id, 'float-left': user?._id != message?.user_id}">
                  {{message?.message}}</p>
                <div class="clearfix"></div>
                <small class="text-muted"
                       [ngClass]="{'float-right': user?._id == message?.user_id, 'float-left': user?._id != message?.user_id}">
                  <strong>{{message?.created_at | date: 'short'}}</strong></small>
              </li>
              <li class="list-group-item text-center" *ngIf="chat.messages?.length == 0">
                <small>Esperando inicio de conversación...</small>
              </li>
            </ul>
          </perfect-scrollbar>
        </div>
        <div class="card-footer text-muted white-color white-text p-1">
          <div class="md-form input-group my-0">
            <input type="text" class="form-control pl-1" placeholder="Escribe tu nota..."
                   [(ngModel)]="message[i].message"
                   aria-describedby="basic-addon2"
                   (keydown.enter)="saveMessage(chat.token, chat.adminToken, message[i], i)">
            <div class="input-group-append cursor-pointer"
                 (click)="saveMessage(chat.token, chat.adminToken, message[i], i)">
              <span class="input-group-text"><mdb-icon far icon="paper-plane"></mdb-icon></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div mdbModal #basicModal="mdb-modal" class="modal fade bottom" tabindex="-1" role="dialog" (onHide)="stopInterval()"
     aria-labelledby="myBasicModalLabel" [config]="{backdrop: false, ignoreBackdropClick: false}"
     aria-hidden="true">
  <div class="modal-dialog modal-frame modal-bottom" role="document">
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">

          <p class="pt-3 pr-2" *ngIf="!formalityName">Hay una nueva solicitud para chat, ¿Desea aceptarla?</p>

          <p class="pt-3 pr-2" *ngIf="formalityName">Hay una solicitud de chat para tratar temas de {{formalityName}},
            ¿Desea aceptarla?</p>

          <a type="button" class="btn btn-success waves-effect waves-light" (click)="acceptRequest()">Aceptar
            <i class="fa fa-check ml-1"></i>
          </a>
          <a type="button" class="btn btn-outline-danger waves-effect" data-dismiss="modal" (click)="clearDomModals()">
            Rechazar
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
