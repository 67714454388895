import {Injectable} from '@angular/core';
import {ConfiguracionChatResource} from './configuracion.chat.resource';
import {ConfiguracionChatModel} from './configuracion-chat.model';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionChatService {
  constructor(private configuracionGeneralResource: ConfiguracionChatResource) {
  }

  public obtenerConfiguracionChat(): Promise<ConfiguracionChatModel> {
    return this.configuracionGeneralResource.obtenerConfiguracionChat();
  }

  public actualizarConfiguracionChat(configuracion: ConfiguracionChatModel) {
    return this.configuracionGeneralResource.actualizarConfiguracionChat(configuracion);
  }
}