/**
 * Created by Luis on 30/05/2018.
 */

import {IResourceAction, IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {Injectable} from '@angular/core';
import {RequirementModel} from '../model/requirement.model copy';
import {ResourceAuth} from '../auth/authenticate.resource';
import {AuthServerProvider} from '../auth/auth-jwt.service';
import {State} from "../model/location/state.model";
import {County} from "../model/location/county.model";
import { AuthCorregidoraResource } from '../auth/auth-corregidora.resource';


@Injectable()
@ResourceParams({
  // url: 'http://localhost:8082'
  pathPrefix: '/coremicro'
  // url: 'http://187.216.144.10:9938/api/core'
})
export class RequirementsResource extends ResourceAuth {
// export class RequirementsResource extends Resource {

  constructor(resourceHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resourceHandler, authProv);
  }
  // constructor(resourceHandler: ResourceHandler) {
  //  super(resourceHandler);
  //  }

  @ResourceAction({
    array: true,
    path: '/config/{!id_config}/requirements'
  })
  getRequirementByFormality: IResourceMethod<{ id_config: any }, RequirementModel[]>;
  @ResourceAction({
    array: true,
    path: '/config/{!id_config}/requirements?{!params}'
  })
  getRequirementByFilters: IResourceMethod<{ id_config: any, params: any }, RequirementModel[]>;
  @ResourceAction({
    array: true,
    path: '/filter/{!id_config}/requirements?{!params}'
  })

  retriveRequirementByFilters: IResourceMethod<{ id_config: any, params: any }, RequirementModel[]>;
  @ResourceAction({
    path: '/state/{!id}',
    array: true
  })

  getStates: IResourceMethod<{ id: any }, State[]>;
  @ResourceAction({
    path: '/county/{!id}',
    array: true
  })
  getCounties: IResourceMethod<{ id: any }, County[]>;
  @ResourceAction({
    path: '/file/{!id}',
    method: ResourceRequestMethod.Delete
  })
  delCasefile: IResourceMethod<{ id: any }, any>;


}
